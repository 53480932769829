import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import SQCAnalystChart from '../SQCAnalystChart';
import SQCInstrumentsChart from '../SQCInstrumentsChart';

const ComparisonSection = ({
  localSearchFinished,
  currentPersonnelNames,
  currentPersonnelDots,
  chartScalars,
  chartData,
  renderChartLabelValue,
  currentMethodComparison,
  handleMethodChangeComparison,
  currentMethods,
  renderOptionsDynamic,
  currentSampleNameComparison,
  handleSampleNameChangeComparison,
  currentSampleNames,
  currentParameterComparison,
  handleParameterChangeComparison,
  currentParametersDynamic,
  currentInstrumentNames,
  currentInstrumentDots,
  currentInstrumentComparisonData
}) => {
  if (localSearchFinished) {
    return (
      <div>
        <Row className="instruments-chart-container">
          <Col lg={4}>
            <h3>Analyst Comparison</h3>
          </Col>
        </Row>
        <SQCAnalystChart
          xAxis="updatedIndex"
          analysts={currentPersonnelNames}
          analystsDots={currentPersonnelDots}
          syncId="chained"
          key={uuidv4()}
          yMin={chartScalars.cYMin}
          yMax={chartScalars.cYMax}
          data={chartData}
          lines={[
            {
              yAxis: 'resultOmits',
              name: 'Data',
              color: '#32CD32'
            },
            {
              yAxis: 'mean',
              name: 'Average',
              reference: chartData.length > 20,
              label: ``,
              color: '#FFBF00'
            },
            {
              yAxis: 'sd3n',
              name: 'LCL',
              label: `LCL: ${renderChartLabelValue(chartData, 'sd3n')}`,
              overrideValue: Number(renderChartLabelValue(chartData, 'sd3n')),
              color: '#FBCEB1',
              reference: true
            },
            {
              yAxis: 'sd3p',
              name: 'UCL',
              label: ``,
              color: '#FBCEB1',
              reference: true,
              overrideValue: Number(renderChartLabelValue(chartData, 'sd3p'))
            }
          ]}
        />
        <Row className="instruments-chart-container">
          <Col lg={4}>
            <h3>Instrument Comparison</h3>
          </Col>
        </Row>
        <Row className="instrument-dropdowns">
          <Form.Group as={Col} controlId="method">
            <Form.Label>Method</Form.Label>
            <Form.Select value={currentMethodComparison} onChange={handleMethodChangeComparison}>
              <option value="">Select method</option>
              {renderOptionsDynamic(currentMethods)}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} controlId="sampleName">
            <Form.Label>Sample Name</Form.Label>
            <Form.Select
              value={currentSampleNameComparison}
              onChange={handleSampleNameChangeComparison}
            >
              <option value="">Select sample name</option>
              {renderOptionsDynamic(currentSampleNames)}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} controlId="parameter">
            <Form.Label>Parameter</Form.Label>
            <Form.Select
              value={currentParameterComparison}
              onChange={handleParameterChangeComparison}
            >
              <option value="">Select parameter</option>
              {renderOptionsDynamic(currentParametersDynamic)}
            </Form.Select>
          </Form.Group>
        </Row>
        <div>
          <SQCInstrumentsChart
            xAxis="dataId"
            instrumentsArray={currentInstrumentNames}
            instrumentsDots={currentInstrumentDots}
            syncId="chained"
            lineType="linear"
            key={uuidv4()}
            yMin={chartScalars.cYMin}
            yMax={chartScalars.cYMax}
            data={currentInstrumentComparisonData}
            lines={[
              {
                yAxis: 'resultOmits',
                name: 'Data',
                color: '#32CD32'
              }
            ]}
          />
        </div>
      </div>
    );
  }
  return (
    <div className="please-select-container">
      Please select a location, method, sample name, instrument, and parameter
    </div>
  );
};

export default ComparisonSection;
