import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import './ReportsSection.scss';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import SQCLineChart from '../SQCLineChart';
import SQCMovingRangeChart from '../SQCMovingRangeChart';
import SQCScatterPlot from '../SQCScatterPlot';
import SQCHistogram from '../SQCHistogram';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import { useFindAssessmentsByShortKey, useGetReportsByFullKey, createReport } from '../../util/db';
import {
  defaultColumnsReport,
  summaryStatisticsColumnsPeriodic,
  summaryStatisticsDataPeriodic
} from '../Columns';
import { currentLogo } from '../../util/firebase';
import ChartContext from '../DataEntrySection/DataEntryProvider';
import { useAuth } from '../../util/auth';
import CustomTable from '../../components/Table/CustomTable';
import { randomId } from '@mui/x-data-grid-generator';
import { renderButtonWithPermissions } from '../../util/util';
import { SCREEN, RULES } from '../../util/Constant';

export default function ReportsSection({
  filteredChartHits,
  chartScalars,
  permissionData,
  radioArchiveValue,
  unit
}) {
  const [showModal, setShowModal] = useState(false);
  const [showPeriodicModal, setShowPeriodicModal] = useState(false);
  const [periodicPage, setPeriodicPage] = useState('1');
  // eslint-disable-next-line no-unused-vars
  const [currentResultsData, setCurrentResultsData] = useState([]);
  const [currentAssessmentTrending, setCurrentAssessmentTrending] = useState(null);
  const [currentAssessmentClustering, setCurrentAssessmentClustering] = useState(null);
  const [currentAssessmentCyclicalPatterns, setCurrentAssessmentCyclicalPatterns] = useState(null);
  const [currentAssessmentDataNormal, setCurrentAssessmentDataNormal] = useState(null);
  const [currentAssessmentTpiAssessment, setCurrentAssessmentTpiAssessment] = useState(null);
  const [currentAssessmentOutcome, setCurrentAssessmentOutcome] = useState('');
  const [currentAssessmentComments, setCurrentAssessmentComments] = useState('');
  const [currentModalReport, setCurrentModalReport] = useState({});
  const [currentReports, setCurrentReports] = useState([]);
  const [reportHeaderType, setReportHeaderType] = useState('');
  const currentDate = moment(Date.now()).format('MM/DD/YYYY');
  const auth = useAuth();
  const {
    currentLocation,
    currentMethod,
    currentInstrument,
    currentShortKey,
    currentFullKey,
    currentSummary,
    currentSampleName,
    currentParameter,
    resultsDataTableData,
    currentControlStrategyConfigurations,
    chartData
  } = useContext(ChartContext);
  const { data: assessment } = useFindAssessmentsByShortKey(currentShortKey);
  const { data: reports } = useGetReportsByFullKey(currentFullKey);
  const history = useHistory();

  useEffect(() => {
    if (Array.isArray(reports)) {
      setCurrentReports(reports);
    }
  }, [reports]);

  const handleTrendingClick = (e) => {
    setCurrentAssessmentTrending(e.target.value);
  };

  const handleClusteringClick = (e) => {
    setCurrentAssessmentClustering(e.target.value);
  };

  const handleCyclicalPatternsClick = (e) => {
    setCurrentAssessmentCyclicalPatterns(e.target.value);
  };

  const handleDataNormalClick = (e) => {
    setCurrentAssessmentDataNormal(e.target.value);
  };

  const handleTpiAssessmentClick = (e) => {
    setCurrentAssessmentTpiAssessment(e.target.value);
  };

  const handleCommentChange = (e) => {
    setCurrentAssessmentComments(e.target.value);
  };

  const handleReportCommentChange = (e) => {
    setCurrentModalReport({
      ...currentModalReport,
      comments: e.target.value
    });
  };

  const handleReportOutcomeClick = (e) => {
    setCurrentModalReport({
      ...currentModalReport,
      outcome: e.target.value
    });
  };

  const handleOutcomeClick = (e) => {
    setCurrentAssessmentOutcome(e.target.value);
  };

  const selectPeriodicPage = (e) => {
    setPeriodicPage(e.target.value);
  };

  useEffect(() => {
    if (Array.isArray(assessment)) {
      setCurrentResultsData(assessment);
    }
  }, [assessment]);

  const reportColumns = [
    {
      field: 'completedOn',
      headerName: 'Completed On',
      sortable: false,
      flex: 1
    },
    {
      field: 'completedBy',
      headerName: 'Completed By',
      flex: 1,
      sortable: false
    },
    {
      field: 'method',
      headerName: 'Method',
      flex: 1,
      sortable: false
    },
    {
      field: 'parameter',
      headerName: 'Parameter',
      flex: 1,
      sortable: false
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 1,
      sortable: false
    },
    {
      field: 'delete',
      headerName: 'View',
      flex: 1,
      sortable: false,
      editable: false,
      renderCell: (params) => {
        return (
          <div>
            <button
              onClick={() => {
                setCurrentModalReport(params.row);
                setReportHeaderType('View');
                setShowModal(true);
              }}
              type="button"
              style={{ border: 'none' }}
            >
              View
            </button>
          </div>
        );
      }
    }
  ];

  const rowEvents = {
    onClick: (e, row) => {
      setCurrentModalReport(row);
      setReportHeaderType('View');
      setShowModal(true);
    }
  };

  const tenNinetyColumns = [
    {
      field: 'parameter',
      headerName: 'Parameter',
      sortable: false,
      flex: 1
    },
    {
      field: 'value',
      headerName: 'Value',
      sortable: false,
      flex: 1
    }
  ];

  const tenNinetyData = [
    {
      id: randomId(),
      parameter: 'Start Date',
      value: currentModalReport.startDate
    },
    {
      id: randomId(),
      parameter: 'End Date',
      value: currentModalReport.endDate
    },
    {
      id: randomId(),
      parameter: 'Sample Size (n)',
      value: currentModalReport.sampleSize
    },
    {
      id: randomId(),
      parameter: 'Average',
      value: currentModalReport.average
    },
    {
      id: randomId(),
      parameter: 'X1',
      value: currentModalReport.x1
    },
    {
      id: randomId(),
      parameter: 'X2 Reproducibility',
      value: currentModalReport.x2_repro
    },
    {
      id: randomId(),
      parameter: 'X2 Repeatability',
      value: currentModalReport.x2_repeat
    },
    {
      id: randomId(),
      parameter: 'X3',
      value: currentModalReport.x3
    },
    {
      id: randomId(),
      parameter: 'σmax',
      value: currentModalReport.thetaMax
    },
    {
      id: randomId(),
      parameter: 'σRMS',
      value: currentModalReport.thetaRms
    },
    {
      id: randomId(),
      parameter: 'σMR',
      value: currentModalReport.thetaMr
    },
    {
      id: randomId(),
      parameter: 'Evaluation',
      value: currentModalReport.evaluation
    }
  ];

  const handlePrintClick = () => {
    history.push({
      pathname: '/print-report',
      state: {
        currentModalReport,
        tenNinetyData,
        tenNinetyColumns
      }
    });
  };

  const pageSizeDefault = 20;

  const options = {
    sizePerPage: 20,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true
  };

  const cellEditProp = {
    blurToSave: true
  };

  const buildReportObject = () => {
    const reportToSave = {
      startDate:
        currentSummary['v_1090 user dateMin'] !== ''
          ? currentSummary['v_1090 user dateMin']
          : currentSummary.v_1090_dateMin,
      endDate:
        currentSummary['v_1090 user dateMax'] !== ''
          ? currentSummary['v_1090 user dateMax']
          : currentSummary.v_1090_dateMax,
      fullKey: currentFullKey,
      method: currentMethod,
      instrument: currentInstrument,
      parameter: currentParameter,
      location: currentLocation,
      fab: currentControlStrategyConfigurations.tenNinetyMaterial,
      pretreated: currentControlStrategyConfigurations.pretreatData,
      sampleSize: currentSummary.v_1090_n,
      average: currentSummary.v_1090_avg,
      x1: currentSummary.v_1090_x1,
      x2_repro: currentSummary['v_1090_x2 repro'],
      x2_repeat: currentSummary['v_1090_x2 repeat'],
      x3: currentSummary.v_1090_x3,
      thetaMax: currentSummary.v_1090_theta ? currentSummary.v_1090_theta : '',
      thetaRms: currentSummary.v_1090_sd_RMS ? currentSummary.v_1090_sd_RMS : '',
      thetaMr: currentSummary.v_1090_sd_MR ? currentSummary.v_1090_sd_MR : '',
      evaluation: currentSummary.v_1090_eval,
      comments: '',
      outcome: '',
      type: '1090 Precision',
      completedBy: auth.user.email,
      completedOn: moment().format('MM/DD/YYYY')
    };
    return reportToSave;
  };

  const handleGenerateReport = () => {
    setCurrentModalReport(buildReportObject());
    setReportHeaderType('Generate');
    setShowModal(true);
  };

  const createNewReport = () => {
    createReport(currentModalReport);
    setShowModal(false);
  };

  const renderPeriodicPage = (page) => {
    switch (page) {
      case '1':
        return (
          <>
            <Row className="report-header">
              <h4>Summary Statistics: 50 Evap</h4>
            </Row>
            <Row className="statistics-table">
              <CustomTable
                numberOfRows={50}
                hideFooter={true}
                data={summaryStatisticsDataPeriodic}
                header={summaryStatisticsColumnsPeriodic}
              />
            </Row>
            <div>
              <table>
                <tr />
              </table>
            </div>
            <Row className="report-header">
              <h4>Assessment: 50 Evap</h4>
            </Row>
            <Row class="pt-2">
              <Col lg={7}>
                <h6>1. Visual Assessment for suspicious results (including GESD outliers)</h6>
              </Col>
              <Col>
                <ButtonGroup
                  onClick={handleTrendingClick}
                  aria-label="Basic example"
                  value={currentAssessmentTrending}
                >
                  <Button value="pass">Pass</Button>
                  <Button value="fail" variant="outline-primary">
                    Fail
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
            <Row class="pt-2">
              <Col lg={7}>
                <h6>2. Visual Assessment for unusual patterns (trending, clustering, cycles)</h6>
              </Col>
              <Col>
                <ButtonGroup
                  onClick={handleClusteringClick}
                  aria-label="Basic example"
                  value={currentAssessmentClustering}
                >
                  <Button value="pass">Pass</Button>
                  <Button value="fail" variant="outline-primary">
                    Fail
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
            <Row>
              <Col lg={7}>
                <h6>3. Evaluation of TPI to assess site precision vs Reproducibility</h6>
              </Col>
              <Col>
                <ButtonGroup
                  onClick={handleCyclicalPatternsClick}
                  aria-label="Basic example"
                  value={currentAssessmentCyclicalPatterns}
                >
                  <Button value="pass">Pass</Button>
                  <Button value="fail" variant="outline-primary">
                    Fail
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
            <Row>
              <Col lg={7}>
                <h6>
                  4. Performance of Chi Square test to assess site precision vs Reproducibility
                </h6>
              </Col>
              <Col>
                <ButtonGroup
                  onClick={handleDataNormalClick}
                  aria-label="Basic example"
                  value={currentAssessmentDataNormal}
                >
                  <Button value="pass">Pass</Button>
                  <Button value="fail" variant="outline-primary">
                    Fail
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
            <Row>
              <Col lg={7}>
                <h6>5. Assessment of normal probability plot (for linearity) and histogram</h6>
              </Col>
              <Col>
                <ButtonGroup
                  class="mt-2"
                  onClick={handleTpiAssessmentClick}
                  aria-label="Basic example"
                  value={currentAssessmentTpiAssessment}
                >
                  <Button value="pass">Pass</Button>
                  <Button value="fail" variant="outline-primary">
                    Fail
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
            <Row>
              <Col lg={7}>
                <h6>6. Evaluation of Anderson-Darling test statistic for normality</h6>
              </Col>
              <Col>
                <ButtonGroup
                  onClick={handleTpiAssessmentClick}
                  aria-label="Basic example"
                  value={currentAssessmentTpiAssessment}
                >
                  <Button value="pass">Pass</Button>
                  <Button value="fail" variant="outline-primary">
                    Fail
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
            <Row>
              <Col lg={7}>
                <h6>7. Performance of t-Test to evaluate accuracy / bias</h6>
              </Col>
              <Col>
                <ButtonGroup
                  onClick={handleTpiAssessmentClick}
                  aria-label="Basic example"
                  value={currentAssessmentTpiAssessment}
                >
                  <Button value="pass">Pass</Button>
                  <Button value="fail" variant="outline-primary">
                    Fail
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
            <Row>
              <Col lg={7}>
                <h6>8. Performance of F-Test to evaluate changes in variance</h6>
              </Col>
              <Col>
                <ButtonGroup
                  onClick={handleTpiAssessmentClick}
                  aria-label="Basic example"
                  value={currentAssessmentTpiAssessment}
                >
                  <Button value="pass">Pass</Button>
                  <Button value="fail" variant="outline-primary">
                    Fail
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
            <Row>
              <Col lg={7}>
                <h6>Assessor comments</h6>
              </Col>
            </Row>
            <Row>
              <Col lg={8}>
                <Form>
                  <Form.Group>
                    <Form.Control
                      className="comments"
                      as="textarea"
                      rows={2}
                      onChange={handleCommentChange}
                      aria-label="Basic example"
                      value={currentAssessmentComments}
                    />
                  </Form.Group>
                </Form>
              </Col>
            </Row>
            <Row>
              <Col lg={7}>
                <h6>Assessment outcome</h6>
              </Col>
              <Col>
                <ButtonGroup
                  onClick={handleOutcomeClick}
                  aria-label="Basic example"
                  value={currentAssessmentOutcome}
                >
                  <Button value="pass">Pass</Button>
                  <Button value="fail" variant="outline-primary">
                    Fail
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
          </>
        );
      case '2':
        return (
          <>
            <Row className="report-header">
              <h4>Chart: 50 Evap</h4>
            </Row>
            <div>
              <div className="chart-row-container">
                <SQCLineChart
                  xAxis="updatedIndex"
                  syncId="chained"
                  yMin={chartScalars.cYMin}
                  yMax={chartScalars.cYMax}
                  data={chartData}
                  lines={[
                    {
                      yAxis: 'resultOmits',
                      name: 'Data',
                      color: '#32CD32'
                    },
                    {
                      yAxis: 'ewmaOmits',
                      name: 'Trend',
                      color: '#B0E0E6'
                    },
                    {
                      yAxis: 'mean',
                      name: 'Average',
                      reference: chartData.length > 20,
                      label: `Average: ${chartData[0].mean}`,
                      color: '#FFBF00'
                    },
                    {
                      yAxis: 'sd15p',
                      label: `-1.5 Sigma: ${chartData[0].sd15p}`,
                      color: '#9932CC',
                      reference: true
                    },
                    {
                      yAxis: 'sd15n',
                      label: `+1.5 Sigma: ${chartData[0].sd15n}`,
                      color: '#9932CC',
                      reference: true
                    },
                    {
                      yAxis: 'sd3n',
                      label: `LCL: ${chartData[0].sd3n}`,
                      color: '#FBCEB1',
                      reference: true
                    },
                    {
                      yAxis: 'sd3p',
                      label: `UCL: ${chartData[0].sd3p}`,
                      color: '#FBCEB1',
                      reference: true
                    }
                  ]}
                />
                <SQCMovingRangeChart
                  xAxis="updatedIndex"
                  key={uuidv4()}
                  data={chartData}
                  syncId="chained2"
                  yMin={0}
                  yMax={chartScalars.mrYMax}
                  lines={[
                    {
                      yAxis: 'mrOmits',
                      name: 'Data',
                      color: '#32CD32'
                    },
                    {
                      yAxis: 'mean',
                      overrideValue: chartScalars.mrMean,
                      name: 'Average',
                      reference: true,
                      label: `Average: ${chartScalars.mrMean}`,
                      color: '#FFBF00'
                    },
                    {
                      yAxis: 'sd3p',
                      overrideValue: chartScalars.mrUCL,
                      label: `UCL: ${chartScalars.mrUCL.toFixed(3)}`,
                      color: '#FBCEB1',
                      reference: true
                    }
                  ]}
                />
              </div>
              <Row>
                <Col>
                  <>
                    {chartScalars.histogramData && (
                      <SQCHistogram data={chartScalars.histogramData} />
                    )}
                    {!chartScalars.histogramData && <div>No Histogram</div>}
                  </>
                </Col>
                <Col>
                  <SQCScatterPlot
                    xAxis="zScoreOmits"
                    key={uuidv4()}
                    data={chartData}
                    syncId="chained2"
                    xMin={chartScalars.qqXMin}
                    xMax={chartScalars.qqXMax}
                    yMin={chartScalars.qqYMin}
                    yMax={chartScalars.qqYMax}
                    collections={[
                      {
                        name: 'Lab Results',
                        color: '#32CD32',
                        data: chartData.map((cd) => {
                          return {
                            x: cd.zScoreOmits,
                            y: cd.resultOmits
                          };
                        })
                      },
                      {
                        name: 'Trend Line',
                        color: '#FFBF00',
                        data: chartScalars.trendData,
                        line: true
                      }
                    ]}
                  />
                </Col>
              </Row>
            </div>
          </>
        );
      case '3':
        return (
          <>
            <Row className="report-header">
              <h4>Data: 50 Evap</h4>
            </Row>
            <div className="page-three-container">
              <CustomTable
                numberOfRows={pageSizeDefault}
                data={filteredChartHits}
                header={defaultColumnsReport}
                sort={{ sorting: { sortModel: [{ field: 'dateTime', sort: 'desc' }] } }}
              />
            </div>
          </>
        );
      default:
        return <div>Page not found</div>;
    }
  };

  return (
    <>
      <div className="assessments-container">
        <div className="report-header-container">
          <div>
            <h3>Reports</h3>
          </div>
          <div>
            {radioArchiveValue === 'Active' &&
              renderButtonWithPermissions(
                'Generate Report',
                () => handleGenerateReport(),
                SCREEN?.REPORT,
                RULES.CREATE,
                permissionData
              )}
          </div>
        </div>
        <div className="assessments-table-container">
          <CustomTable
            numberOfRows={pageSizeDefault}
            data={currentReports}
            header={reportColumns}
          />
        </div>
      </div>
      <Modal size="xl" show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{reportHeaderType} Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <img alt="logo" src={currentLogo} width="200" />
            </Col>
            <Col>
              <h3>ASTM SQC Insights</h3>
            </Col>
          </Row>
          <Row className="report-header">
            <h4>EPA Compliance Report for Precision of Alternative Procedure: 40 CFR 1090.1365</h4>
          </Row>
          <Row>
            <Col>Method:</Col>
            <Col>{currentModalReport.method}</Col>
            <Col>Location:</Col>
            <Col>{currentModalReport.location}</Col>
          </Row>
          <Row>
            <Col>Instrument:</Col>
            <Col>{currentModalReport.instrument}</Col>
            <Col>Date:</Col>
            <Col>{currentModalReport.completedOn}</Col>
          </Row>
          <Row>
            <Col>Fuel, additive or blendstock:</Col>
            <Col>{currentModalReport.fab}</Col>
            <Col>Assessment by:</Col>
            <Col>{currentModalReport.completedBy}</Col>
          </Row>
          <Row>
            <Col>Parameter:</Col>
            <Col>{currentModalReport.parameter}</Col>
            <Col>Pre-treated:</Col>
            <Col>{currentModalReport.pretreated}</Col>
          </Row>
          <Row className="report-header" />
          <Row className="statistics-table">
            <CustomTable
              numberOfRows={pageSizeDefault}
              data={tenNinetyData}
              header={tenNinetyColumns}
              hideFooter={true}
            />
          </Row>
          <div>
            <table>
              <tr>{/* {renderAssessmentTableHeader(resultsDataTableData)} */}</tr>
            </table>
          </div>
          <Row>
            <Col lg={7}>
              <h6>6. Assessor comments</h6>
            </Col>
          </Row>
          <Row className="report-comment-container">
            <Col lg={8}>
              <Form>
                <Form.Group>
                  <Form.Control
                    className="comments"
                    as="textarea"
                    rows={2}
                    onChange={handleReportCommentChange}
                    aria-label="Basic example"
                    value={currentModalReport.comments}
                  />
                </Form.Group>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col lg={7}>
              <h6>7. Assessment outcome</h6>
            </Col>
            <Col>
              <ToggleButton
                key="radioTrending-pass"
                id="radioTrending-pass"
                type="radio"
                variant="outline-primary"
                name="radio"
                value="Pass"
                style={
                  currentModalReport.outcome === 'Pass'
                    ? { backgroundColor: 'navy', color: 'white' }
                    : {}
                }
                checked={currentModalReport.outcome === 'Pass'}
                onChange={handleReportOutcomeClick}
              >
                Pass
              </ToggleButton>
              <ToggleButton
                key="radioTrending-fail"
                id="radioTrending-fail"
                type="radio"
                variant="outline-primary"
                name="radio"
                value="Fail"
                style={
                  currentModalReport.outcome === 'Fail'
                    ? { backgroundColor: 'navy', color: 'white' }
                    : {}
                }
                checked={currentModalReport.outcome === 'Fail'}
                onChange={handleReportOutcomeClick}
              >
                Fail
              </ToggleButton>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {radioArchiveValue === 'Active' &&
            renderButtonWithPermissions(
              'Save',
              () => createNewReport(),
              SCREEN?.REPORT,
              RULES.SAVE,
              permissionData
            )}
          <PrimaryButton text="Print" clicked={() => handlePrintClick()} />
        </Modal.Footer>
      </Modal>

      <Modal size="xl" show={showPeriodicModal} onHide={() => setShowPeriodicModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Chart Assessment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <img alt="logo" src={currentLogo} width="200" />
            </Col>
            <Col style={{ textAlign: 'center' }}>
              <h3>ASTM SQC Insights</h3>
            </Col>
            <Col>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'right'
                }}
              >
                <div>Page</div>
                <Form.Select
                  style={{ width: '70px', marginLeft: '10px' }}
                  value={periodicPage}
                  onChange={selectPeriodicPage}
                  aria-label="Default select example"
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
          <Row className="report-header">
            <h4>Periodic Assessment Report</h4>
          </Row>
          <Row>
            <Col>Method:</Col>
            <Col>{currentMethod}</Col>
            <Col>Date:</Col>
            <Col>{currentDate}</Col>
          </Row>
          <Row>
            <Col>Instrument:</Col>
            <Col>{currentInstrument}</Col>
            <Col>Assessment by:</Col>
            <Col>{resultsDataTableData[0]?.personnel}</Col>
          </Row>
          <Row>
            <Col>Sample Name:</Col>
            <Col>{currentSampleName}</Col>
            <Col>Pre-treated:</Col>
            <Col>{currentControlStrategyConfigurations.pretreatData}</Col>
          </Row>
          <Row>
            <Col>Units:</Col>
            <Col>{unit}</Col>
            <Col />
            <Col />
          </Row>
          {renderPeriodicPage(periodicPage)}
        </Modal.Body>
      </Modal>
    </>
  );
}

ReportsSection.propTypes = {
  chartScalars: PropTypes.arrayOf(PropTypes.object).isRequired,
  filteredChartHits: PropTypes.arrayOf(PropTypes.object).isRequired
};
