import React, { useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import ToggleButton from 'react-bootstrap/ToggleButton';
import moment from 'moment';
import './PrintAssessmentPage.scss';
import { useLocation } from 'react-router-dom';
import { requireAuth } from '../../util/auth';
import CustomTable from '../Table/CustomTable';
import { Button } from 'bootstrap';
import { defaultColumnsReport, summaryStatisticsColumnsPeriodic } from '../Columns';
import SQCLineChart from '../SQCLineChart';
import { getSigDigitsUtil } from '../../util/util';
import SQCMovingRangeChart from '../SQCMovingRangeChart';
import { v4 as uuidv4 } from 'uuid';
import SQCHistogram from '../SQCHistogram';
import SQCScatterPlot from '../SQCScatterPlot';
import PrintOverviewDataTable from '../Table/PrintOverviewDataTable';

function PrintPeriodicAssessmentPage() {
  const location = useLocation();
  const {
    modalAssessment,
    currentMethod,
    currentInstrument,
    currentParameter,
    currentSampleName,
    unit,
    currentControlStrategyConfigurations,
    chartScalars,
    chartData,
    periodicPage,
    filteredChartHits,
    tz,
    dateTimeFormat
  } = location.state || {};
  const pageSizeDefault = 20;

  useEffect(() => {
    setTimeout(() => {
      window.print();
    }, 500);
  }, []);

  const renderSigDigits = (stringValue) => {
    return getSigDigitsUtil(stringValue, currentControlStrategyConfigurations?.chartSigDigits);
  };

  const renderPeriodicPage = (page) => {
    switch (page) {
      case '1':
        return (
          <>
            <Row className="report-header">
              <h4>
                Summary Statistics: {currentParameter}, {unit}
              </h4>
            </Row>
            <Row className="statistics-table">
              <CustomTable
                hideFooter={true}
                data={modalAssessment.summaryStatistics}
                header={summaryStatisticsColumnsPeriodic}
              />
            </Row>
            <div>
              <table>
                <tr />
              </table>
            </div>
            <Row className="report-header">
              <h4>
                Assessment:
                {currentParameter}
              </h4>
            </Row>
            <div className="assessment-row">
              <div>
                <h6 className="ia-table" style={{ marginLeft: '50px' }}>
                  1. Visual Assessment for suspicious results (including GESD outliers)
                </h6>
              </div>
              <div className="assessment-row-btn-group">
                <ToggleButton
                  key="radioSR-pass"
                  id="radioSR-pass"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.suspiciousResults === 'Pass'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Pass"
                  checked={modalAssessment.suspiciousResults === 'Pass'}
                >
                  Pass
                </ToggleButton>
                <ToggleButton
                  key="radioSR-fail"
                  id="radioSR-fail"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.suspiciousResults === 'Fail'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Fail"
                  checked={modalAssessment.suspiciousResults === 'Fail'}
                >
                  Fail
                </ToggleButton>
              </div>
            </div>
            <div className="assessment-row">
              <div>
                <h6 className="ia-table" style={{ marginLeft: '50px' }}>
                  2. Visual Assessment for unusual patterns (trending, clustering, cycles)
                </h6>
              </div>
              <div className="assessment-row-btn-group">
                <ToggleButton
                  key="radioUP-pass"
                  id="radioUP-pass"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.unusualPatterns === 'Pass'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Pass"
                  checked={modalAssessment.unusualPatterns === 'Pass'}
                >
                  Pass
                </ToggleButton>
                <ToggleButton
                  key="radioUP-fail"
                  id="radioUP-fail"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.unusualPatterns === 'Fail'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Fail"
                  checked={modalAssessment.unusualPatterns === 'Fail'}
                >
                  Fail
                </ToggleButton>
              </div>
            </div>
            <div className="assessment-row">
              <div>
                <h6 className="ia-table" style={{ marginLeft: '50px' }}>
                  3. Evaluation of TPI to assess site precision vs Reproducibility
                </h6>
              </div>
              <div className="assessment-row-btn-group">
                <ToggleButton
                  key="radioTE-pass"
                  id="radioTE-pass"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.tpiEvaluation === 'Pass'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Pass"
                  checked={modalAssessment.tpiEvaluation === 'Pass'}
                >
                  Pass
                </ToggleButton>
                <ToggleButton
                  key="radioTE-fail"
                  id="radioTE-fail"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.tpiEvaluation === 'Fail'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Fail"
                  checked={modalAssessment.tpiEvaluation === 'Fail'}
                >
                  Fail
                </ToggleButton>
              </div>
            </div>
            <div className="assessment-row">
              <div>
                <h6 className="ia-table" style={{ marginLeft: '50px' }}>
                  4. Performance of Chi Square test to assess site precision vs Reproducibility
                </h6>
              </div>
              <div className="assessment-row-btn-group">
                <ToggleButton
                  key="radioCST-pass"
                  id="radioCST-pass"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.chiSquareTest === 'Pass'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Pass"
                  checked={modalAssessment.chiSquareTest === 'Pass'}
                >
                  Pass
                </ToggleButton>
                <ToggleButton
                  key="radioCST-fail"
                  id="radioCST-fail"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.chiSquareTest === 'Fail'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Fail"
                  checked={modalAssessment.chiSquareTest === 'Fail'}
                >
                  Fail
                </ToggleButton>
              </div>
            </div>
            <div className="assessment-row">
              <div>
                <h6 className="ia-table" style={{ marginLeft: '50px' }}>
                  5. Assessment of normal probability plot (for linearity) and histogram
                </h6>
              </div>
              <div className="assessment-row-btn-group">
                <ToggleButton
                  key="radioNPP-pass"
                  id="radioNPP-pass"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.normalProbabilityPlot === 'Pass'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Pass"
                  checked={modalAssessment.normalProbabilityPlot === 'Pass'}
                >
                  Pass
                </ToggleButton>
                <ToggleButton
                  key="radioNPP-fail"
                  id="radioNPP-fail"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.normalProbabilityPlot === 'Fail'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Fail"
                  checked={modalAssessment.normalProbabilityPlot === 'Fail'}
                >
                  Fail
                </ToggleButton>
              </div>
            </div>
            <div className="assessment-row">
              <div>
                <h6 className="ia-table" style={{ marginLeft: '50px' }}>
                  6. Evaluation of Anderson-Darling test statistic for normality
                </h6>
              </div>
              <div className="assessment-row-btn-group">
                <ToggleButton
                  key="radioAD-pass"
                  id="radioAD-pass"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.andersonDarling === 'Pass'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Pass"
                  checked={modalAssessment.andersonDarling === 'Pass'}
                >
                  Pass
                </ToggleButton>
                <ToggleButton
                  key="radioAD-fail"
                  id="radioAD-fail"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.andersonDarling === 'Fail'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Fail"
                  checked={modalAssessment.andersonDarling === 'Fail'}
                >
                  Fail
                </ToggleButton>
              </div>
            </div>
            <div className="assessment-row">
              <div>
                <h6 className="ia-table" style={{ marginLeft: '50px' }}>
                  7. Performance of t-Test to evaluate accuracy / bias
                </h6>
              </div>
              <div className="assessment-row-btn-group">
                <ToggleButton
                  key="radiotT-pass"
                  id="radiotT-pass"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.tTest === 'Pass'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Pass"
                  checked={modalAssessment.tTest === 'Pass'}
                >
                  Pass
                </ToggleButton>
                <ToggleButton
                  key="radiotT-fail"
                  id="radiotT-fail"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.tTest === 'Fail'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Fail"
                  checked={modalAssessment.tTest === 'Fail'}
                >
                  Fail
                </ToggleButton>
              </div>
            </div>
            <div className="assessment-row">
              <div>
                <h6 className="ia-table" style={{ marginLeft: '50px' }}>
                  8. Performance of F-Test to evaluate changes in variance
                </h6>
              </div>
              <div className="assessment-row-btn-group">
                <ToggleButton
                  key="radiofT-pass"
                  id="radiofT-pass"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.fTest === 'Pass'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Pass"
                  checked={modalAssessment.fTest === 'Pass'}
                >
                  Pass
                </ToggleButton>
                <ToggleButton
                  key="radiofT-fail"
                  id="radiofT-fail"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.fTest === 'Fail'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Fail"
                  checked={modalAssessment.fTest === 'Fail'}
                >
                  Fail
                </ToggleButton>
              </div>
            </div>
            <Row>
              <Col lg={9}>
                <h6 className="ia-table" style={{ marginLeft: '50px' }}>
                  Assessor comments
                </h6>
              </Col>
            </Row>
            <Row>
              <Col lg={9}>
                <Form className="ia-table" style={{ marginLeft: '50px' }}>
                  <Form.Group>
                    <Form.Control
                      className="comments"
                      as="textarea"
                      rows={2}
                      aria-label="Basic example"
                      value={modalAssessment.assessorComments}
                    />
                  </Form.Group>
                </Form>
              </Col>
            </Row>
            <div className="assessment-row" style={{ marginTop: '15px', marginBottom: '15px' }}>
              <div>
                <h6 className="ia-table" style={{ marginLeft: '50px' }}>
                  Assessment outcome
                </h6>
              </div>
              <div className="assessment-row-btn-group">
                <ToggleButton
                  key="radioOutcome-pass"
                  id="radioOutcome-pass"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.outcome === 'Pass'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Pass"
                  checked={modalAssessment.outcome === 'Pass'}
                >
                  Pass
                </ToggleButton>
                <ToggleButton
                  key="radioOutcome-fail"
                  id="radioOutcome-fail"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.outcome === 'Fail'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Fail"
                  checked={modalAssessment.outcome === 'Fail'}
                >
                  Fail
                </ToggleButton>
              </div>
            </div>
          </>
        );
      case '2':
        return (
          <>
            <Row className="report-header">
              <h4>
                Chart:
                {currentParameter}
              </h4>
            </Row>
            <Row>
              <Col lg={6}>
                <SQCLineChart
                  xAxis="updatedIndex"
                  syncId="chained"
                  yMin={chartScalars.cYMin}
                  yMax={chartScalars.cYMax}
                  data={chartData}
                  chartSigDigits={currentControlStrategyConfigurations?.chartSigDigits}
                  lines={[
                    {
                      yAxis: 'resultOmits',
                      name: 'Data',
                      color: '#32CD32'
                    },
                    {
                      yAxis: 'ewmaOmits',
                      name: 'Trend',
                      color: '#B0E0E6'
                    },
                    {
                      yAxis: 'mean',
                      name: 'Average',
                      reference: chartData.length > 20,
                      label: `Average: ${renderSigDigits(chartData[chartData.length - 1].mean)}`,
                      color: '#FFBF00'
                    },
                    {
                      yAxis: 'sd15p',
                      label: `-1.5 Sigma: ${renderSigDigits(
                        chartData[chartData.length - 1].sd15p
                      )}`,
                      color: '#9932CC',
                      reference: true
                    },
                    {
                      yAxis: 'sd15n',
                      label: `+1.5 Sigma: ${renderSigDigits(
                        chartData[chartData.length - 1].sd15n
                      )}`,
                      color: '#9932CC',
                      reference: true
                    },
                    {
                      yAxis: 'sd3n',
                      label: `LCL: ${renderSigDigits(chartData[chartData.length - 1].sd3n)}`,
                      color: '#FBCEB1',
                      reference: true
                    },
                    {
                      yAxis: 'sd3p',
                      label: `UCL: ${renderSigDigits(chartData[chartData.length - 1].sd3p)}`,
                      color: '#FBCEB1',
                      reference: true
                    }
                  ]}
                />
              </Col>
              <Col lg={6}>
                <SQCMovingRangeChart
                  xAxis="updatedIndex"
                  key={uuidv4()}
                  data={chartData}
                  syncId="chained2"
                  yMin={0}
                  yMax={chartScalars.mrYMax}
                  chartSigDigits={currentControlStrategyConfigurations?.chartSigDigits}
                  lines={[
                    {
                      yAxis: 'mrOmits',
                      name: 'Data',
                      color: '#32CD32'
                    },
                    {
                      yAxis: 'mean',
                      overrideValue: chartScalars.mrMean,
                      name: 'Average',
                      reference: true,
                      label: `Average: ${renderSigDigits(chartScalars.mrMean)}`,
                      color: '#FFBF00'
                    },
                    {
                      yAxis: 'sd3p',
                      overrideValue: chartScalars.mrUCL,
                      label: `UCL: ${renderSigDigits(chartScalars.mrUCL)}`,
                      color: '#FBCEB1',
                      reference: true
                    }
                  ]}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <>
                  {chartScalars.histogramData && (
                    <SQCHistogram
                      chartSigDigits={currentControlStrategyConfigurations?.chartSigDigits}
                      data={chartScalars.histogramData}
                    />
                  )}
                  {!chartScalars.histogramData && <div>No Histograsm</div>}
                </>
              </Col>
              <Col lg={6}>
                <SQCScatterPlot
                  xAxis="zScoreOmits"
                  key={uuidv4()}
                  data={chartData}
                  syncId="chained2"
                  xMin={chartScalars.qqXMin}
                  xMax={chartScalars.qqXMax}
                  yMin={chartScalars.qqYMin}
                  yMax={chartScalars.qqYMax}
                  chartSigDigits={currentControlStrategyConfigurations?.chartSigDigits}
                  collections={[
                    {
                      name: 'Lab Results',
                      color: '#32CD32',
                      data: chartData.map((cd) => {
                        return {
                          x: cd.zScoreOmits,
                          y: cd.resultOmits
                        };
                      })
                    },
                    {
                      name: 'Trend Line',
                      color: '#FFBF00',
                      data: chartScalars.trendData,
                      line: true
                    }
                  ]}
                />
              </Col>
            </Row>
          </>
        );
      case '3':
        return (
          <>
            <Row className="report-header">
              <h4>
                Data:
                {currentParameter}
              </h4>
            </Row>
            <Row className="page-three-container">
              <PrintOverviewDataTable
                columns={defaultColumnsReport}
                data={filteredChartHits}
                tz={tz}
              />
            </Row>
            <Row className="report-footer">
              <Col>
                <p>Report generated on: {moment().format('MM/DD/YYYY')}</p>
              </Col>
              <Col>
                <p>Generated by: {modalAssessment?.createdBy}</p>
              </Col>
            </Row>
          </>
        );
      default:
        return <div>Invalid Report Page</div>;
    }
  };

  return (
    <>
      <Row className="report-header">
        <h4>Periodic Assessment Report</h4>
      </Row>
      <Row className="ia-table" style={{ paddingLeft: '32px' }}>
        <Col>
          <Row>
            <Col>Method:</Col>
            <Col>{currentMethod}</Col>
          </Row>
          <Row>
            <Col>Instrument:</Col>
            <Col>{currentInstrument}</Col>
          </Row>
          <Row>
            <Col>Sample Name:</Col>
            <Col>{currentSampleName}</Col>
          </Row>
          <Row>
            <Col>Units:</Col>
            <Col>{unit}</Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col>Parameter:</Col>
            <Col>{modalAssessment?.fullKey?.split('|')[4]}</Col>
          </Row>
          <Row>
            <Col>Date:</Col>
            <Col>{moment(modalAssessment.created).format('MM/DD/YYYY')}</Col>
          </Row>
          <Row>
            <Col>Assessment by:</Col>
            <Col>{modalAssessment?.createdBy}</Col>
          </Row>
          <Row>
            <Col>Pre-treat:</Col>
            <Col>{currentControlStrategyConfigurations.pretreatData}</Col>
          </Row>
        </Col>
      </Row>
      {renderPeriodicPage(periodicPage)}
    </>
  );
}

export default requireAuth(PrintPeriodicAssessmentPage);
