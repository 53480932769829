import { Box, Button, CardContent, CardHeader, Collapse, IconButton, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomTable from '../../Table/CustomTable';
import { listNotificationGroupsColumns } from './Columns';
import {
  deleteInstrumentNotificationGroups,
  setInstrumentNotificationGroups,
  useGetPersonnel,
  useInstrumentNotificationGroups
} from '../../../util/db';
import { Delete, Edit, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import ModalFormNotificationGroup from '../Modal/ModalFormNotificationGroup';
import { Card } from 'react-bootstrap';
import { useAuth } from '../../../util/auth';
import { hasPermission } from '../utils';
import { RULES, SCREEN } from '../../../util/Constant';
import { renderMUIButtonWithPermissions } from '../../../util/util';

const NotificationGroups = () => {
  const auth = useAuth();
  const { data: personnel } = useGetPersonnel();
  const { data: notificationGroups = [] } = useInstrumentNotificationGroups();
  const [isOpen, setOpen] = useState(false);
  const [currentGroup, setCurrentGroup] = useState(null);
  const [isOpenUser, setToggleUser] = useState([]);
  const [permissionData, setPermissionData] = useState(null);

  useEffect(() => {
    if (Array.isArray(personnel) && personnel.length > 0) {
      const matchingUser = personnel.find((person) => person.email === auth.user.email);
      if (matchingUser) {
        if (auth.permissionData) setPermissionData(auth.permissionData[matchingUser.permission]);
      }
    }
  }, [personnel]);
  const handleSave = async (data) => {
    const { name, ...personData } = data;
    const selectedPersons = Object.keys(personData).filter((personId) => personData[personId]);

    const payload = {
      ...(currentGroup || []),
      name,
      users: [...selectedPersons]
    };

    await setInstrumentNotificationGroups(payload);
    setCurrentGroup(null);
    setOpen(false);
  };

  const handleDelete = async (data) => {
    await deleteInstrumentNotificationGroups(data);
  };

  const groups = notificationGroups.map((row) => {
    return {
      ...row,
      users: (row.users || [])
        .map((userId) => {
          const user = personnel.find((item) => item.id === userId);
          return user || null;
        })
        .filter(Boolean)
    };
  });

  return (
    <>
      {hasPermission(permissionData, SCREEN?.INSTRUMENT_SETTINGS, RULES.VIEW) ? (
        <div className="grid-view">
          <div className="inner-actions">
            <h2>Notification Groups</h2>
            {renderMUIButtonWithPermissions(
              'Add new',
              () => setOpen(true),
              SCREEN?.INSTRUMENT_SETTINGS,
              RULES.CREATE,
              permissionData
            )}
          </div>
          <ModalFormNotificationGroup
            title={currentGroup ? 'Edit notification group' : 'Add notification group'}
            currentGroup={currentGroup}
            personnel={personnel}
            show={isOpen}
            handleClose={() => {
              setCurrentGroup(null);
              setOpen(false);
            }}
            handleSave={handleSave}
          />
          <Box display="flex" gap={2} flexDirection="column">
            {groups.map((group, index) => {
              return (
                <Card key={group.id}>
                  <CardHeader
                    sx={{
                      '& .MuiTypography-root': {
                        fontSize: '15px',
                        fontWeight: 700
                      }
                    }}
                    title={group.name}
                    action={
                      <>
                        <IconButton
                          onClick={() => {
                            const temp = [...isOpenUser];
                            temp[index] = !temp[index];
                            setToggleUser([...temp]);
                          }}
                        >
                          {isOpenUser[index] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                        </IconButton>
                        {hasPermission(
                          permissionData,
                          SCREEN?.INSTRUMENT_SETTINGS,
                          RULES.UPDATE
                        ) ? (
                          <IconButton
                            onClick={() => {
                              setCurrentGroup(group);
                              setOpen(true);
                            }}
                          >
                            <Edit />
                          </IconButton>
                        ) : (
                          <></>
                        )}
                        {hasPermission(
                          permissionData,
                          SCREEN?.INSTRUMENT_SETTINGS,
                          RULES.DELETE
                        ) ? (
                          <IconButton
                            onClick={() => {
                              if (confirm('Are you sure to delete this group?')) {
                                handleDelete(group);
                              }
                            }}
                          >
                            <Delete />
                          </IconButton>
                        ) : (
                          <></>
                        )}
                      </>
                    }
                  />
                  <Collapse in={isOpenUser[index]}>
                    <CardContent>
                      <CustomTable
                        numberOfRows={20}
                        data={group.users || []}
                        header={listNotificationGroupsColumns}
                        sort={{ sorting: { sortModel: [{ field: 'name', sort: 'asc' }] } }}
                        sx={{
                          '& .MuiDataGrid-iconButtonContainer[aria-label*="filter"]': {
                            display: 'none'
                          }
                        }}
                      />
                    </CardContent>
                  </Collapse>
                </Card>
              );
            })}
          </Box>
        </div>
      ) : (
        <> </>
      )}
    </>
  );
};

export default NotificationGroups;
