import * as XLSX from 'xlsx';
import moment from 'moment';
import { alphaNumericSorter } from '../../../util/util';

// Create a hash map of instruments and associated events
const createInstrumentEventMap = (events, eventTypeMapping) =>
  events.reduce((acc, event) => {
    const instrumentName = event.instrumentName;
    if (!acc[instrumentName]) {
      acc[instrumentName] = [];
    }
    const eventTypeName = eventTypeMapping.find((item) => item.id === event.eventType)?.name;
    acc[instrumentName].push({
      ...event,
      eventTypeName
    });

    return acc;
  }, {});

// Prepare data for the cost sheet
const prepareSheetData = (instruments, instrumentStatusLogs, eventMap, sheet) => {
  const currentYear = moment().year();
  const nowUnix = moment().unix();

  return instruments
    .map((instrument) => {
      const instrumentEvents = eventMap[instrument.id] || [];
      const numReports = instrumentEvents.filter(
        (e) => e.eventTypeName === 'Instrument Issue Report'
      ).length;
      const totalCost = instrumentEvents.reduce(
        (total, event) => total + parseFloat(event.cost || '0'),
        0
      );

      const expectedLifeYears = parseFloat(instrument?.expectedLifeYears) || 0;
      const inServiceDate = instrument?.inServiceDate?.seconds
        ? moment.unix(instrument.inServiceDate.seconds)
        : null;
      let yearInService = inServiceDate ? currentYear - inServiceDate.year() : 0;
      yearInService = Math.max(yearInService, 0);

      const lifeLeftPercent =
        expectedLifeYears > 0
          ? Math.max((expectedLifeYears - yearInService) / expectedLifeYears, 0)
          : 0;

      // Find latest "Out of Service" log
      const latestOutOfServiceLog = instrumentStatusLogs.reduce((latestLog, log) => {
        if (log.documentId === instrument.id && log.currentStatusName === 'Out of Services') {
          return !latestLog || log.createdAt.seconds > latestLog.createdAt.seconds
            ? log
            : latestLog;
        }
        return latestLog;
      }, null);

      const timeOutOfService = latestOutOfServiceLog?.createdAt
        ? Math.floor(
            moment.duration(nowUnix - latestOutOfServiceLog.createdAt.seconds, 'seconds').asDays()
          )
        : 0;

      const totalTimeFrame = inServiceDate
        ? Math.floor(moment.duration(nowUnix - inServiceDate.unix(), 'seconds').asDays())
        : 0;

      const outOfServicePercent = totalTimeFrame > 0 ? timeOutOfService / totalTimeFrame : 0;

      if (sheet === 'Cost') {
        return {
          location: instrument.location,
          name: instrument.name,
          type: instrument.type,
          parameter: instrument.parameter,
          lifeLeftPercent: lifeLeftPercent,
          numEvents: instrumentEvents.length,
          numReports,
          cost: parseFloat(instrument.cost) || 0,
          totalCostOfEvent: totalCost,
          outOfServicePercent: outOfServicePercent
        };
      } else {
        let instrumentHealthScore = 0;
        let parameterMultiplier = 1;
        let locationMultiplier = 1;

        // Health score calculations
        const healthScoreConditions = [
          { condition: numReports >= 0 && numReports <= 1, score: 20 },
          { condition: numReports >= 2 && numReports <= 5, score: 10 },
          { condition: outOfServicePercent >= 0 && outOfServicePercent <= 0.1, score: 20 },
          { condition: outOfServicePercent > 0.1 && outOfServicePercent <= 0.2, score: 10 },
          { condition: instrument.connectedInstrument, score: 20 },
          { condition: lifeLeftPercent > 0.3, score: 20 },
          { condition: lifeLeftPercent >= 0.1 && lifeLeftPercent <= 0.3, score: 10 },
          { condition: totalCost < 1000, score: 20 },
          { condition: totalCost >= 1001 && totalCost <= 10000, score: 10 }
        ];

        healthScoreConditions.forEach(({ condition, score }) => {
          if (condition) instrumentHealthScore += score;
        });

        // Parameter multiplier
        const parameterMultipliers = {
          RVP: 1.5,
          Flash: 1.5,
          'Online RVP': 1.5,
          Gravity: 1.25,
          Sulfur: 1.25,
          Distillation: 1.25,
          MSEP: 1.25,
          'Online Sulfur': 1.25
        };
        parameterMultiplier = parameterMultipliers[instrument.parameter] || 1;

        // Location multiplier
        const highMultiplierLocations = [
          'Houston',
          'Baton Rouge',
          'Greensboro',
          'Dorsey',
          'Linden',
          'Atlanta',
          'Pelham',
          'Belton'
        ];
        const mediumMultiplierLocations = [
          'Charlotte',
          'Woodbury',
          'Collins',
          'Woodbury',
          'Lake Charles',
          'Hebert',
          'Spartanburg',
          'Fairfax'
        ];
        const location = instrument.location || '';
        if (highMultiplierLocations.some((loc) => location.includes(loc))) {
          locationMultiplier = 1.5;
        } else if (mediumMultiplierLocations.some((loc) => location.includes(loc))) {
          locationMultiplier = 1.25;
        }

        // Final score calculation
        const score =
          ((100 - instrumentHealthScore) * parameterMultiplier * locationMultiplier) / 2;

        return {
          location: instrument.location,
          name: instrument.name,
          type: instrument.type,
          parameter: instrument.parameter,
          lifeLeftPercent: lifeLeftPercent,
          numEvents: instrumentEvents.length,
          numReports,
          cost: parseFloat(instrument.cost) || 0,
          totalCostOfEvent: totalCost,
          outOfServicePercent: outOfServicePercent,
          connectedInstrument: instrument.connectedInstrument,
          highPriorityInstrument: Number(instrument.highPriorityInstrument) || 0,
          instrumentHealthScore,
          score
        };
      }
    })
    .sort(alphaNumericSorter);
};

// Prepare data for the audit sheet
const prepareAuditSheetData = (events, instruments, eventTypeMapping) => {
  const instrumentMap = new Map(instruments.map((item) => [item.id, item]));
  const eventTypeMap = new Map(eventTypeMapping.map((item) => [item.id, item.name]));

  return events
    .map((event) => {
      const dataInstrument = instrumentMap.get(event.instrumentName);
      return {
        eventId: event.eventNumber,
        instrumentName: dataInstrument?.name,
        parameter: dataInstrument?.parameter,
        manufacturer: dataInstrument?.manufacturer,
        eventType: eventTypeMap.get(event.eventType),
        dateDue: event?.dateDue?.seconds ? new Date(event.dateDue.seconds * 1000) : null,
        eventCreationDate: event?.createdAt?.seconds
          ? new Date(event.createdAt.seconds * 1000)
          : null,
        eventCompletionDate: event?.completeDate?.seconds
          ? new Date(event.completeDate.seconds * 1000)
          : null,
        description: event.description || ''
      };
    })
    .sort((a, b) => a.eventId - b.eventId);
};

// Prepare data for abandoned instruments
const prepareAbandonedInstrumentsSheetData = (instruments) =>
  instruments
    .filter((instrument) => !instrument.nextEventDate)
    .map((instrument) => ({
      location: instrument.location,
      name: instrument.name,
      nickname: instrument.nickname,
      status: instrument.status,
      type: instrument.type,
      parameter: instrument.parameter,
      responsibleParty: instrument.responsibleParty
    }))
    .sort(alphaNumericSorter);

const applyColumnFormats = (worksheet, length, columnsFormat, columnWidths) => {
  if (columnsFormat)
    Object.keys(columnsFormat).forEach((col) => {
      for (let row = 1; row <= length; row++) {
        const cellRef = `${col}${row}`;
        if (worksheet[cellRef]) {
          worksheet[cellRef].z = columnsFormat[col];
          worksheet[cellRef].s = { alignment: { wrapText: true } };
        }
      }
    });
  worksheet['!cols'] = columnWidths.map((width) => ({ wch: width }));
};

const createExcelTable = (worksheet, range, tableName, tableStyle) => {
  if (!worksheet['!ref']) return;
  worksheet['!autofilter'] = { ref: range };
  worksheet['!table'] = [
    {
      name: tableName,
      ref: range,
      style: {
        theme: tableStyle,
        showRowStripes: true
      }
    }
  ];
};

/**
 * Defines sheet configurations including headers, formats, and column widths.
 */
const getSheetsConfig = () => ({
  Cost: {
    header: [
      'Location',
      'Instrument Name',
      'Instrument Type',
      'Parameter',
      '% of useful life left',
      'Number of Events',
      'Number of Instrument Issue Reports',
      'Cost of Instrument',
      'Total Cost of Events',
      '% of time frame listed as out of service'
    ],
    formats: { E: '0.00%', F: '0', G: '0', H: '$#,##0.00', I: '$#,##0.00', J: '0.00%' },
    columnWidths: [30, 20, 15, 15, 20, 20, 20, 20, 20, 35]
  },
  Audit: {
    header: [
      'Event ID',
      'Instrument Name',
      'Parameter',
      'Manufacturer',
      'Event Type',
      'Due date',
      'Event Creation Date',
      'Event Completion Date',
      'Event Description'
    ],
    formats: { C: 'MM/DD/YYYY', D: 'MM/DD/YYYY' },
    columnWidths: [15, 25, 20, 20, 20, 20, 20, 25, 30]
  },
  'No Next Event': {
    header: [
      'Instrument Location',
      'Instrument Name',
      'Instrument Nickname',
      'Instrument Status',
      'Instrument Type',
      'Parameter',
      'Responsible Party'
    ],
    formats: null,
    columnWidths: [30, 20, 20, 20, 20, 20, 20]
  },
  Scorecard: {
    header: [
      'Location',
      'Instrument Name',
      'Instrument Type',
      'Parameter',
      '% of useful life left',
      'Number of Events',
      'Number of Instrument Issue Reports',
      'Cost of Instrument',
      'Total Cost of Events',
      '% of time frame listed as out of service',
      'Connected Instrument',
      'Instrument Priority',
      'The instrument health score',
      'Score'
    ],
    formats: { E: '0.00%', F: '0', G: '0', H: '$#,##0.00', I: '$#,##0.00', J: '0.00%' },
    columnWidths: [30, 20, 15, 15, 20, 20, 20, 20, 20, 15, 20, 15, 25, 10]
  }
});

/**
 * Prepares sheet data.
 */
const prepareSheetDataMap = (
  instruments,
  instrumentStatusLogs,
  eventMap,
  events,
  instrumentSettingsEventType
) => ({
  Cost: prepareSheetData(instruments, instrumentStatusLogs, eventMap, 'Cost'),
  Audit: prepareAuditSheetData(events, instruments, instrumentSettingsEventType),
  'No Next Event': prepareAbandonedInstrumentsSheetData(instruments),
  Scorecard: prepareSheetData(instruments, instrumentStatusLogs, eventMap, 'Scorecard')
});

/**
 * Generates Excel worksheets from data.
 */
const generateExcelSheets = (sheetDataMap, sheetsConfig) => {
  const sheets = {};
  Object.entries(sheetDataMap).forEach(([name, data]) => {
    const sheetConfig = sheetsConfig[name];
    const sheetData = [sheetConfig.header, ...data.map(Object.values)];
    sheets[name] = XLSX.utils.aoa_to_sheet(sheetData);
  });
  return sheets;
};

/**
 * Applies column formatting and creates tables in sheets.
 */
const applySheetFormatting = (sheets, sheetDataMap, sheetsConfig) => {
  Object.entries(sheetDataMap).forEach(([name, data]) => {
    const sheetConfig = sheetsConfig[name];
    applyColumnFormats(
      sheets[name],
      data.length + 1,
      sheetConfig.formats,
      sheetConfig.columnWidths
    );
    createExcelTable(
      sheets[name],
      `A1:${String.fromCharCode(64 + sheetConfig.header.length)}${data.length + 1}`,
      name,
      5
    );
  });
};

/**
 * Creates and downloads the Excel workbook.
 */
const createAndDownloadWorkbook = (workbook) => {
  const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([wbout], { type: 'application/octet-stream' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = 'Instrument_Manager_Report.xlsx';
  link.click();
};

/**
 * Generates and downloads an Excel report with multiple sheets
 */
export const generateInstrumentReport = async (
  instruments,
  instrumentStatusLogs,
  events,
  instrumentSettingsEventType
) => {
  try {
    const eventMap = createInstrumentEventMap(events, instrumentSettingsEventType);
    const sheetsConfig = getSheetsConfig();

    // Prepare data for all sheets
    const sheetDataMap = prepareSheetDataMap(
      instruments,
      instrumentStatusLogs,
      eventMap,
      events,
      instrumentSettingsEventType
    );

    // Generate Excel sheets
    const workbook = XLSX.utils.book_new();
    const sheets = generateExcelSheets(sheetDataMap, sheetsConfig);

    // Apply formatting and add sheets to workbook
    applySheetFormatting(sheets, sheetDataMap, sheetsConfig);
    Object.entries(sheets).forEach(([name, sheet]) => {
      XLSX.utils.book_append_sheet(workbook, sheet, name);
    });

    // Create and download workbook
    createAndDownloadWorkbook(workbook);
  } catch (error) {
    throw new Error(error);
  }
};

export const isValidTimestamp = (timestamp) => {
  return timestamp && typeof timestamp.seconds === 'number';
};
