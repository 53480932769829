import React from 'react';
import PropTypes from 'prop-types';
import {
  hashQueryKey,
  QueryClient,
  QueryClientProvider as QueryClientProviderBase,
  useQuery
} from 'react-query';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  documentId,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
  runTransaction,
  Timestamp,
  writeBatch
} from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';

import firebaseApp from './firebase';
import { getConfiguration } from '../util/config';
import { getStorageJSONAsync } from './storage';
import locationFacility from '../assets/facility_location.json';
import { DEFAULT_PERMISSION } from './Constant';
import moment from 'moment';
import { firebaseClean } from './util';

// Initialize Firestore
const db = getFirestore(firebaseApp);

// React Query client
const client = new QueryClient();

/* HELPERS */

// Store Firestore unsubscribe functions
const unsubs = {};

// Format Firestore response
function format(response) {
  // Converts doc into object that contains data and `doc.id`
  const formatDoc = (d) => {
    const docData = d.data();
    delete docData.id; // if an id field was saved on the doc, remove it
    return { id: d.id, ...docData };
  };

  if (response.docs) {
    // Handle a collection of docs
    return response.docs.map(formatDoc);
  }
  // Handle a single doc
  return response.exists() ? formatDoc(response) : null;
}

function createQuery(getRef) {
  // Create a query function to pass to `useQuery`
  return async ({ queryKey }) => {
    let unsubscribe;
    let firstRun = true;
    // Wrap `onSnapshot` with a promise so that we can return initial data
    const data = await new Promise((resolve, reject) => {
      unsubscribe = onSnapshot(
        getRef(),
        // Success handler resolves the promise on the first run.
        // For subsequent runs we manually update the React Query cache.
        (response) => {
          const dataResponse = format(response);
          if (firstRun) {
            firstRun = false;
            resolve(dataResponse);
          } else {
            client.setQueryData(queryKey, dataResponse);
          }
        },
        // Error handler rejects the promise on the first run.
        // We can't manually trigger an error in React Query, so on a subsequent runs we
        // invalidate the query so that it re-fetches and rejects if error persists.
        (error) => {
          if (firstRun) {
            firstRun = false;
            reject(error);
          } else {
            client.invalidateQueries(queryKey);
          }
        }
      );
    });

    // Unsubscribe from an existing subscription for this `queryKey` if one exists
    // Then store `unsubscribe` function so it can be called later
    const queryHash = hashQueryKey(queryKey);
    if (unsubs[queryHash]) {
      unsubs[queryHash]();
    }

    unsubs[queryHash] = unsubscribe;

    return data;
  };
}

// ** clean values **
const cleanName = (name) => name.replace(/[.#$/[\]]/g, '');

/** ** USERS *** */

// Subscribe to user data
// Note: This is called automatically in `auth.js` and data is merged into `auth.user`
export function useUser(uid) {
  // Manage data fetching with React Query: https://react-query.tanstack.com/overview
  return useQuery(
    // Unique query key: https://react-query.tanstack.com/guides/query-keys
    ['user', { uid }],
    // Query function that subscribes to data and auto-updates the query cache
    createQuery(() => doc(db, 'users', uid)),
    // Only call query function if we have a `uid`
    { enabled: !!uid }
  );
}

// Create a new user
export function createUser(uid, data) {
  return setDoc(doc(db, 'users', uid), data, { merge: true });
}

// Update an existing user
export function updateUser(uid, data) {
  return updateDoc(doc(db, 'users', uid), data);
}

/* ITEMS */
/* Example query functions (modify to your needs) */

// Subscribe to all Companies
export function useGetCompanies(variation = '') {
  return useQuery(
    [`${variation}companies`],
    createQuery(() =>
      query(collection(db, `${variation}config`), where('recType', '==', 'companies'))
    )
  );
}

// Subscribe to all Divisions
export function useGetDivisions(variation = '') {
  return useQuery(
    [`${variation}divisions`],
    createQuery(() =>
      query(collection(db, `${variation}config`), where('recType', '==', 'divisions'))
    )
  );
}

// Subscribe to all Units
export function useGetUnits(variation = '') {
  return useQuery(
    [`${variation}units`],
    createQuery(() => query(collection(db, `${variation}config`), where('recType', '==', 'units')))
  );
}

// Subscribe to all Regions
export function useGetRegions(variation = '') {
  return useQuery(
    [`${variation}regions`],
    createQuery(() =>
      query(collection(db, `${variation}config`), where('recType', '==', 'regions'))
    )
  );
}

export function useGetRvpCheckData() {
  return useQuery(
    ['rvpcheck'],
    createQuery(() => query(collection(db, 'rvpcheck')))
  );
}

// Subscribe to CompanyType
export function useGetCompanyType(variation = '') {
  return useQuery(
    [`${variation}companyType`],
    createQuery(() =>
      query(collection(db, `${variation}config`), where('recType', '==', 'companyType'))
    )
  );
}

// Subscribe to CompanyType
export function useGetOrderOfParameters(variation = '') {
  return useQuery(
    [`${variation}orderOfParameters`],
    createQuery(() =>
      query(collection(db, `${variation}config`), where('recType', '==', 'orderOfParameters'))
    )
  );
}

// Subscribe to all Laboratories
export function useGetLaboratories(variation = '') {
  return useQuery(
    [`${variation}laboratories`],
    createQuery(() =>
      query(collection(db, `${variation}config`), where('recType', '==', 'laboratories'))
    )
  );
}

// Subscribe to all Departments
export function useGetDepartments(variation = '') {
  return useQuery(
    [`${variation}departments`],
    createQuery(() =>
      query(collection(db, `${variation}config`), where('recType', '==', 'departments'))
    )
  );
}

// Subscribe to all Departments
export function useGetOrganizations(variation = '') {
  return useQuery(
    [`${variation}organizations`],
    createQuery(() =>
      query(collection(db, `${variation}config`), where('recType', '==', 'organizations'))
    )
  );
}

// Subscribe to all Areas
export function useGetAreas(variation = '') {
  return useQuery(
    [`${variation}areas`],
    createQuery(() => query(collection(db, `${variation}config`), where('recType', '==', 'areas')))
  );
}

// Create scheduled samples
export function createScheduledSamples(data, variation = '') {
  return addDoc(collection(db, `${variation}config`), {
    ...data,
    recType: 'scheduled_samples'
  });
}

// Subscribe to all scheduled samples
export function useGetScheduledSamples(locations = [], variation = '') {
  const locationNames = locations?.map((loc) => loc?.name).filter(Boolean) || [];
  return useQuery(
    [`${variation}scheduled_samples`, locationNames],
    createQuery(() => {
      return query(
        collection(db, `${variation}config`),
        where('recType', '==', 'scheduled_samples'),
        where('location', 'in', locationNames)
      );
    }),
    { enabled: locationNames.length > 0 }
  );
}

// Subscribe to all scheduled samples
export function useGetSampleSchedules(variation = '') {
  return useQuery(
    [`${variation}sample_schedules`],
    createQuery(() =>
      query(collection(db, `${variation}config`), where('recType', '==', 'sample_schedules'))
    )
  );
}

// Subscribe to all Instruments
export function useGetInstruments(variation = '') {
  return useQuery(
    [`${variation}instruments`],
    createQuery(() =>
      query(collection(db, `${variation}config`), where('recType', '==', 'instruments'))
    )
  );
}

// Subscribe to all Personnel
export function useGetPersonnel(variation = '') {
  return useQuery(
    [`${variation}personnel`],
    createQuery(() =>
      query(collection(db, `${variation}config`), where('recType', '==', 'personnel'))
    )
  );
}

// Subscribe to all Setup Groups
export function useGetSetupGroups(variation = '') {
  return useQuery(
    [`${variation}setup_groups`],
    createQuery(() =>
      query(collection(db, `${variation}config`), where('recType', '==', 'setup_groups'))
    )
  );
}

async function useQueryToGetIndexValues(variation = '') {
  const indexes = {
    data: []
  };
  const collectionRef = collection(db, `${variation}index`);
  const q = query(collectionRef);
  const querySnapshot = await getDocs(q);
  if (!querySnapshot.empty) {
    for (const doc of querySnapshot.docs) {
      const { id } = doc;
      indexes.data.push({
        id,
        ...doc.data()
      });
    }
  }
  return indexes;
}

// Subscribe to all Index Values
export async function useGetIndexValues(variation = '') {
  const config = getConfiguration(window.location.hostname);
  const { useStorageForIndex, useStorageForSPCIndex } = config;
  if (!useStorageForIndex && variation === '') {
    return useQueryToGetIndexValues(variation);
  }
  if (!useStorageForSPCIndex && variation === 'spc') {
    return useQueryToGetIndexValues(variation);
  }

  // looking first in storage, and then firestore second
  try {
    const indexes = {
      data: []
    };
    indexes.data = await Promise.all([
      getStorageJSONAsync(`${variation}index/mainArchiveIndex-location.json`),
      getStorageJSONAsync(`${variation}index/mainIndex-location.json`)
    ]);

    if (indexes.data.length === 2 && indexes.data[0].recType && indexes.data[1].recType) {
      indexes.data[0].id = 'mainArchiveIndex-location';
      indexes.data[1].id = 'mainIndex-location';
      return indexes;
    }

    return useQueryToGetIndexValues(variation);
  } catch (e) {
    console.log(`error: ${e}`);
    return useQueryToGetIndexValues(variation);
  }
}

// Subscribe to all Summary Values
export function useGetSummaries(variation = '') {
  return useQuery(
    [`${variation}summary`],
    createQuery(() => query(collection(db, `${variation}summary`)))
  );
}

// Get summary document for fullKey
export function useGetSummaryByFullKey(fullKey) {
  const dataToReturn = useQuery(
    ['summary', { fullKey }],
    createQuery(() =>
      query(collection(db, 'summary'), where(documentId(), 'in', cleanName(fullKey)))
    )
  );

  return dataToReturn;
}

// Get report documents for fullKey
export function useGetReportsByFullKey(fullKey, variation = '') {
  return useQuery(
    [`${variation}reports`, { fullKey }],
    createQuery(() =>
      query(
        collection(db, `${variation}config`),
        where('fullKey', '==', fullKey),
        where('recType', '==', 'reports')
      )
    )
  );
}

// Create a new Report
export function createReport(data, variation = '') {
  return addDoc(collection(db, `${variation}config`), {
    ...data,
    createdAt: serverTimestamp(),
    recType: 'reports'
  });
}

// Subscribe to all Methods
export function useGetMethods(variation = '') {
  return useQuery(
    [`${variation}methods`],
    createQuery(() =>
      query(collection(db, `${variation}config`), where('recType', '==', 'methods'))
    )
  );
}

// Subscribe to all Types
export function useGetTypes(variation = '') {
  return useQuery(
    [`${variation}types`],
    createQuery(() => query(collection(db, `${variation}config`), where('recType', '==', 'types')))
  );
}

// Subscribe to all Products
export function useGetProducts(variation = '') {
  return useQuery(
    [`${variation}products`],
    createQuery(() =>
      query(collection(db, `${variation}config`), where('recType', '==', 'products'))
    )
  );
}

// Subscribe to all Methods
export function useGetDataEntry(variation = '') {
  return useQuery(
    [`${variation}data_entry_demo`],
    createQuery(() =>
      query(collection(db, `${variation}config`), where('recType', '==', 'data_entry_demo'))
    )
  );
}

// Subscribe to all Results
export function useGetResults(variation = '') {
  return useQuery(
    [`${variation}data`],
    createQuery(() => query(collection(db, `${variation}data`), limit(100)))
  );
}

// Subscribe to all Samples
export function useGetSamples(variation = '') {
  return useQuery(
    [`${variation}samples`],
    createQuery(() =>
      query(collection(db, `${variation}config`), where('recType', '==', 'samples'))
    )
  );
}

// Subscribe to all Data
export function useGetAllData() {
  return useQuery(
    ['data'],
    createQuery(() => query(collection(db, 'data')))
  );
}

// Subscribe to all Assessments
export function useGetAllAssessments() {
  return useQuery(
    ['assessments'],
    createQuery(() => query(collection(db, 'assessments')))
  );
}

// Subscribe to all Sample Properties
export function useGetSampleProperties(variation = '') {
  return useQuery(
    [`${variation}sample_properties`],
    createQuery(() =>
      query(collection(db, `${variation}config`), where('recType', '==', 'sample_properties'))
    )
  );
}

// Subscribe to all entities by shortKey
export function useEntitiesByShortKey(shortKey, variation = '') {
  return useQuery(
    [`${variation}data`, { shortKey }],
    createQuery(() => query(collection(db, `${variation}data`), where('shortKey', '==', shortKey))),
    { enabled: !!shortKey }
  );
}

// Subscribe to all entities by fullKey
export function useEntitiesByFullKey(fullKey, variation = '') {
  return useQuery(
    [`${variation}data`, { fullKey }],
    createQuery(() => query(collection(db, `${variation}data`), where('fullKey', '==', fullKey))),
    { enabled: !!fullKey }
  );
}

export async function getDataByFullKey(fullKey, variation = '') {
  if (!fullKey) return [];

  try {
    const q = query(collection(db, `${variation}data`), where('fullKey', '==', fullKey));
    const querySnapshot = await getDocs(q);

    return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error('Error fetching data by fullkey:', error);
    return null;
  }
}

// Subscribe to all entities by fullKey
export function useEntitiesByLocationMethodAndSampleName(
  location,
  method,
  sampleName,
  variation = ''
) {
  return useQuery(
    [`${variation}lmsn`, { location, method, sampleName }],
    createQuery(() =>
      query(
        collection(db, `${variation}data`),
        where('location', '==', location),
        where('method', '==', method),
        where('sampleName', '==', sampleName)
      )
    ),
    { enabled: !!location && !!method && !!sampleName }
  );
}

export async function checkDefaultSetupDataForScheduledData(fullKey, variation = '') {
  if (!fullKey) return false;
  const shortKey = fullKey.split('|').slice(0, -1).join('|');

  try {
    // Fetch Data
    const dataQuery = query(collection(db, `${variation}data`), where('fullKey', '==', fullKey));
    const dataSnapshot = await getDocs(dataQuery);
    const dataCount = dataSnapshot.docs
      .map((doc) => doc.data())
      .filter((item) => item.setupData === '1' || item.setupData === 1).length;

    // Fetch Config
    const configQuery = query(
      collection(db, `${variation}config`),
      where('shortKey', '==', shortKey),
      where('recType', '==', 'configurations'),
      where('type', '==', 'Chart Group')
    );
    const configSnapshot = await getDocs(configQuery);
    const config = configSnapshot.docs[0]?.data();

    const initialAssessment = Number(config?.initialAssessment || 20);

    return dataCount <= initialAssessment;
  } catch (error) {
    console.error('Error fetching data and config:', error);
    return false;
  }
}

// Function to reset `everFlags` field for matching documents
const BATCH_SIZE = 500;

export async function resetEverFlags(fullKey, everFlagsReset) {
  try {
    console.log('Starting resetEverFlags for:', fullKey);

    const q = query(collection(db, 'data'), where('fullKey', '==', fullKey));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const docs = querySnapshot.docs;
      const idToFlagsMap = Object.fromEntries(
        everFlagsReset.map(({ id, flags }, index) => [id, { flags, version: 1 }])
      );

      for (let i = 0; i < docs.length; i += BATCH_SIZE) {
        const batch = writeBatch(db);
        const batchDocs = docs.slice(i, i + BATCH_SIZE);

        batchDocs.forEach((doc) => {
          const docId = doc.id;
          const flagData = idToFlagsMap[docId];

          if (flagData) {
            const updatedEverFlags = [];

            updatedEverFlags.push({ flags: flagData.flags, version: flagData.version });
            console.log(`Adding flag to doc ${docId}:`, {
              flags: flagData.flags,
              version: flagData.version
            });

            batch.update(doc.ref, { everFlags: updatedEverFlags });
          } else {
            batch.update(doc.ref, { everFlags: [] });
          }
        });

        await batch.commit();
        console.log(`Batch ${Math.floor(i / BATCH_SIZE) + 1} processed successfully.`);
      }
    } else {
      console.log('No matching documents found.');
    }
  } catch (error) {
    console.error('Error updating documents in batches:', error);
  }
}
export function useFindAssessmentsByShortKey(shortKey, variation = '') {
  return useQuery(
    [`${variation}assessments`, { shortKey }],
    createQuery(() =>
      query(collection(db, `${variation}assessments`), where('shortKey', '==', shortKey))
    ),
    { enabled: !!shortKey }
  );
}

export function useGetSilencedDataByShortKey(shortKey, variation = '') {
  return useQuery(
    [`${variation}silenced`, { shortKey }],
    createQuery(() =>
      query(collection(db, `${variation}silenced`), where('shortKey', '==', shortKey))
    ),
    { enabled: !!shortKey }
  );
}

export function useFindNotificationsByLocation(location, variation = '') {
  return useQuery(
    [`${variation}notifications`, { location }],
    createQuery(() =>
      query(
        collection(db, `${variation}config`),
        where('location', '==', location),
        where('recType', '==', 'notifications')
      )
    ),
    { enabled: !!location }
  );
}

export function useFindDataByMultipleParameters(method, sampleName, parameter, variation = '') {
  return useQuery(
    [
      `${variation}data`,
      {
        method,
        sampleName,
        parameter
      }
    ],
    createQuery(() =>
      query(
        collection(db, `${variation}data`),
        where('method', '==', method),
        where('sampleName', '==', sampleName),
        where('parameter', '==', parameter)
      )
    ),
    { enabled: !!method }
  );
}

export function useFindControlStrategyConfigurationByFullKey(fullKey, variation = '') {
  return useQuery(
    [`${variation}configurations`, { fullKey }],
    createQuery(() =>
      query(
        collection(db, `${variation}config`),
        where('fullKey', '==', fullKey),
        where('recType', '==', 'configurations'),
        where('type', '==', 'Control Strategy')
      )
    ),
    { enabled: !!fullKey, refetchOnWindowFocus: false }
  );
}

export async function getControlStrategyConfigurationByFullKey(fullKey, variation = '') {
  if (!fullKey) return [];

  try {
    const q = query(
      collection(db, `${variation}config`),
      where('fullKey', '==', fullKey),
      where('recType', '==', 'configurations'),
      where('type', '==', 'Control Strategy')
    );
    const querySnapshot = await getDocs(q);

    return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error(`Error fetching ${variation}config`, error);
    return null;
  }
}

export function useFindChartGroupConfigurationByShortKey(shortKey, variation = '') {
  return useQuery(
    [`${variation}configurations`, { shortKey }],
    createQuery(() =>
      query(
        collection(db, `${variation}config`),
        where('shortKey', '==', shortKey),
        where('recType', '==', 'configurations'),
        where('type', '==', 'Chart Group')
      )
    ),
    { enabled: !!shortKey }
  );
}

// Create a new Assessment
export function createAssessment(data) {
  return addDoc(collection(db, 'assessments'), {
    ...data,
    createdAt: serverTimestamp()
  });
}

// Get summary document for ShortKey
export async function getInitAssessmentByShortKey(shortKey) {
  try {
    const q = query(
      collection(db, 'assessments'),
      where('shortKey', '==', shortKey),
      where('type', '==', 'Initial')
    );

    const querySnapshot = await getDocs(q);

    // Return the first document or null if no documents are found
    if (!querySnapshot.empty) {
      const doc = querySnapshot.docs[0];
      return { id: doc.id, ...doc.data() };
    }

    return null; // No matching document found
  } catch (error) {
    console.error('Error fetching assessment:', error);
    throw error; // Re-throw the error to handle it at the call site
  }
}

export async function createAssessmentByFullKey(shortKey, fullKey, data) {
  try {
    const assessmentsRef = collection(db, 'assessments');

    // Query to check if a document with the fullKey already exists
    const q = query(
      assessmentsRef,
      where('shortKey', '==', shortKey),
      where('fullKey', '==', fullKey),
      where('type', '==', 'Initial')
    );

    const querySnapshot = await getDocs(q);

    // If a document matching the query exists, return early
    if (!querySnapshot.empty) {
      const data = querySnapshot.docs[0].data();
      if (data?.archive === false || !data.archive) {
        return;
      }
    }

    // Create a new document
    const docRef = await addDoc(assessmentsRef, {
      ...data,
      fullKey,
      shortKey,
      createdAt: serverTimestamp()
    });

    return docRef; // Return the reference of the created document
  } catch (error) {
    console.error('Error creating assessment:', error);
    throw error; // Re-throw the error for further handling if needed
  }
}

// Update an existing Assessment
export function updateAssessment(id, data) {
  return updateDoc(doc(db, 'assessments', id), data);
}

// Update an existing data point
export function updateData(id, dataIn, variation = '') {
  const data = dataIn;
  if (data.id) {
    delete data.id;
  }
  return updateDoc(doc(db, `${variation}data`, id), data);
}

// Update an existing Summary
export function updateSummary(id, data, variation = '') {
  return updateDoc(doc(db, `${variation}summary`, cleanName(id)), data);
}

// Create a new method
export function createMethod(data, variation = '') {
  return addDoc(collection(db, `${variation}config`), {
    ...data,
    recType: 'methods',
    createdAt: serverTimestamp()
  });
}

export function createSampleSchedule(data, variation = '') {
  return addDoc(collection(db, `${variation}config`), {
    ...data,
    recType: 'sample_schedules',
    createdAt: serverTimestamp()
  });
}

// Create a new personnel
export function createPersonnel(data, variation = '') {
  return addDoc(collection(db, `${variation}config`), {
    ...data,
    recType: 'personnel',
    createdAt: serverTimestamp()
  });
}

// Create a new company
export function createCompany(data, variation = '') {
  return addDoc(collection(db, `${variation}config`), {
    ...data,
    recType: 'companies',
    createdAt: serverTimestamp()
  });
}

// Create a new setup group
export function createSetupGroup(data, variation = '') {
  return addDoc(collection(db, `${variation}config`), {
    ...data,
    recType: 'setup_groups',
    createdAt: serverTimestamp()
  });
}

// Create a new instrument
export function createInstrument(data, variation = '') {
  return addDoc(collection(db, `${variation}config`), {
    ...data,
    recType: 'instruments',
    createdAt: serverTimestamp()
  });
}

// Create a new organization
export function createOrganization(data, variation = '') {
  return addDoc(collection(db, `${variation}config`), {
    ...data,
    recType: 'organizations',
    createdAt: serverTimestamp()
  });
}

// Create a new silenced data point
export function createSilencedData(data, variation = '') {
  return addDoc(collection(db, `${variation}silenced`), {
    ...data,
    createdAt: serverTimestamp()
  });
}

// Create a data entry
export function createDataEntry(data, variation = '') {
  return addDoc(collection(db, `${variation}config`), {
    ...data,
    recType: 'data_entry_demo',
    createdAt: serverTimestamp()
  });
}

// Create a new division
export function createDivision(data, variation = '') {
  return addDoc(collection(db, `${variation}config`), {
    ...data,
    recType: 'divisions',
    createdAt: serverTimestamp()
  });
}

// Create a new unit
export function createUnit(data, variation = '') {
  return addDoc(collection(db, `${variation}config`), {
    ...data,
    recType: 'units',
    createdAt: serverTimestamp()
  });
}

// Create a new region
export function createRegion(data, variation = '') {
  return addDoc(collection(db, `${variation}config`), {
    ...data,
    recType: 'regions',
    createdAt: serverTimestamp()
  });
}

// Create a new Company Type
export async function setCompanyType(data, variation = '') {
  if (!data.id) {
    const ref = await addDoc(collection(db, `${variation}config`), data);
    data.id = ref.id;
  }
  return setDoc(doc(collection(db, `${variation}config`), data.id), {
    ...data,
    recType: 'companyType',
    createdAt: serverTimestamp()
  });
}

// Create a new Order of Parameters
export async function setOrderOfParameters(data, variation = '') {
  if (!data.id) {
    const ref = await addDoc(collection(db, `${variation}config`), data);
    data.id = ref.id;
  }
  return setDoc(doc(collection(db, `${variation}config`), data.id), {
    ...data,
    recType: 'orderOfParameters',
    createdAt: serverTimestamp()
  });
}

// Create a new area
export function createArea(data, variation = '') {
  return addDoc(collection(db, `${variation}config`), {
    ...data,
    recType: 'areas',
    createdAt: serverTimestamp()
  });
}

// Create a new laboratory
export function createLaboratory(data, variation = '') {
  return addDoc(collection(db, `${variation}config`), {
    ...data,
    recType: 'laboratories',
    createdAt: serverTimestamp()
  });
}

// Create a new department
export function createDepartment(data, variation = '') {
  return addDoc(collection(db, `${variation}config`), {
    ...data,
    recType: 'departments',
    createdAt: serverTimestamp()
  });
}

// Create a new sample
export function createSample(data, variation = '') {
  return addDoc(collection(db, `${variation}config`), {
    ...data,
    recType: 'samples',
    createdAt: serverTimestamp()
  });
}

// Create a new sample property
export function createSampleProperty(data, variation = '') {
  return addDoc(collection(db, `${variation}config`), {
    ...data,
    recType: 'sample_properties',
    createdAt: serverTimestamp()
  });
}

// Create a new configuration
export function createConfiguration(data, variation = '') {
  return addDoc(collection(db, `${variation}config`), {
    ...data,
    recType: 'configurations',
    createdAt: serverTimestamp()
  });
}

// Create a new notification
export function createNotification(data, variation = '') {
  return addDoc(collection(db, `${variation}config`), {
    ...data,
    recType: 'notifications',
    createdAt: serverTimestamp()
  });
}

// Update a notification
export function updateNotification(id, data, variation = '') {
  return updateDoc(doc(db, `${variation}config`, id), data);
}

// Update an item
export function updateSample(id, data, variation = '') {
  return updateDoc(doc(db, `${variation}config`, id), data);
}

// Update an item
export function updateSampleProperty(id, data, variation = '') {
  return updateDoc(doc(db, `${variation}config`, id), data);
}

// Update an item
export function updateInstrument(id, data, variation = '') {
  return updateDoc(doc(db, `${variation}config`, id), data);
}

// Update a sample schedule
export function updateSampleSchedule(data, variation = '') {
  return updateDoc(doc(db, `${variation}config`, data.id), data);
}

// Update a Personnel
export function updatePersonnel(id, data, variation = '') {
  return updateDoc(doc(db, `${variation}config`, id), data);
}

// Update a setup group
export function updateSetupGroup(id, data, variation = '') {
  return updateDoc(doc(db, `${variation}config`, id), data);
}

// Update a company
export function updateCompany(id, data, variation = '') {
  return updateDoc(doc(db, `${variation}config`, id), data);
}

// Update an item
export function updateItem(id, data, variation = '') {
  return updateDoc(doc(db, `${variation}config`, id), data);
}

// Update a method
export function updateMethod(id, data, variation = '') {
  return updateDoc(doc(db, `${variation}config`, id), data);
}

// Update a configuration
export function updateConfiguration(id, data, variation = '') {
  if (!id) {
    console.log(`adding document`);
    return addDoc(collection(db, `${variation}config`), data);
  }
  return updateDoc(doc(db, `${variation}config`, id), data);
}

// Delete a method
export function deleteMethod(id, variation = '') {
  return deleteDoc(doc(db, `${variation}config`, id));
}

// Delete a sample shedule
export function deleteSampleSchedule(id, variation = '') {
  return deleteDoc(doc(db, `${variation}config`, id));
}

// Delete a scheduled sample
export function deleteScheduledSample(id, variation = '') {
  return deleteDoc(doc(db, `${variation}config`, id));
}

// Delete a sample
export function deleteSample(id, variation = '') {
  return deleteDoc(doc(db, `${variation}config`, id));
}

// Delete an instrument
export function deleteInstrument(id, variation = '') {
  return deleteDoc(doc(db, `${variation}config`, id));
}

// Delete an organization
export function deleteOrganization(id, variation = '') {
  return deleteDoc(doc(db, `${variation}config`, id));
}

// Delete a division
export function deleteDivision(id, variation = '') {
  return deleteDoc(doc(db, `${variation}config`, id));
}

// Delete a data point
export function deleteData(id) {
  return deleteDoc(doc(db, 'data', id));
}

// Delete an assessment
export function deleteAssessment(id) {
  return deleteDoc(doc(db, 'assessments', id));
}

// Delete a unit
export function deleteUnit(id, variation = '') {
  return deleteDoc(doc(db, `${variation}config`, id));
}

// Delete a region
export function deleteRegion(id, variation = '') {
  return deleteDoc(doc(db, `${variation}config`, id));
}

// Delete an area
export function deleteArea(id, variation = '') {
  return deleteDoc(doc(db, `${variation}config`, id));
}

// Delete a laboratory
export function deleteLaboratory(id, variation = '') {
  return deleteDoc(doc(db, `${variation}config`, id));
}

// Delete a department
export function deleteDepartment(id, variation = '') {
  return deleteDoc(doc(db, `${variation}config`, id));
}

// Delete a personnel
export function deletePersonnel(id, variation = '') {
  return deleteDoc(doc(db, `${variation}config`, id));
}

// Delete a company
export function deleteCompany(id, variation = '') {
  return deleteDoc(doc(db, `${variation}config`, id));
}

// Delete a setup group
export function deleteSetupGroup(id, variation = '') {
  return deleteDoc(doc(db, `${variation}config`, id));
}

// Delete a sample property
export function deleteSampleProperty(id, variation = '') {
  return deleteDoc(doc(db, `${variation}config`, id));
}

// Create combine z score
export function createCombineZScoreParameter(data) {
  return addDoc(collection(db, 'config'), {
    ...data,
    recType: 'combine_z_score',
    createdAt: serverTimestamp()
  });
}

// Update combine z score
export function updateCombineZScoreParameter(id, data) {
  return updateDoc(doc(db, 'config', id), data);
}

// Read combine z score
export async function readCombineZScoreParameter(shortKey) {
  const collectionRef = collection(db, 'config');
  const q = query(
    collectionRef,
    where('shortKey', '==', shortKey),
    where('recType', '==', 'combine_z_score')
  );

  let value;
  const querySnapshot = await getDocs(q);
  if (!querySnapshot.empty) {
    const doc = querySnapshot.docs[0] || {};
    const { id } = doc;
    value = {
      id,
      ...doc.data()
    };
  }

  return value;
}

// Automatically remove Firestore subscriptions when all observing components have unmounted
client.queryCache.subscribe(({ type, query: q }) => {
  if (type === 'observerRemoved' && q.getObserversCount() === 0 && unsubs[q.queryHash]) {
    // Call stored Firestore unsubscribe function
    unsubs[q.queryHash]();
    delete unsubs[q.queryHash];
  }
});

// React Query context provider that wraps our app
export function QueryClientProvider({ children }) {
  return <QueryClientProviderBase client={client}>{children}</QueryClientProviderBase>;
}

QueryClientProvider.propTypes = {
  children: PropTypes.node.isRequired
};

// ILCP EXTERNAL

export async function getAllPrograms() {
  const collectionRef = collection(db, 'external_ilcp_program');
  const q = query(collectionRef);

  const values = {};

  const querySnapshot = await getDocs(q);
  const uniqueKeyValues = new Set();
  querySnapshot.forEach((d) => {
    const value = d.get('label');
    if (value) {
      uniqueKeyValues.add(value);
      values[value] = [...(values[value] ?? []), d.data()];
    }
  });

  return values;
}

export async function getAllProgramsByLabelAndProgramId(label, programId) {
  const collectionRef = collection(db, 'external_ilcp_program');
  const q = query(
    collectionRef,
    where('label', '==', label),
    where('programId', '==', programId),
    orderBy('round')
  );

  const values = [];

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((d) => {
    const { id } = d;
    const value = {
      id,
      ...d.data()
    };

    value.sitestdev = value.sitestdev || -1 > 0 ? value.sitestdev : 1;
    if (value) values.push(value);
  });

  return [...values];
}

export async function updatePrograms(programsToUpdate) {
  const collectionRef = collection(db, 'external_ilcp_program');

  const updates = programsToUpdate.map((p) => {
    const programRef = doc(collectionRef, p.id);
    const updated = { ...p };
    delete updated.id;
    return updateDoc(programRef, updated);
  });

  return Promise.all(updates);
}

export async function savePrograms(programsToSave) {
  const promises = programsToSave.map((program) =>
    addDoc(collection(db, 'external_ilcp_program'), program)
  );
  await Promise.all(promises);
}

export async function updateIlcpData(toUpdate) {
  const docRef = doc(collection(db, 'external_ilcp_data'), toUpdate.programId);
  await setDoc(docRef, toUpdate);
}

export async function getAllExternalData() {
  const collectionRef = collection(db, 'external_ilcp_data');
  const q = query(collectionRef);
  const array = [];
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((d) => {
    const items = d.data();
    if (items.name) array.push(items);
  });

  return array;
}

export async function saveDataSet(newDataSet) {
  const collectionRef = collection(db, 'external_ilcp_data');

  const programId = uuidv4();

  const ds = {
    ...newDataSet,
    programId
  };

  await setDoc(doc(collectionRef, programId), ds);

  return ds;
}

export async function getStcmpPrograms() {
  const collectionRef = collection(db, 'stdcmp_program');
  const q = query(collectionRef);

  const values = [];

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((d) => {
    const value = d.get('name');
    if (value) {
      values.push(d.data());
    }
  });

  return [...values];
}

export async function getProductDataByMethod(method, programId) {
  const collectionRef = collection(db, 'stdcmp_data');
  const q = query(
    collectionRef,
    where('method', '==', method),
    where('programId', '==', programId),
    orderBy('date', 'desc')
  );

  const values = [];

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((d) => {
    const { id } = d;

    const time = d.get('date');
    const date = new Date(time);

    const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
      .getDate()
      .toString()
      .padStart(2, '0')}-${date.getFullYear()}`;

    const value = {
      id,
      ...d.data(),
      formattedDate
    };
    if (value) values.push(value);
  });

  return [...values];
}

export async function saveStdcmpData(dataToSave) {
  const promises = dataToSave.map(async (dataIn) => {
    const data = dataIn;
    if (!data.sampleID) {
      data.sampleID = uuidv4();
    }
    const docRef = doc(collection(db, 'stdcmp_data'), data.sampleID);
    return setDoc(docRef, data).catch((error) => {
      // eslint-disable-next-line no-console
      console.error('Error writing document: ', error);
    });
  });
  await Promise.all(promises);
}

export async function updateStdcmpPrograms(toUpdate) {
  const docRef = doc(collection(db, 'stdcmp_program'), toUpdate.programId);
  await setDoc(docRef, toUpdate).catch((error) => {
    // eslint-disable-next-line no-console
    console.error('Error writing document: ', error);
  });
}

export async function updateStdcmpData(listUpdate) {
  for (const toUpdate of listUpdate) {
    const dataUpdate = { ...toUpdate };
    delete dataUpdate.formattedDate;
    const docRef = doc(collection(db, 'stdcmp_data'), dataUpdate.id);
    await setDoc(docRef, dataUpdate);
  }
}

export async function addNewStdcmpData(listAddNew) {
  const collectionRef = collection(db, 'stdcmp_data');
  for (const toAddNew of listAddNew) {
    const dataUpdate = { ...toAddNew };
    delete dataUpdate.formattedDate;
    const data = await addDoc(collectionRef, {});
    const docRef = doc(collection(db, 'stdcmp_data'), data.id);
    await setDoc(docRef, { ...dataUpdate, id: data.id });
  }
}

export async function getInternalData() {
  const collectionRef = collection(db, 'internal_ilcp_data');

  const q = query(collectionRef);

  const values = [];

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((d) => {
    const { id } = d;

    const value = { id, ...d.data() };
    if (value) values.push(value);
  });

  return [...values];
}

export async function getInternalRun(id) {
  const docRef = doc(db, 'internal_ilcp_run', id);
  return getDoc(docRef).then((d) => d.data());
}

export async function getInternalRunData() {
  const collectionRef = collection(db, 'internal_ilcp_run');
  const values = {};
  const q = query(collectionRef);
  const querySnapshot = await getDocs(q);

  querySnapshot.forEach((d) => {
    const { id } = d;

    const value = d.data();
    if (value) values[id] = value;
  });

  return { ...values };
}

export async function getInternalMetadata() {
  const collectionRef = collection(db, 'internal_ilcp_metadata');
  const values = {};
  const q = query(collectionRef);
  const querySnapshot = await getDocs(q);

  querySnapshot.forEach((d) => {
    const { id } = d;

    const value = d.data();
    if (value) values[id] = value;
  });

  return { ...values };
}

export async function updateInternalMetadata(toUpdate) {
  const docRef = doc(collection(db, 'internal_ilcp_metadata'), toUpdate.key);
  await setDoc(docRef, toUpdate);
}

export async function updateMultipleInternalMetadata(metaDatas) {
  const collectionRef = collection(db, 'internal_ilcp_metadata');
  const updates = [];
  metaDatas.forEach((metaData) => {
    updates.push(setDoc(doc(collectionRef, metaData.key), metaData));
  });
  await Promise.all(updates);
}

export async function updateRun(toUpdate, key) {
  const docRef = doc(collection(db, 'internal_ilcp_run'), key);
  await setDoc(docRef, toUpdate);
}

export async function getLocations() {
  const collectionRef = collection(db, 'index');
  const q = query(collectionRef, where('aggType', '==', 'location'));

  const values = [];

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((d) => {
    const i = d.get('index');

    i.forEach((d2) => {
      if (d2.name) values.push(d2.name);
    });
  });

  return [...values];
}

export async function saveCalculation(calculation) {
  const collectionRef = collection(db, 'external_ilcp_calculations');
  const id = `${calculation.programId}-${calculation.fuelType}-${calculation.round}`;

  const docRef = doc(collectionRef, id);
  await setDoc(docRef, calculation);
}

export async function getCalculations(programId) {
  const collectionRef = collection(db, 'external_ilcp_calculations');
  const q = query(collectionRef, where('programId', '==', programId));

  const values = [];

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((d) => {
    const { id } = d;
    const value = { id, ...d.data() };
    if (value) values.push(value);
  });

  return [...values];
}

// this function should be call if we update data location in file assets/facility_location.json
export async function initLocationData() {
  const locationMap = new Map(locationFacility.map((element) => [element.facilityCode, element]));

  const collectionRef = collection(db, 'facility_location');

  const q = query(collectionRef);

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((d) => {
    const { id } = d;
    const { facilityCode, locationId, locationName } = d.data();
    const value = locationMap.get(facilityCode);
    if (value) {
      if (value.locationId !== locationId || locationName !== value.locationName) {
        updateDoc(doc(db, 'facility_location', id), { facilityCode, locationId, locationName });
      }
      locationMap.delete(facilityCode);
    } else {
      deleteDoc(doc(db, 'facility_location', id));
    }
  });

  locationMap.forEach((location) => {
    const newLocationRef = doc(collection(db, 'facility_location'));
    setDoc(newLocationRef, location);
  });
}

export async function getLocationData() {
  const collectionRef = collection(db, 'facility_location');
  const result = new Map();
  const q = query(collectionRef);
  const querySnapshot = await getDocs(q);

  querySnapshot.forEach((d) => {
    const { facilityCode, locationId, locationName } = d.data();
    if (facilityCode && locationName) {
      result.set(facilityCode, { locationId, locationName });
    }
  });
  // check list location on local source and db
  if (result.size !== locationFacility.length) {
    await initLocationData();
    result.clear();
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((d) => {
      const { facilityCode, locationId, locationName } = d.data();
      if (facilityCode && locationName) {
        result.set(facilityCode, { locationId, locationName });
      }
    });
  }
  return result;
}

export async function useGetPermisisonData() {
  try {
    const collectionRef = collection(db, 'permission');
    const result = {};
    const q = query(collectionRef);
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((d) => {
      const data = d.data();
      const { id } = d;
      const permissionValue = {};
      Object.keys(data).forEach((key) => {
        permissionValue[key] = data[key];
      });
      permissionValue['permissionType'] = id;
      result[id] = permissionValue;
    });
    return result;
  } catch (err) {
    // return default permission config
    return DEFAULT_PERMISSION;
  }
}

export async function updatePermisison(id, data) {
  try {
    await setDoc(doc(db, 'permission', id), data);
    return { success: true };
  } catch (err) {
    return { success: false, message: `${err.message}` };
  }
}

export async function unArchiveSimple({ id, fullKey, data }) {
  try {
    await Promise.all(
      data.map(async (item) => {
        await setDoc(doc(db, 'data', item.id), { fullKey, archive: false });
      })
    );
    await setDoc(doc(db, 'config', id), { fullKey, archive: 'No' });
    return { success: true };
  } catch (err) {
    return { success: false, message: `${err.message}` };
  }
}

// useInstrumentSettings
function useInstrumentSettingsQuery(type) {
  return useQuery(
    ['inventorySettings', { type }],
    createQuery(() => query(collection(db, 'settings'), where('type', '==', type))),
    { enabled: !!type }
  );
}

export function useInstrumentSettings(type) {
  const results = useInstrumentSettingsQuery(type);
  if (results.data) {
    const newResults = JSON.parse(JSON.stringify(results));
    newResults.data = newResults.data.sort((a, b) => {
      const nameA = String(a?.name || '').toLowerCase();
      const nameB = String(b?.name || '').toLowerCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    return newResults;
  }
  return results;
}

// useEventNumberSettings
export function useEventNumberSettings() {
  const type = 'EVENT_NUMBER';
  return useQuery(
    ['eventNumberSettings', { type }],
    createQuery(() => query(collection(db, 'settings'), where('type', '==', type))),
    { enabled: !!type }
  );
}

// update event number
export async function setInstrumentEventNumber(number) {
  const type = 'EVENT_NUMBER';
  const refConfig = collection(db, 'settings');
  const q = query(refConfig, where('type', '==', type));

  const querySnapshot = await getDocs(q);
  let enDocRef;
  if (!querySnapshot.empty) {
    enDocRef = querySnapshot.docs[0].ref;
    setDoc(enDocRef, { eventNumber: number, type: type });
  } else {
    enDocRef = await addDoc(refConfig, {
      type: type,
      eventNumber: number
    });
  }
}

// Create a new InstrumentSettings
export async function setInstrumentSettings(data) {
  if (!data.id) {
    const ref = await addDoc(collection(db, 'settings'), data);
    data.id = ref.id;
  }
  return setDoc(doc(collection(db, 'settings'), data.id), {
    ...data,
    createdAt: serverTimestamp()
  });
}

// delete InstrumentSettings
export async function deleteInstrumentSettings(data) {
  return deleteDoc(doc(db, 'settings', data.id));
}

// useInstrumentEvents
export function useInstrumentEvents(filter) {
  const { instrumentName, vendorId, parentEventId, responsiblePartyId, status, eventType, date } =
    filter || {};
  if (instrumentName) {
    return useQuery(
      ['inventoryEvents', { ...filter }],
      createQuery(() =>
        query(collection(db, 'events'), where('instrumentName', '==', instrumentName))
      )
    );
  } else if (vendorId) {
    return useQuery(
      ['inventoryEvents', { ...filter }],
      createQuery(() =>
        query(collection(db, 'events'), where('vendors', 'array-contains', vendorId))
      )
    );
  } else if (parentEventId) {
    return useQuery(
      ['inventoryEvents', { ...filter }],
      createQuery(() =>
        query(collection(db, 'events'), where('parentEventId', '==', parentEventId))
      )
    );
  } else if (responsiblePartyId) {
    return useQuery(
      ['inventoryEvents', { ...filter }],
      createQuery(() =>
        query(collection(db, 'events'), where('responsibleParty', '==', responsiblePartyId))
      )
    );
  } else {
    const conditions = [];

    if (date) {
      var filterDate = new Date();
      filterDate.setDate(filterDate.getDate() + parseInt(date));

      const timestampFilterDate = Timestamp.fromDate(filterDate);
      const timestampCurrentDate = Timestamp.fromDate(new Date());

      conditions.push(
        where('dateDue', '<=', date > 0 ? timestampFilterDate : timestampCurrentDate)
      );
      conditions.push(
        where('dateDue', '>=', date > 0 ? timestampCurrentDate : timestampFilterDate)
      );
    }

    if (status) {
      conditions.push(where('status', '==', status));
    }

    if (eventType) {
      conditions.push(where('eventType', '==', eventType));
    }

    return useQuery(
      ['inventoryEvents', { ...filter }],
      createQuery(() => query(collection(db, 'events'), ...conditions))
    );
  }
}

// useInstrumentEvents
export function useInstrumentEventDetails(id) {
  return useQuery(
    ['inventoryEquipmentDetails', { id }],
    createQuery(() => query(collection(db, 'events'), where('id', '==', id)))
  );
}

export async function getEventNumberSettingsRef() {
  const refConfig = collection(db, 'settings');
  const q = query(refConfig, where('type', '==', 'EVENT_NUMBER'));
  const querySnapshot = await getDocs(q);
  let enDocRef;
  if (!querySnapshot.empty) {
    enDocRef = querySnapshot.docs[0].ref;
  } else {
    enDocRef = await addDoc(refConfig, {
      type: 'EVENT_NUMBER',
      sequential: 0,
      dateSection: moment().format('YYMM')
    });
  }

  return enDocRef;
}

export async function getNewEventNumber(enDocRef, transaction) {
  const eventNumberDoc = await transaction.get(enDocRef);
  if (!eventNumberDoc.exists()) {
    throw 'Document does not exist!';
  }
  if (moment().format('YYMM') !== eventNumberDoc.data().dateSection) {
    return {
      dateSection: moment().format('YYMM'),
      sequential: 0
    };
  } else {
    return {
      dateSection: eventNumberDoc.data().dateSection,
      sequential: eventNumberDoc.data().sequential
    };
  }
}

export async function getEventsByCloneGroupId(cloneGroupId) {
  const ref = collection(db, 'events');
  const q = query(ref, where('cloneGroupId', '==', cloneGroupId));

  try {
    const querySnapshot = await getDocs(q);
    const records = [];

    querySnapshot.forEach((doc) => {
      records.push({
        id: doc.id,
        ...doc.data()
      });
    });

    return records;
  } catch (error) {
    console.error('Error fetching records by cloneGroupId:', error);
    return [];
  }
}

// Create a new InstrumentEvents
export async function setInstrumentEvents(data) {
  const ref = collection(db, 'events');
  // clear variable undefine before request
  Object.keys(data).forEach((key) => data[key] === undefined && delete data[key]);
  if (!data?.cloneGroupId) data.cloneGroupId = uuidv4();
  // update event exist.
  if (data.id) {
    await setDoc(doc(ref, data.id), {
      ...data,
      updatedAt: serverTimestamp()
    });
  } else {
    // create new event.
    const enDocRef = await getEventNumberSettingsRef();
    const docRef = await addDoc(ref, { ...data });
    data.id = docRef.id;
    try {
      await runTransaction(db, async (transaction) => {
        const newEventNumber = await getNewEventNumber(enDocRef, transaction);

        newEventNumber.sequential += 1;
        data.eventNumber = `${newEventNumber.dateSection}${newEventNumber.sequential
          .toString()
          .padStart(4, '0')}`;

        await setDoc(docRef, {
          ...data,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp()
        });
        transaction.update(enDocRef, newEventNumber);
      });
    } catch (e) {
      console.log('Transaction failed: ', e);
    }
  }
  return data;
}

// delete InstrumentEvents
export async function deleteInstrumentEvents(data) {
  return deleteDoc(doc(db, 'events', data.id));
}

// useInstrumentEquipments
export function useInstrumentEquipments(filter) {
  const { vendorId, location, status, type, parameter, serialNumber, responsiblePartyId } =
    filter || {};
  if (vendorId) {
    return useQuery(
      ['inventoryEquipments', { ...filter }],
      createQuery(() =>
        query(collection(db, 'equipments'), where('vendors', 'array-contains', vendorId))
      )
    );
  } else if (responsiblePartyId) {
    return useQuery(
      ['inventoryEquipments', { ...filter }],
      createQuery(() =>
        query(collection(db, 'equipments'), where('responsibleParty', '==', responsiblePartyId))
      )
    );
  } else {
    const conditions = [];

    if (location) {
      conditions.push(where('location', '==', location));
    }
    if (status) {
      conditions.push(where('status', '==', status));
    }
    if (type) {
      conditions.push(where('type', '==', type));
    }
    if (parameter) {
      conditions.push(where('parameter', '==', parameter));
    }
    if (serialNumber) {
      conditions.push(where('serialNumber', '==', serialNumber));
    }
    return useQuery(
      ['inventoryEquipments', { ...filter }],
      createQuery(() => query(collection(db, 'equipments'), ...conditions))
    );
  }
}

// useInstrumentEquipments
export function useInstrumentEquipmentDetails(id) {
  return useQuery(
    ['inventoryEquipmentDetails', { id }],
    createQuery(() => query(collection(db, 'equipments'), where('id', '==', id)))
  );
}

// Create a new InstrumentEquipments
export async function setInstrumentEquipments(data) {
  if (!data.id) {
    const ref = await addDoc(collection(db, 'equipments'), data);
    data.id = ref.id;
  }
  return setDoc(doc(collection(db, 'equipments'), data.id), {
    ...data,
    createdAt: serverTimestamp()
  });
}

// delete InstrumentEquipments
export async function deleteInstrumentEquipments(data) {
  return deleteDoc(doc(db, 'equipments', data.id));
}

// useInstrumentVendors
export function useInstrumentVendors(filter) {
  const { companyName, status, id } = filter || {};
  const conditions = [];

  if (companyName) {
    conditions.push(where('companyName', '==', companyName));
  }
  if (status) {
    conditions.push(where('status', '==', status));
  }

  if (id) {
    conditions.push(where('vendorId', '==', id));
  }

  return useQuery(
    ['inventoryVendors', { filter }],
    createQuery(() => query(collection(db, 'vendors'), ...conditions))
  );
}

// useInstrumentVendors
export function useInstrumentVendorDetails(id) {
  return useQuery(
    ['inventoryVendorDetails', { id }],
    createQuery(() => query(collection(db, 'vendors'), where('id', '==', id)))
  );
}

// Create a new InstrumentVendors
export async function setInstrumentVendors(data) {
  if (!data.id) {
    const ref = await addDoc(collection(db, 'vendors'), data);
    data.id = ref.id;
  }
  return setDoc(doc(collection(db, 'vendors'), data.id), {
    ...data,
    createdAt: serverTimestamp()
  });
}

// delete InstrumentVendors
export async function deleteInstrumentVendors(data) {
  return deleteDoc(doc(db, 'vendors', data.id));
}

// useInstrumentDocuments
export function useInstrumentDocuments(filter) {
  const { location, documentType, instrumentId } = filter || {};
  const conditions = [];

  if (location) {
    conditions.push(where('location', '==', location));
  }

  if (documentType) {
    conditions.push(where('documentType', '==', documentType));
  }

  if (instrumentId) {
    conditions.push(where('instrumentId', '==', instrumentId));
  }

  return useQuery(
    ['inventoryDocuments', { ...filter }],
    createQuery(() => query(collection(db, 'documents'), ...conditions))
  );
}

// useInstrumentDocuments
export function useInstrumentDocumentDetails(id) {
  return useQuery(
    ['inventoryDocumentDetails', { id }],
    createQuery(() => query(collection(db, 'documents'), where('id', '==', id)))
  );
}

// Create a new InstrumentDocuments
export async function setInstrumentDocuments(data) {
  if (!data.id) {
    const ref = await addDoc(collection(db, 'documents'), data);
    data.id = ref.id;
  }
  return setDoc(doc(collection(db, 'documents'), data.id), {
    ...data,
    createdAt: serverTimestamp()
  });
}

// delete InstrumentDocuments
export async function deleteInstrumentDocuments(data) {
  return deleteDoc(doc(db, 'documents', data.id));
}

// useInstrumentNotifications
export function useInstrumentNotifications(filter) {
  const { eventId } = filter;
  return useQuery(
    ['inventoryNotifications', { ...filter }],
    createQuery(() => query(collection(db, 'notification_groups'), where('eventId', '==', eventId)))
  );
}

// Create a new InstrumentNotifications
export async function setInstrumentNotifications(data) {
  if (!data.id) {
    const ref = await addDoc(collection(db, 'notification_groups'), data);
    data.id = ref.id;
  }
  return setDoc(doc(collection(db, 'notification_groups'), data.id), {
    ...data,
    createdAt: serverTimestamp()
  });
}

// delete InstrumentNotifications
export async function deleteInstrumentNotifications(data) {
  return deleteDoc(doc(db, 'notification_groups', data.id));
}

// useInstrumentNotificationGroups
export function useInstrumentNotificationGroups() {
  return useQuery(
    ['inventoryNotifications'],
    createQuery(() => query(collection(db, 'notification_groups')))
  );
}

// Create a new InstrumentNotificationGroups
export async function setInstrumentNotificationGroups(data) {
  if (!data.id) {
    const ref = await addDoc(collection(db, 'notification_groups'), data);
    data.id = ref.id;
  }
  return setDoc(doc(collection(db, 'notification_groups'), data.id), {
    ...data,
    createdAt: serverTimestamp()
  });
}

// delete InstrumentNotificationGroups
export async function deleteInstrumentNotificationGroups(data) {
  return deleteDoc(doc(db, 'notification_groups', data.id));
}

// Create a new InstrumentStatusLogs
export async function setInstrumentStatusLog(data) {
  if (!data.id) {
    const ref = await addDoc(collection(db, 'instrument_status_logs'), data);
    data.id = ref.id;
  }
  return setDoc(doc(collection(db, 'instrument_status_logs'), data.id), {
    ...data,
    createdAt: serverTimestamp()
  });
}

export function getInstrumentStatusLog(documentId, type) {
  return useQuery(
    ['instrumentStatusLog', { documentId, type }],
    createQuery(() =>
      query(
        collection(db, 'instrument_status_logs'),
        where('type', '==', type),
        where('documentId', '==', documentId)
      )
    )
  );
}

export function useInstrumentStatusLogs(type) {
  return useQuery(
    ['instrumentStatusLog', { type }],
    createQuery(() => query(collection(db, 'instrument_status_logs'), where('type', '==', type))),
    { enabled: !!type }
  );
}

export async function ImportInstrumentEquipments(instruments) {
  try {
    const enDocRef = await getEventNumberSettingsRef();

    await runTransaction(db, async (transaction) => {
      const newEventNumber = await getNewEventNumber(enDocRef, transaction);

      instruments.forEach((instrument) => {
        const newInstrumentRef = doc(collection(db, 'equipments'));
        let { events, ...instrumentFields } = instrument;
        transaction.set(newInstrumentRef, {
          ...instrumentFields,
          id: newInstrumentRef.id
        });

        events.forEach((event) => {
          newEventNumber.sequential += 1;

          const newEventRef = doc(collection(db, 'events'));

          transaction.set(newEventRef, {
            ...event,
            id: newEventRef.id,
            instrumentName: newInstrumentRef.id,
            eventNumber: `${newEventNumber.dateSection}${newEventNumber.sequential
              .toString()
              .padStart(4, '0')}`,
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp()
          });
        });

        transaction.update(enDocRef, newEventNumber);
      });
    });
  } catch (e) {
    console.log('Transaction failed: ', e);
  }
}

// Saving filter
export async function setFilter(data) {
  try {
    const filtersCollection = collection(db, 'filter');
    const q = query(
      filtersCollection,
      where('name', '==', data.name),
      where('page', '==', data.page)
    );
    const querySnapshot = await getDocs(q);

    let docId = null;

    if (!querySnapshot.empty) {
      querySnapshot.forEach((doc) => {
        docId = doc.id;
      });
    }

    if (docId) {
      await setDoc(doc(filtersCollection, docId), {
        ...data,
        createdAt: serverTimestamp()
      });
    } else {
      const ref = await addDoc(filtersCollection, {
        ...data,
        createdAt: serverTimestamp()
      });
      docId = ref.id;
    }

    return docId;
  } catch (error) {
    console.error('Error saving filter:', error);
    throw error;
  }
}

export function useEventFilters() {
  const page = 'EVENT';
  return useQuery(
    ['eventFilters', { page }],
    createQuery(() => query(collection(db, 'filter'), where('page', '==', page)))
  );
}

export function useInstrumentFilters() {
  const page = 'INSTRUMENT';
  return useQuery(
    ['instrumentFilters', { page }],
    createQuery(() => query(collection(db, 'filter'), where('page', '==', page)))
  );
}

export async function deleteFilter(data) {
  return deleteDoc(doc(db, 'filter', data.id));
}

export async function updateChartSigDigits({ keyToUpdate = [], chartSigDigits = 4, year }) {
  try {
    if (!keyToUpdate.length) {
      console.log('No keys provided for update. Exiting...');
      return;
    }

    const keySet = new Set(keyToUpdate);
    const dataQuery = query(collection(db, 'config'), where('recType', '==', 'configurations'));
    const querySnapshot = await getDocs(dataQuery);

    let batchSize = 0;
    let countRecordUpdated = 0;
    let batch = writeBatch(db);

    for (const doc of querySnapshot.docs) {
      const data = doc.data();
      const fullKey = data.fullKey || data.shortKey || '';
      const currentChartSigDigits = data.chartSigDigits;
      const sampleName = fullKey?.split('|')[2] || '';
      const archive = data.archive ?? 'No';

      // Only update if fullKey starts with one of the values in keyToUpdate.
      if (![...keySet].some((key) => fullKey.startsWith(key))) continue;

      // Ignore if it does not contain year (if year is specified)
      if (year && !sampleName.includes(year.toString())) continue;

      // Only update if chartSigDigits differs from the desired value and not archive.
      if (currentChartSigDigits !== chartSigDigits && archive === 'No') {
        batch.update(doc.ref, { chartSigDigits });
        console.log('Updated >>> ', fullKey);
        batchSize++;

        if (batchSize >= BATCH_SIZE) {
          await batch.commit();
          batch = writeBatch(db);
          countRecordUpdated += batchSize;
          batchSize = 0;
        }
      }
    }

    if (batchSize > 0) {
      await batch.commit();
      countRecordUpdated += batchSize;
    }

    console.log(
      `Successfully updated chartSigDigits to ${chartSigDigits} (${countRecordUpdated} records updated)`
    );
  } catch (error) {
    console.error('Error updating chartSigDigits:', error);
  }
}

function getParameters(data, locationName, methodName, sampleName, instrumentName) {
  for (const item of data) {
    if (item.name === locationName) {
      for (const method of item.method) {
        if (method.name === methodName) {
          for (const sample of method.sampleName) {
            if (sample.name === sampleName) {
              for (const instrument of sample.instrument) {
                if (instrument.name === instrumentName) {
                  return instrument.parameter.map((param) => param.name);
                }
              }
            }
          }
        }
      }
    }
  }

  console.log(`Not found param by ${locationName}|${methodName}|${sampleName}|${instrumentName}`);
  return [];
}

function cloneObjects(currentActiveIndexes, inputObject) {
  const { summaryStatistics, shortKey, ...rest } = inputObject;
  const [location, method, sampleName, instrument] = shortKey.split('|');
  const params = getParameters(
    currentActiveIndexes[0].index,
    location,
    method,
    sampleName,
    instrument
  );

  const excludedKeys = ['Combined Z-score', 'statistic'];
  return params
    .filter((param) => !excludedKeys.includes(param))
    .map((param) => ({
      ...rest,
      summaryStatistics: summaryStatistics.map((stat) => ({
        statistic: stat.statistic,
        'Combined Z-score': stat['Combined Z-score'] || '',
        [firebaseClean(param)]: stat[firebaseClean(param)] || null
      })),
      clonedKey: param,
      shortKey,
      fullKey: `${shortKey}|${param}`
    }));
}

export async function handleSyncAssessments(currentActiveIndexes) {
  try {
    const q = query(collection(db, 'assessments'), where('type', '==', 'Initial'));

    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const docs = querySnapshot.docs;
      const initAllAssessment = docs.filter((doc) => !doc.data().archive);
      const initAssessmentByFullkey = initAllAssessment.filter((doc) => doc.data().fullKey);
      const initAssessmentRemain = initAllAssessment.filter((doc) => !doc.data().fullKey);

      // Delete initAssessment by fullKey
      const deletePromises = initAssessmentByFullkey.map((assessment) =>
        deleteDoc(doc(db, 'assessments', assessment.id))
      );
      await Promise.all(deletePromises);

      const creationPromises = [];

      for (const assessment of initAssessmentRemain) {
        const cloneAssessments = cloneObjects(currentActiveIndexes, assessment.data());
        creationPromises.push(...cloneAssessments.map((item) => createAssessment(item)));
      }
      await Promise.all(creationPromises);
    }
  } catch (error) {
    console.error('Error handleSyncAssessments:', error);
    throw error;
  }
}

export function getDefaultControlStrategyConfiguration() {
  return {
    andersonDarling: 'Off',
    archive: 'No',
    chartSigDigits: 3,
    controlLimitExceedance: 'Off',
    controlLimitViolation: 'Out of Control',
    controlStatus: 'Yes',
    controlStrategy: 'EWMA',
    duiChart: 'Yes',
    ewmaTrend: 'Out of Control',
    fiveConsecutive: 'Off',
    fivePointViolation: 'Out of Control',
    fullKey: '',
    lowerControlLimit: 'N/A',
    lowerControlLimitApproach: 'Default',
    lowerToleranceLimit: 'N/A',
    lowerToleranceLimitApproach: 'Default',
    mean: 'N/A',
    n: '',
    standardDeviation: 'N/A',
    nineConsecutive: 'Out of Control',
    pretreatData: 'No',
    repeatability: 'Default',
    reproducibility: 'Default',
    sevenConsecutive: 'Off',
    shortKey: '',
    singlePointViolation: 'Warning',
    suppressNormality: 'No',
    suppressTPI: 'No',
    tenNinetyMaterial: 'N/A',
    toleranceLimitExceedance: 'Off',
    tpiOnePointTwo: 'Off',
    twoOfThree: 'Off',
    type: 'Control Strategy',
    upperControlLimit: 'N/A',
    upperControlLimitApproach: 'Default',
    upperToleranceLimit: 'N/A',
    upperToleranceLimitApproach: 'Default',
    warningLimitViolation: 'Off',
    ewmaLambda: '0.4',
    tAlpha: '0.05',
    fAlpha: '0.05'
  };
}
