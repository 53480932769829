import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { requireAuth, useAuth } from '../util/auth';
import {
  createNotification,
  updateNotification,
  useFindNotificationsByLocation,
  useGetIndexValues,
  useGetPersonnel
} from '../util/db';
import { checkReadOnly, renderButtonWithPermissions } from '../util/util';
import { SCREEN, RULES } from '../util/Constant';

function NotificationsPage() {
  const auth = useAuth();
  const [indexes, setIndexes] = useState([]);
  const { data: personnel } = useGetPersonnel();
  const [currentLocations, setCurrentLocations] = useState([]);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [currentPersonnel, setCurrentPersonnel] = useState([]);
  const { data: notifications } = useFindNotificationsByLocation(currentLocation);
  const [permission, setPermission] = useState(null);
  const [readOnly, setReadOnly] = useState(false);
  useEffect(() => {
    if (Array.isArray(personnel) && personnel.length > 0) {
      setCurrentPersonnel(personnel);
      const matchingUser = personnel.find((p) => p.email === auth.user.email);
      if (matchingUser) {
        setPermission(auth.permissionData[matchingUser.permission]);
      }
    }
  }, [personnel]);

  useEffect(() => {
    if (permission) {
      setReadOnly(checkReadOnly(permission, SCREEN?.NOTIFICATIONS));
    }
  }, [permission]);

  const defaultNotifications = {
    enabled: 'Off',
    location: currentLocation,
    saeValue: 'Off',
    saeText: 'Please be advised sample XXXX is within YY days of expiration',
    saeDaysBefore: 5,
    seValue: 'Off',
    seText: 'Please be advised sample XXXX has expired',
    wvValue: 'On',
    wvText:
      'Please be advised that (Chart set) is under warning for (parameter) because (Flag) violation.',
    wvFrequency: 'Immediately',
    wvTime: '12:00 PM',
    oocValue: 'On',
    oocText:
      'Please be advised that (Chart set) is out of control for (parameter) because (Flag) violation',
    oocFrequency: 'Immediately',
    oocTime: '12:00 PM',
    srValue: 'Off',
    srText:
      'Please be advised that test result has not been entered for (Chart set) for QC run scheduled for (date/time)',
    srHours: 1,
    rcValue: 'Off',
    rcText:
      'Please be advised that (chart set) was designated out of control at (date/time), and a re-check has not been run within XX hours',
    rcHours: 4,
    icaValue: 'Off',
    icaText: 'Please be advised that (Chart set) is ready for initial assessment',
    icaValuesBefore: 20,
    icaPastDueValue: 'Off',
    icaPastDueText: 'Please be advised that (Chart set) is past due for initial assessment',
    icaPastDueDays: 20,
    paValue: 'Off',
    paText: 'Please be advised that (Chart set) is ready for periodic assessment',
    paValuesSince: 20,
    paPastDueValue: 'Off',
    paPastDueText: 'Please be advised that (Chart set) is past due for periodic assessment',
    paPastDueDays: 50
  };

  const [currentNotifications, setCurrentNotifications] = useState(defaultNotifications);

  useEffect(() => {
    if (Array.isArray(indexes) && indexes.length > 0) {
      const locations = [];
      indexes[0].index.forEach((location) => {
        locations.push(location);
      });
      setCurrentLocations(locations);
      if (Array.isArray(locations) && locations.length > 0) {
        setCurrentLocation(locations[0].name);
      }
    }
  }, [indexes]);

  useEffect(() => {
    (async () => {
      const result = await useGetIndexValues();
      setIndexes(result.data);
    })();
  }, []);

  useEffect(() => {}, [currentNotifications]);

  useEffect(() => {
    if (Array.isArray(notifications)) {
      if (notifications.length > 0) {
        setCurrentNotifications(notifications[0]);
      } else {
        createNotification(defaultNotifications);
      }
    }
  }, [notifications]);

  const handleLocationChange = (e) => {
    setCurrentLocation(e.target.value);
  };

  const handleSaeValueChange = (e) => {
    setCurrentNotifications({
      ...currentNotifications,
      saeValue: e.target.value
    });
  };

  const handleSaeTextChange = (e) => {
    setCurrentNotifications({
      ...currentNotifications,
      saeText: e.target.value
    });
  };

  const handleSaeDaysBeforeChange = (e) => {
    setCurrentNotifications({
      ...currentNotifications,
      saeDaysBefore: e.target.value
    });
  };

  const handleSeValueChange = (e) => {
    setCurrentNotifications({
      ...currentNotifications,
      seValue: e.target.value
    });
  };
  const handleSeTextChange = (e) => {
    setCurrentNotifications({
      ...currentNotifications,
      seText: e.target.value
    });
  };
  const handleWvValueChange = (e) => {
    setCurrentNotifications({
      ...currentNotifications,
      wvValue: e.target.value
    });
  };
  const handleWvTextChange = (e) => {
    setCurrentNotifications({
      ...currentNotifications,
      wvText: e.target.value
    });
  };
  const handleWvFrequencyChange = (e) => {
    setCurrentNotifications({
      ...currentNotifications,
      wvFrequency: e.target.value
    });
  };
  const handleWvTimeChange = (e) => {
    setCurrentNotifications({
      ...currentNotifications,
      wvTime: e.target.value
    });
  };
  const handleOocValueChange = (e) => {
    setCurrentNotifications({
      ...currentNotifications,
      oocValue: e.target.value
    });
  };
  const handleOocTextChange = (e) => {
    setCurrentNotifications({
      ...currentNotifications,
      oocText: e.target.value
    });
  };
  const handleOocFrequencyChange = (e) => {
    setCurrentNotifications({
      ...currentNotifications,
      oocFrequency: e.target.value
    });
  };
  const handleOocTimeChange = (e) => {
    setCurrentNotifications({
      ...currentNotifications,
      oocTime: e.target.value
    });
  };
  const handleSrValueChange = (e) => {
    setCurrentNotifications({
      ...currentNotifications,
      srValue: e.target.value
    });
  };
  const handleSrTextChange = (e) => {
    setCurrentNotifications({
      ...currentNotifications,
      srText: e.target.value
    });
  };
  const handleSrHoursChange = (e) => {
    setCurrentNotifications({
      ...currentNotifications,
      srHours: e.target.value
    });
  };
  const handleRecheckValueChange = (e) => {
    setCurrentNotifications({
      ...currentNotifications,
      rcValue: e.target.value
    });
  };
  const handleRecheckTextChange = (e) => {
    setCurrentNotifications({
      ...currentNotifications,
      rcText: e.target.value
    });
  };
  const handleRecheckHoursChange = (e) => {
    setCurrentNotifications({
      ...currentNotifications,
      rcHours: e.target.value
    });
  };
  const handleIcaValueChange = (e) => {
    setCurrentNotifications({
      ...currentNotifications,
      icaValue: e.target.value
    });
  };
  const handleIcaTextChange = (e) => {
    setCurrentNotifications({
      ...currentNotifications,
      icaText: e.target.value
    });
  };
  const handleIcaValuesBeforeChange = (e) => {
    setCurrentNotifications({
      ...currentNotifications,
      icaValuesBefore: e.target.value
    });
  };
  const handlePastDueValueChange = (e) => {
    setCurrentNotifications({
      ...currentNotifications,
      icaPastDueValue: e.target.value
    });
  };
  const handlePastDueTextChange = (e) => {
    setCurrentNotifications({
      ...currentNotifications,
      icaPastDueText: e.target.value
    });
  };
  const handlePastDueDaysChange = (e) => {
    setCurrentNotifications({
      ...currentNotifications,
      icaPastDueDays: e.target.value
    });
  };
  const handlePaValueChange = (e) => {
    setCurrentNotifications({
      ...currentNotifications,
      paValue: e.target.value
    });
  };
  const handlePaTextChange = (e) => {
    setCurrentNotifications({
      ...currentNotifications,
      paText: e.target.value
    });
  };
  const handlePaValuesSinceChange = (e) => {
    setCurrentNotifications({
      ...currentNotifications,
      paValuesSince: e.target.value
    });
  };
  const handlePaPastDueValueChange = (e) => {
    setCurrentNotifications({
      ...currentNotifications,
      paPastDueValue: e.target.value
    });
  };
  const handlePaPastDueTextChange = (e) => {
    setCurrentNotifications({
      ...currentNotifications,
      paPastDueText: e.target.value
    });
  };
  const handlePaPastDueDaysChange = (e) => {
    setCurrentNotifications({
      ...currentNotifications,
      paPastDueDays: e.target.value
    });
  };

  const handleNotificationsToggle = (value) => {
    setCurrentNotifications({
      ...currentNotifications,
      enabled: value
    });
  };

  const saveNotifications = () => {
    updateNotification(currentNotifications.id, currentNotifications);
  };

  const renderOptionsDynamic = (dropdownOptions) => {
    const rows = [];
    dropdownOptions.forEach((option) => {
      rows.push(<option value={option?.name}>{option?.name}</option>);
    });
    return rows;
  };

  return (
    <>
      <div className="top-container">
        <div>
          <div className="location-label">Laboratory</div>
          <div className="location-select">
            <Form.Select
              value={currentLocation}
              onChange={handleLocationChange}
              aria-label="Default select example"
            >
              {renderOptionsDynamic(currentLocations)}
            </Form.Select>
          </div>
        </div>
        <div>
          <ButtonGroup>
            <ToggleButton
              type="radio"
              id={1}
              variant="outline-primary"
              name="radio"
              checked={currentNotifications.enabled === 'On'}
              onChange={() => handleNotificationsToggle('On')}
            >
              On
            </ToggleButton>
            <ToggleButton
              type="radio"
              id={2}
              variant="outline-primary"
              name="radio"
              checked={currentNotifications.enabled === 'Off'}
              onChange={() => handleNotificationsToggle('Off')}
            >
              Off
            </ToggleButton>
          </ButtonGroup>
        </div>
      </div>
      <div className="main-container-normal">
        <div className="notification-row">
          <div className="variable-column">Variable</div>
          <div className="value-column">Value</div>
          <div className="text-column">Text</div>
        </div>
        <div className="notification-row">
          <div className="variable-column fw-bold">1. Sample Expiration approaching</div>
          <div className="value-column">
            <Form.Select
              disabled={readOnly}
              value={currentNotifications.saeValue}
              onChange={handleSaeValueChange}
              aria-label="Default select example"
            >
              <option value="Off">Off</option>
              <option value="On">On</option>
            </Form.Select>
          </div>
          <div className="text-column">
            <input
              readOnly={readOnly}
              size="60"
              value={currentNotifications.saeText}
              onChange={handleSaeTextChange}
              placeholder="Please be advised sample XXXX is within YY days of expiration"
            />
          </div>
        </div>
        <div className="notification-row">
          <div className="variable-column indent">Days before expiration date</div>
          <div className="value-column">
            <input
              readOnly={readOnly}
              size="10"
              value={currentNotifications.saeDaysBefore}
              onChange={handleSaeDaysBeforeChange}
            />
          </div>
          <div className="text-column">
            <input readOnly size="60" value="" />
          </div>
        </div>
        <div className="notification-row">
          <div className="variable-column">2. Sample has expired</div>
          <div className="value-column">
            <Form.Select
              disabled={readOnly}
              value={currentNotifications.seValue}
              onChange={handleSeValueChange}
              aria-label="Default select example"
            >
              <option value="Off">Off</option>
              <option value="On">On</option>
            </Form.Select>
          </div>
          <div className="text-column">
            <input
              readOnly={readOnly}
              size="60"
              value={currentNotifications.seText}
              onChange={handleSeTextChange}
              placeholder="Please be advised sample XXXX has expired"
            />
          </div>
        </div>
        <div className="notification-row">
          <div className="variable-column">3. Warning violation</div>
          <div className="value-column">
            <Form.Select
              disabled={readOnly}
              value={currentNotifications.wvValue}
              onChange={handleWvValueChange}
              aria-label="Default select example"
            >
              <option value="Off">Off</option>
              <option value="On">On</option>
            </Form.Select>
          </div>
          <div className="text-column">
            <input
              readOnly={readOnly}
              size="80"
              value={currentNotifications.wvText}
              onChange={handleWvTextChange}
              placeholder="Please be advised that (Chart set) is under warning for (parameter) because (Flag) violation."
            />
          </div>
        </div>
        <div className="notification-row">
          <div className="variable-column indent">Frequency</div>
          <div className="value-column">
            <Form.Select
              disabled={readOnly}
              value={currentNotifications.wvFrequency}
              onChange={handleWvFrequencyChange}
              aria-label="Default select example"
            >
              <option value="Immediately">Immediately</option>
              <option value="Daily">Daily</option>
            </Form.Select>
          </div>
          <div className="text-column">
            <input readOnly={readOnly} size="60" value="" />
          </div>
        </div>
        <div className="notification-row">
          <div className="variable-column indent">Time</div>
          <div className="value-column">
            <input
              readOnly={readOnly}
              size="10"
              value={currentNotifications.wvTime}
              onChange={handleWvTimeChange}
            />
          </div>
          <div className="text-column">
            <input size="60" value="" />
          </div>
        </div>
        <div className="notification-row">
          <div className="variable-column">4. Out of control violation</div>
          <div className="value-column">
            <Form.Select
              disabled={readOnly}
              value={currentNotifications.oocValue}
              onChange={handleOocValueChange}
              aria-label="Default select example"
            >
              <option value="On">On</option>
              <option value="Off">Off</option>
            </Form.Select>
          </div>
          <div className="text-column">
            <input
              readOnly={readOnly}
              value={currentNotifications.oocText}
              onChange={handleOocTextChange}
              size="80"
              placeholder="Please be advised that (Chart set) is out of control for (parameter) because (Flag) violation"
            />
          </div>
        </div>
        <div className="notification-row">
          <div className="variable-column indent">Frequency</div>
          <div className="value-column">
            <Form.Select
              disabled={readOnly}
              value={currentNotifications.oocFrequency}
              onChange={handleOocFrequencyChange}
              aria-label="Default select example"
            >
              <option value="Immediately">Immediately</option>
              <option value="Daily">Daily</option>
            </Form.Select>
          </div>
          <div className="text-column">
            <input readOnly={readOnly} size="60" value="" />
          </div>
        </div>
        <div className="notification-row">
          <div className="variable-column indent">Time</div>
          <div className="value-column">
            <input
              readOnly={readOnly}
              size="10"
              value={currentNotifications.oocTime}
              onChange={handleOocTimeChange}
            />
          </div>
          <div className="text-column">
            <input readOnly={readOnly} size="60" value="" />
          </div>
        </div>
        <div className="notification-row">
          <div className="variable-column">5. Scheduled result not entered</div>
          <div className="value-column">
            <Form.Select
              disabled={readOnly}
              value={currentNotifications.srValue}
              onChange={handleSrValueChange}
              aria-label="Default select example"
            >
              <option value="Off">Off</option>
              <option value="On">On</option>
            </Form.Select>
          </div>
          <div className="text-column">
            <textarea
              readOnly={readOnly}
              value={currentNotifications.srText}
              onChange={handleSrTextChange}
              placeholder="Please be advised that test result has not been entered for (Chart set) for QC run scheduled for (date/time)"
            />
          </div>
        </div>
        <div className="notification-row">
          <div className="variable-column indent">Hours (after time)</div>
          <div className="value-column">
            <input
              readOnly={readOnly}
              value={currentNotifications.srHours}
              onChange={handleSrHoursChange}
              size="10"
              placeholder="1"
            />
          </div>
          <div className="text-column">
            <input readOnly={readOnly} size="60" />
          </div>
        </div>
        <div className="notification-row">
          <div className="variable-column">6. Recheck out of control data</div>
          <div className="value-column">
            <Form.Select
              disabled={readOnly}
              value={currentNotifications.rcValue}
              onChange={handleRecheckValueChange}
              aria-label="Default select example"
            >
              <option value="Off">Off</option>
              <option value="On">On</option>
            </Form.Select>
          </div>
          <div className="text-column">
            <textarea
              readOnly={readOnly}
              size="100"
              value={currentNotifications.rcText}
              onChange={handleRecheckTextChange}
              placeholder="Please be advised that (chart set) was designated out of control at (date/time), and a re-check has not been run within XX hours"
            />
          </div>
        </div>
        <div className="notification-row">
          <div className="variable-column indent">Hours (after designated time)</div>
          <div className="value-column">
            <input
              readOnly={readOnly}
              value={currentNotifications.rcHours}
              onChange={handleRecheckHoursChange}
              size="10"
              placeholder="4"
            />
          </div>
          <div className="text-column">
            <input readOnly={readOnly} size="60" />
          </div>
        </div>
        <div className="notification-row">
          <div className="variable-column">7. Initial chart assessment</div>
          <div className="value-column">
            <Form.Select
              disabled={readOnly}
              value={currentNotifications.icaValue}
              onChange={handleIcaValueChange}
              aria-label="Default select example"
            >
              <option value="Off">Off</option>
              <option value="On">On</option>
            </Form.Select>
          </div>
          <div className="text-column">
            <input
              readOnly={readOnly}
              size="60"
              value={currentNotifications.icaText}
              onChange={handleIcaTextChange}
              placeholder="Please be advised that (Chart set) is ready for initial assessment"
            />
          </div>
        </div>
        <div className="notification-row">
          <div className="variable-column indent">X setup data values before notification</div>
          <div className="value-column">
            <input
              size="10"
              readOnly={readOnly}
              value={currentNotifications.icaValuesBefore}
              onChange={handleIcaValuesBeforeChange}
              placeholder="20"
            />
          </div>
          <div className="text-column">
            <input readOnly={readOnly} size="60" />
          </div>
        </div>
        <div className="notification-row">
          <div className="variable-column">8. Initial chart assessment past due</div>
          <div className="value-column">
            <Form.Select
              disabled={readOnly}
              value={currentNotifications.icaPastDueValue}
              onChange={handlePastDueValueChange}
              aria-label="Default select example"
            >
              <option value="Off">Off</option>
              <option value="On">On</option>
            </Form.Select>
          </div>
          <div className="text-column">
            <input
              readOnly={readOnly}
              value={currentNotifications.icaPastDueText}
              onChange={handlePastDueTextChange}
              size="60"
              placeholder="Please be advised that (Chart set) is past due for initial assessment"
            />
          </div>
        </div>
        <div className="notification-row">
          <div className="variable-column indent">Number of days overdue</div>
          <div className="value-column">
            <input
              readOnly={readOnly}
              size="10"
              value={currentNotifications.icaPastDueDays}
              onChange={handlePastDueDaysChange}
              placeholder="20"
            />
          </div>
          <div className="text-column">
            <input readOnly={readOnly} size="60" value="" />
          </div>
        </div>
        <div className="notification-row">
          <div className="variable-column">9. Periodic assessment</div>
          <div className="value-column">
            <Form.Select
              disabled={readOnly}
              value={currentNotifications.paValue}
              onChange={handlePaValueChange}
              aria-label="Default select example"
            >
              <option value="Off">Off</option>
              <option value="On">On</option>
            </Form.Select>
          </div>
          <div className="text-column">
            <input
              readOnly={readOnly}
              size="60"
              value={currentNotifications.paText}
              onChange={handlePaTextChange}
              placeholder="Please be advised that (Chart set) is ready for periodic assessment"
            />
          </div>
        </div>
        <div className="notification-row">
          <div className="variable-column indent">X values since last assessment</div>
          <div className="value-column">
            <input
              value={currentNotifications.paValuesSince}
              onChange={handlePaValuesSinceChange}
              size="10"
              placeholder="20"
            />
          </div>
          <div className="text-column">
            <input size="60" value="" />
          </div>
        </div>
        <div className="notification-row">
          <div className="variable-column">10. Periodic assessment past due</div>
          <div className="value-column">
            <Form.Select
              disabled={readOnly}
              value={currentNotifications.paPastDueValue}
              onChange={handlePaPastDueValueChange}
              aria-label="Default select example"
            >
              <option value="Off">Off</option>
              <option value="On">On</option>
            </Form.Select>
          </div>
          <div className="text-column">
            <input
              readOnly={readOnly}
              size="60"
              value={currentNotifications.paPastDueText}
              onChange={handlePaPastDueTextChange}
              placeholder="Please be advised that (Chart set) is past due for periodic assessment"
            />
          </div>
        </div>
        <div className="notification-row">
          <div className="variable-column indent">Number of days overdue</div>
          <div className="value-column">
            <input
              size="10"
              value={currentNotifications.paPastDueDays}
              onChange={handlePaPastDueDaysChange}
              placeholder="50"
            />
          </div>
          <div className="text-column">
            <input size="60" value="" />
          </div>
        </div>
      </div>

      <div className="bottom-section">
        <div>
          <div>
            {/* <PrimaryButton
              clicked={saveNotifications}
              text="Save"
            ></PrimaryButton> */}
            {renderButtonWithPermissions(
              'Save',
              () => saveNotifications(),
              SCREEN?.NOTIFICATIONS,
              RULES.SAVE,
              permission
            )}
          </div>
          <div className="bottom-text">
            Notifications apply across all charts at a lab and are sent to anyone with permissions
            to a chart set. If no permissions are set, then they will be sent to everyone at that
            lab.
          </div>
        </div>
      </div>
    </>
  );
}

export default requireAuth(NotificationsPage);
