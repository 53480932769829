import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { Checkbox } from '@mui/material';
import { useAuth } from '../../util/auth';
import { useRouter } from '../../util/router';
import {
  getControlStrategyConfigurationByFullKey,
  getDataByFullKey,
  useGetIndexValues,
  useGetSummaries
} from '../../util/db';
import CustomTable from '../Table/CustomTable';
import Section from '../Section';
import { archiveRegex } from '../../util/Constant';
import './SPCDashboardSection.scss';
import { getCurrentFeatureFlags } from '../../util/firebase';
import {
  arrayAverage,
  calculateHistogram,
  firebaseClean,
  getSigDigitsUtil,
  getTimeZone
} from '../../util/util';
import { oocFlagColumns, wFlagColumns } from '../Columns';
import { formatInTimeZone } from 'date-fns-tz';
import createTrend from 'trendline';
import SQCLineChart from '../SQCLineChart';
import {
  convertToDailyData,
  extractFullKeys,
  formatNumberDisplay,
  getChartLines,
  separateFullKey,
  sortNumberComparator
} from './utils';
import PrintOverviewDataTable from '../Table/PrintOverviewDataTable';

const variation = 'spc';

const defaultControlStrategyConfigurations = {
  andersonDarling: 'Off',
  archive: 'No',
  chartSigDigits: 3,
  controlLimitExceedance: 'Out of Control',
  controlLimitViolation: 'Warning',
  controlStrategy: 'Run rules',
  duiChart: 'No',
  ewmaTrend: 'Off',
  fiveConsecutive: 'Off',
  fivePointViolation: 'Off',
  fullKey: '',
  kStdDev: 3,
  lowerControlLimit: 'N/A',
  lowerControlLimitApproach: 'Default',
  lowerToleranceLimit: 'N/A',
  lowerToleranceLimitApproach: 'Default',
  mean: 'N/A',
  n: '',
  standardDeviation: 'N/A',
  nineConsecutive: 'Off',
  nSamplesPerDay: 3,
  pretreatData: 'No',
  repeatability: 'Default',
  reproducibility: 'Default',
  sevenConsecutive: 'Off',
  shortKey: '',
  singlePointViolation: 'Off',
  suppressNormality: 'No',
  suppressTPI: 'No',
  tenNinetyMaterial: 'N/A',
  toleranceLimitExceedance: 'Off',
  tpiOnePointTwo: 'Off',
  twoOfThree: 'Off',
  type: 'Control Strategy',
  upperControlLimit: 'N/A',
  upperControlLimitApproach: 'Default',
  upperToleranceLimit: 'N/A',
  upperToleranceLimitApproach: 'Default',
  warningLimitViolation: 'Off',
  ewmaLambda: '0.4',
  tAlpha: '0.05',
  fAlpha: '0.05'
};

function SPCDashboardSection({ bg, textColor, bgImage, bgImageOpacity }) {
  const auth = useAuth();
  const router = useRouter();
  const { data: rawSummaries } = useGetSummaries(variation);
  const [currentSummaries, setCurrentSummaries] = useState([]);
  const [selectedSummaries, setSelectedSummaries] = useState([]);
  const [featureFlags] = useState(getCurrentFeatureFlags());
  const [tz] = useState(getTimeZone(featureFlags));
  const [chartElements, setChartElements] = useState([]);
  const [spcFullKeyMap, setSpcFullKeyMap] = useState({});
  const [activeTab, setActiveTab] = useState('Overview');

  const handlePrintClick = () => {
    setActiveTab('PrintOverview');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleBackToOverview = () => {
    setActiveTab('Overview');
  };

  useEffect(() => {
    (async () => {
      const result = await useGetIndexValues(variation);
      if (!result?.data) return;

      const activeIndexes = result.data.filter((index) => !index.id.includes('Archive'));
      if (!Array.isArray(activeIndexes) || activeIndexes.length === 0) return;

      const allIndexes = activeIndexes[0].index;
      const fullKeys = extractFullKeys(allIndexes);

      const fullKeyLookup = fullKeys.reduce((acc, fullKey) => {
        acc[firebaseClean(fullKey)] = fullKey;
        return acc;
      }, {});

      setSpcFullKeyMap(fullKeyLookup);
    })();
  }, []);

  useEffect(() => {
    if (Array.isArray(rawSummaries)) {
      const summaries = [];
      for (const summary of rawSummaries) {
        if (!archiveRegex.test(summary.id)) {
          const fullKey = spcFullKeyMap[summary.id] ?? summary.id;
          summaries.push({
            ...summary,
            mean_process_or: parseFloat(summary.mean_process_or).toFixed(2),
            r_bar_sd: parseFloat(summary.r_bar_sd).toFixed(2),
            Cp: parseFloat(summary.Cp).toFixed(2),
            Cpk: parseFloat(summary.Cpk).toFixed(2),
            percent_yield: parseFloat(summary.percent_yield).toFixed(2),
            location: fullKey.split('|')[0] || '',
            type: fullKey.split('|')[1] || '',
            product: fullKey.split('|')[2] || '',
            method: fullKey.split('|')[3] || '',
            parameter: fullKey.split('|')[4] || '',
            fullKey,
            select: selectedSummaries.includes(fullKey)
          });
        }
      }
      setCurrentSummaries(summaries);
    }
  }, [rawSummaries, spcFullKeyMap]);

  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    setCurrentSummaries((prevSummaries) =>
      prevSummaries.map((summary) => ({ ...summary, select: isChecked }))
    );
    setSelectedSummaries(isChecked ? currentSummaries.map((s) => s.fullKey) : []);
  };

  const handleRowSelect = (fullKey) => (event) => {
    const isChecked = event.target.checked;
    setCurrentSummaries((prevSummaries) =>
      prevSummaries.map((summary) =>
        summary.fullKey === fullKey ? { ...summary, select: isChecked } : summary
      )
    );
    setSelectedSummaries((prevSelected) =>
      isChecked ? [...prevSelected, fullKey] : prevSelected.filter((sid) => sid !== fullKey)
    );
  };

  const detailsColumns = [
    {
      headerName: (
        <Checkbox
          checked={
            selectedSummaries.length === currentSummaries.length && currentSummaries.length > 0
          }
          indeterminate={
            selectedSummaries.length > 0 && selectedSummaries.length < currentSummaries.length
          }
          onChange={handleSelectAll}
        />
      ),
      field: 'select',
      flex: 0.5,
      minWidth: 50,
      sortable: false,
      renderCell: (params) => (
        <Checkbox
          checked={params.row.select || false}
          onChange={handleRowSelect(params.row.fullKey)}
        />
      )
    },
    { headerName: 'Location', field: 'location', flex: 1, minWidth: 250 },
    { headerName: 'Type', field: 'type', flex: 1, minWidth: 180 },
    { headerName: 'Product', field: 'product', flex: 1.5, minWidth: 150 },
    { headerName: 'Method', field: 'method', flex: 1, minWidth: 150 },
    { headerName: 'Parameters', field: 'parameter', flex: 1.5, minWidth: 150 },
    {
      headerName: 'Process Mean',
      field: 'mean_process_or',
      flex: 1,
      minWidth: 50,
      valueFormatter: formatNumberDisplay,
      sortComparator: sortNumberComparator
    },
    {
      headerName: 'Process Range Mean',
      field: 'r_bar_sd',
      flex: 1,
      minWidth: 50,
      valueFormatter: formatNumberDisplay,
      sortComparator: sortNumberComparator
    },
    {
      headerName: 'Process Capability(Cp)',
      field: 'Cp',
      flex: 1,
      minWidth: 70,
      valueFormatter: formatNumberDisplay,
      sortComparator: sortNumberComparator
    },
    {
      headerName: 'Process Capability(Cpk)',
      field: 'Cpk',
      flex: 1,
      minWidth: 70,
      valueFormatter: formatNumberDisplay,
      sortComparator: sortNumberComparator
    },
    {
      headerName: 'Percent Yield',
      field: 'percent_yield',
      flex: 1,
      minWidth: 50,
      valueFormatter: formatNumberDisplay,
      sortComparator: sortNumberComparator
    }
  ];

  const getChartParams = async (fullKey) => {
    let currentControlStrategyConfigurationsObj = {};
    const chartHits = await getDataByFullKey(fullKey, variation);
    if (!Array.isArray(chartHits)) return { chartData: [], chartScalars: {}, chartLines: [] };

    const controlStrategyConfigurations = await getControlStrategyConfigurationByFullKey(
      fullKey,
      variation
    );
    if (Array.isArray(controlStrategyConfigurations) && controlStrategyConfigurations.length > 0) {
      currentControlStrategyConfigurationsObj = controlStrategyConfigurations.sort((a, b) =>
        b.archive?.localeCompare(a.archive)
      )[0];
    } else {
      currentControlStrategyConfigurationsObj = {
        ...defaultControlStrategyConfigurations,
        fullKey
      };
    }

    const filteredChartHits = chartHits
      .map((cd, index) => {
        const wFlags = wFlagColumns.filter((f) => cd[f]).map((f) => cd[f]);
        const oocFlags = oocFlagColumns.filter((f) => cd[f]).map((f) => cd[f]);
        const flags = [
          wFlags.length > 0 ? `Warnings: ${wFlags.join(', ')}` : '',
          oocFlags.length > 0 ? `OOC: ${oocFlags.join(', ')}` : ''
        ]
          .filter(Boolean)
          .join('; ');

        const row = { index, flags };
        for (const elt of Object.keys(cd)) {
          if (['date'].includes(elt) || ['objectID'].includes(elt)) {
            row[elt] = cd[elt];
          } else if (
            String(cd[elt]).match(/[A-Z]/i) ||
            ['type', 'parameter', 'location', 'product', 'method', 'sampleId'].includes(elt)
          ) {
            row[elt] = cd[elt];
          } else if (!Number.isNaN(parseFloat(cd[elt]))) {
            row[elt] = Number(cd[elt]);
          } else if (elt !== 'flags') {
            row[elt] = cd[elt];
          }
        }
        if (row.dateTime) {
          const timeZone = tz || 'UTC';
          const theDate = new Date(row.dateTime);
          row.date = formatInTimeZone(theDate, timeZone, 'M/d/yyyy');
          row.time = formatInTimeZone(theDate, timeZone, 'h:mm aaa');
        }
        if (row.result) {
          row.result = getSigDigitsUtil(
            row.result,
            currentControlStrategyConfigurationsObj.chartSigDigits
          );
        }
        if (row.resultOmits) {
          row.resultOmits = getSigDigitsUtil(
            row.resultOmits,
            currentControlStrategyConfigurationsObj.chartSigDigits
          );
        }
        return row;
      })
      .sort((a, b) => new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime());

    const newChartData = convertToDailyData(
      filteredChartHits.filter((cd) => !!cd.resultOmits || parseInt(cd.resultOmits, 10) === 0),
      tz,
      currentControlStrategyConfigurationsObj
    );

    const rs = newChartData.map((d) => d.dateAverage);
    const rMin = rs.length > 0 ? Math.min(...rs) : 0;
    const rMax = rs.length > 0 ? Math.max(...rs) : 0;
    const topYMin =
      rMin < newChartData[newChartData.length - 1]?.sd3n
        ? rMin
        : newChartData[newChartData.length - 1]?.sd3n;
    const topYMax =
      rMax > newChartData[newChartData.length - 1]?.sd3p
        ? rMax
        : newChartData[newChartData.length - 1]?.sd3p;
    const topYInterval = topYMax - topYMin;

    const mrMean = arrayAverage(newChartData.map((d) => d.dateRange));
    const dateTrend = newChartData
      .map((item) => ({
        zScoreOmits: Number(item.zScoreOmits),
        resultOmits: Number(item.resultOmits)
      }))
      .filter((item) => !isNaN(item.zScoreOmits) && !isNaN(item.resultOmits));
    const trend = createTrend(dateTrend, 'zScoreOmits', 'resultOmits');
    const zscores = newChartData.map((d) => d.zScoreOmits);
    const newResults = newChartData.map((d) => d.resultOmits);
    const mrs = newChartData
      .filter((d) => !Number.isNaN(parseFloat(d.mrOmits)))
      .map((d) => parseFloat(d.mrOmits));
    const mrMaxVal = mrs.length > 0 ? Math.max(...mrs) : 0;
    const mrUCL = mrMean * 3.27;
    const mrMax = mrUCL > mrMaxVal ? mrUCL : mrMaxVal;
    const xMin = zscores.length > 0 ? Math.min(...zscores) : 0;
    const xMax = zscores.length > 0 ? Math.max(...zscores) : 0;
    const yMin = newResults.length > 0 ? Math.min(...newResults) : 0;
    const yMax = newResults.length > 0 ? Math.max(...newResults) : 0;

    const chartScalars = {
      mrMean,
      mrUCL,
      mrYMax: mrMax * 1.05,
      trendData: [
        { y: trend.calcY(xMin), x: xMin },
        { y: trend.calcY(xMax), x: xMax }
      ],
      qqXMin: xMin,
      qqXMax: xMax,
      qqYMin: yMin,
      qqYMax: yMax,
      cYMin: topYMin - 0.05 * topYInterval,
      cYMax: topYMax + 0.05 * topYInterval,
      histogramData: calculateHistogram(newChartData, yMin, yMax)
    };

    newChartData.forEach((data, index) => {
      data.formattedDate = new Date(data.date);
      data.updatedIndex = index;
      data.userDefinedUTL = currentControlStrategyConfigurationsObj.upperToleranceLimit;
      data.userDefinedLTL = currentControlStrategyConfigurationsObj.lowerToleranceLimit;
      data.userDefinedUCL = currentControlStrategyConfigurationsObj.upperControlLimit;
      data.userDefinedLCL = currentControlStrategyConfigurationsObj.lowerControlLimit;
    });

    const sortedAsc = newChartData.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
    return {
      currentControlStrategyConfigurationsObj,
      chartData: sortedAsc,
      chartScalars,
      chartLines: getChartLines(sortedAsc.length)
    };
  };

  useEffect(() => {
    const fetchChartData = async () => {
      const elements = await Promise.all(
        selectedSummaries.map(async (fullKey) => {
          const { currentControlStrategyConfigurationsObj, chartData, chartScalars, chartLines } =
            await getChartParams(fullKey);
          const { location, method, parameter, product, type } = separateFullKey(fullKey);

          if (
            currentControlStrategyConfigurationsObj &&
            chartData.length > 0 &&
            chartScalars &&
            chartLines.length > 0
          ) {
            return (
              <li key={fullKey} className="info-spc-item">
                <h3 className="info-spc-container">
                  <span className="info-spc-label">Type:</span> {type}
                  <span className="info-spc-label">Product:</span> {product}
                  <span className="info-spc-label">Method:</span> {method}
                  <span className="info-spc-label">Parameter:</span> {parameter}
                </h3>
                <SQCLineChart
                  xAxis="updatedIndex"
                  syncId="chained"
                  yMin={chartScalars.cYMin}
                  yMax={chartScalars.cYMax}
                  data={chartData}
                  lines={chartLines}
                  isEverFlags={false}
                  controlStrategyConfigurations={{
                    currentControlStrategyConfigurations: currentControlStrategyConfigurationsObj
                  }}
                />
              </li>
            );
          } else {
            return (
              <li key={fullKey} className="info-spc-item">
                <h3 className="info-spc-container">
                  <span className="info-spc-label">Type:</span> {type}
                  <span className="info-spc-label">Product:</span> {product}
                  <span className="info-spc-label">Method:</span> {method}
                  <span className="info-spc-label">Parameter:</span> {parameter}
                </h3>
                <p>Not found data</p>
              </li>
            );
          }
        })
      );
      setChartElements(<ul style={{ width: '100%' }}>{elements}</ul>);
    };
    if (selectedSummaries.length > 0) fetchChartData();
    else setChartElements('');
  }, [selectedSummaries, rawSummaries]);

  const renderSumariesTable = () => {
    if (activeTab === 'PrintOverview') {
      const [init, ...columnPrint] = detailsColumns;
      return <PrintOverviewDataTable columns={columnPrint} data={currentSummaries} tz={tz} />;
    } else {
      return <CustomTable header={detailsColumns} data={currentSummaries} numberOfRows={10} />;
    }
  };

  const renderSumariesBtn = () => {
    if (activeTab === 'PrintOverview') {
      return (
        <div>
          <button
            className="btn btn-primary"
            onClick={handleBackToOverview}
            style={{ marginRight: '10px' }}
          >
            Back
          </button>
          <button
            className="btn btn-primary"
            onClick={() => {
              window.print();
            }}
          >
            Print
          </button>
        </div>
      );
    } else {
      return (
        <button className="btn btn-primary" onClick={handlePrintClick}>
          Overview Print
        </button>
      );
    }
  };

  return (
    <Section
      bg={bg}
      textColor={textColor}
      size="md"
      bgImage={bgImage}
      bgImageOpacity={bgImageOpacity}
    >
      {router.query.paid && auth.user.planIsActive && (
        <Alert variant="success" className="text-center mx-auto mb-5" style={{ maxWidth: '400px' }}>
          You are now subscribed to the {auth.user.planId} plan
          <span className="ml-2" role="img" aria-label="party">
            🥳
          </span>
        </Alert>
      )}

      <Row>
        <Col lg={12}>
          <Tabs
            defaultActiveKey="summaryStatistics"
            id="uncontrolled-tab-example"
            className="tri-tabs"
          >
            <Tab eventKey="summaryStatistics" title="Summary Statistics">
              <div>
                <div>
                  <div className="header">
                    <h2>Summary Statistics Details</h2>
                  </div>
                  <div className="underline-header">
                    <hr />
                  </div>
                </div>
                <div>{renderSumariesTable()}</div>
                <div className="btn-center">{renderSumariesBtn()}</div>
              </div>
            </Tab>
            <Tab eventKey="charts" title="Charts">
              <div>
                <div>
                  <div className="header">
                    <h2>Chart Details</h2>
                  </div>
                  <div className="underline-header">
                    <hr />
                  </div>
                </div>
                <div className="chart-spc-container">
                  {selectedSummaries.length > 0 ? (
                    chartElements
                  ) : (
                    <p>
                      Please select one or more records from the list to display data in the chart.
                    </p>
                  )}
                </div>
                <div className="btn-center">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      window.print();
                    }}
                  >
                    Print
                  </button>
                </div>
              </div>
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Section>
  );
}

SPCDashboardSection.propTypes = {
  bg: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  bgImage: PropTypes.string.isRequired,
  bgImageOpacity: PropTypes.number.isRequired
};

export default SPCDashboardSection;
