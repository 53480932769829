import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import CustomTable from '../../Table/CustomTable';
import { listEventColumnsFn } from './Columns';
import {
  setFilter,
  setInstrumentEvents,
  useEventFilters,
  useGetPersonnel,
  useInstrumentEquipments,
  useInstrumentEvents,
  useInstrumentSettings,
  useInstrumentVendors
} from '../../../util/db';
import { Link, useHistory, useLocation } from '../../../util/router';
import ModalFormEvent from '../Modal/ModalFormEvent';
import { SETTING_TYPE } from '../TabSettings/DefineCategories';
import qs from 'qs';
import {
  alphaNumericSorter,
  checkHasPermission,
  renderMUIButtonWithPermissions
} from '../../../util/util';
import moment from 'moment';
import { useAuth } from '../../../util/auth';
import { ROLES, RULES, SCREEN } from '../../../util/Constant';
import { hasPermission } from '../utils';
import ModalAddFilter from '../Modal/ModalAddFilter';
import ModalListEventFilter from '../Modal/ModalListEventFilter';

const TabEvents = () => {
  const auth = useAuth();
  const history = useHistory();
  const location = useLocation();
  const [filterModel, setFilterModel] = useState({});
  const { data: personnel } = useGetPersonnel();
  const { data: events = [] } = useInstrumentEvents(filterModel);
  const { data: instruments = [] } = useInstrumentEquipments();
  const { data: vendors = [] } = useInstrumentVendors();
  const { data: instrumentSettingsEventStatus = [] } = useInstrumentSettings(
    SETTING_TYPE.EVENT_STATUS
  );
  const { data: instrumentSettingsEventType = [] } = useInstrumentSettings(SETTING_TYPE.EVENT_TYPE);
  const { data: allLocations = [] } = useInstrumentSettings(SETTING_TYPE.INSTRUMENT_LOCATION);
  const { data: eventFilters = [] } = useEventFilters();

  const [instrumentName, setInstrumentName] = useState('');
  const [responsiblePartyOfInstrument, setResponsiblePartyOfInstrument] = useState('');
  const [isOpen, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState();
  const [instrumentFilters, setInstrumentFilters] = useState();
  const [pastDueFiltered, setPastDueFiltered] = useState(false);
  const [permissionData, setPermissionData] = useState(null);
  const [isReportInstrumentIssue, setIsReportInstrumentIssue] = useState(false);
  const [locationIdSelected, setLocationIdSelected] = useState('');
  const [editingFilter, setEditingFilter] = useState(null);
  const [isOpenAddFilter, setOpenAddFilter] = useState(false);
  const [isOpenListFilter, setOpenListFilter] = useState(false);

  // add Event from Instrument Details
  useEffect(() => {
    if (location?.search) {
      const qsObj = qs.parse(String(location?.search).replace(/\?/g, ''));

      if (qsObj?.isReportInstrumentIssue && JSON.parse(qsObj?.isReportInstrumentIssue) === true) {
        setIsReportInstrumentIssue(true);
      }

      if (qsObj?.responsibleParty) {
        setResponsiblePartyOfInstrument(qsObj?.responsibleParty);
      }

      if (qsObj?.addInstrumentEvent) {
        setOpen(true);
        setInstrumentName(qsObj?.addInstrumentEvent);
      }
    }
  }, [location]);

  useEffect(() => {
    if (Array.isArray(personnel) && personnel.length > 0) {
      const matchingUser = personnel.find((person) => person.email === auth.user.email);
      if (matchingUser) {
        if (auth.permissionData) setPermissionData(auth.permissionData[matchingUser.permission]);
      }
    }
  }, [personnel]);

  const handleSave = async (data) => {
    const dataStatus = instrumentSettingsEventStatus.find((item) => item.id === data.status);
    data.statusName = dataStatus?.name || '';

    // get vendors from instrument when create
    if (!data.id) {
      const selectedInstrument = instruments.find((row) => row.id === data.instrumentName);
      data = await setInstrumentEvents({
        ...data,
        vendors: selectedInstrument?.vendors || [],
        groups: selectedInstrument?.groups || []
      });
    } else {
      await setInstrumentEvents(data);
    }

    setOpen(false);
    if (instrumentName) {
      history.push(`/instrument/detail/${instrumentName}`);
      setInstrumentName('');
    }

    return data;
  };

  const handleClose = () => {
    setIsReportInstrumentIssue(false);
    setOpen(false);
    if (instrumentName) {
      history.push('/instrument/events');
      setInstrumentName('');
      setResponsiblePartyOfInstrument('');
    }
  };

  const handleChange = (field, event) => {
    const { value } = event.target;

    // Special handling for 'date' field with "PastDue" value
    if (field === 'date') {
      if (value === 'PastDue') {
        setPastDueFiltered(true);
        setFilterModel((prev) => ({ ...prev, [field]: '' }));
      } else {
        setPastDueFiltered(false);
        setFilterModel((prev) => ({ ...prev, [field]: value || '' }));
      }
    } else {
      setFilterModel((prev) => ({ ...prev, [field]: value || '' }));
    }
  };

  const handleLocationChange = (field, event) => {
    if (event.target.value) {
      const instrumentIds = instruments
        .filter((i) => i.location == event.target.value)
        .map((i) => i.id);
      setInstrumentFilters(instrumentIds);
      setLocationIdSelected(event.target.value);
    } else {
      setInstrumentFilters(undefined);
      setLocationIdSelected('');
    }
  };

  const now = moment();

  const handleSaveFilter = async (data) => {
    const currentFilter = {
      ...filterModel,
      instrumentFilters: instrumentFilters || '',
      page: 'EVENT',
      locationIdSelected,
      name: data.name
    };
    if (pastDueFiltered) currentFilter.date = 'PastDue';
    await setFilter(currentFilter);
    setOpenAddFilter(false);
  };

  const handleApplyFilter = (data) => {
    setSearchValue('');
    if (data?.date === 'PastDue') {
      setPastDueFiltered(true);
      setFilterModel({
        ...data,
        date: ''
      });
    } else {
      setPastDueFiltered(false);
      setFilterModel(data);
    }
    setInstrumentFilters(data.instrumentFilters);
    setLocationIdSelected(data.locationIdSelected);
    setEditingFilter({
      ...editingFilter,
      name: data?.name || ''
    });
    setOpenListFilter(false);
  };

  const clearFilter = () => {
    setSearchValue('');
    setFilterModel({});
    setInstrumentFilters(undefined);
    setLocationIdSelected('');
    setEditingFilter({});
    setPastDueFiltered(false);
  };

  return (
    <>
      {hasPermission(permissionData, SCREEN?.INSTRUMENT_EVENTS, RULES.VIEW) ? (
        <>
          <div className="top-actions">
            {renderMUIButtonWithPermissions(
              'Create new event',
              () => setOpen(true),
              SCREEN?.INSTRUMENT_EVENTS,
              RULES.CREATE,
              permissionData
            )}
            {renderMUIButtonWithPermissions(
              'Report instrument issue',
              () => {
                setIsReportInstrumentIssue(true);
                setOpen(true);
              },
              SCREEN?.INSTRUMENT_EVENTS,
              RULES.CREATE,
              permissionData
            )}
            <ModalFormEvent
              show={isOpen}
              handleClose={handleClose}
              handleSave={handleSave}
              personnel={[...personnel].sort(alphaNumericSorter)}
              instrumentSettingsEventStatus={instrumentSettingsEventStatus}
              instrumentSettingsEventType={instrumentSettingsEventType}
              instruments={instruments.sort(alphaNumericSorter)}
              instrumentName={instrumentName}
              isReportInstrumentIssue={isReportInstrumentIssue}
              responsiblePartyOfInstrument={responsiblePartyOfInstrument}
              allLocations={allLocations}
            />
            <Button
              variant="contained"
              component={Link}
              sx={{ color: 'white !important' }}
              to="/instrument/events/at-a-glance"
            >
              At a glance
            </Button>
          </div>
          <div className="filter">
            <p>
              <strong>Filter data by:</strong>
            </p>
            <div>
              <Row>
                <Form.Group as={Col} controlId="location">
                  <Form.Label>Location</Form.Label>
                  <Form.Select
                    value={locationIdSelected ?? ''}
                    onChange={(event) => handleLocationChange('location', event)}
                  >
                    <option value="">All locations</option>
                    {allLocations.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col} controlId="days">
                  <Form.Label>Date</Form.Label>
                  <Form.Select
                    value={pastDueFiltered ? 'PastDue' : filterModel?.date ?? ''}
                    onChange={(event) => handleChange('date', event)}
                  >
                    <option value="">All days</option>
                    <option value="-30">Past 30 days</option>
                    <option value="-60">Past 60 days</option>
                    <option value="-90">Past 90 days</option>
                    <option value="30">Upcoming 30 days</option>
                    <option value="60">Upcoming 60 days</option>
                    <option value="90">Upcoming 90 days</option>
                    <option value="PastDue">Past Due</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col} controlId="status">
                  <Form.Label>Status</Form.Label>
                  <Form.Select
                    value={filterModel?.status ?? ''}
                    onChange={(event) => handleChange('status', event)}
                  >
                    <option value="">All status</option>
                    {instrumentSettingsEventStatus.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col} controlId="eventType">
                  <Form.Label>Event Type</Form.Label>
                  <Form.Select
                    value={filterModel?.eventType ?? ''}
                    onChange={(event) => handleChange('eventType', event)}
                  >
                    <option value="">All types</option>
                    {instrumentSettingsEventType.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Filter Actions</Form.Label>
                  <br />
                  <div className="top-actions">
                    {renderMUIButtonWithPermissions(
                      'Save',
                      () => setOpenAddFilter(true),
                      SCREEN?.INSTRUMENT_EVENTS,
                      RULES.VIEW,
                      permissionData
                    )}
                    {renderMUIButtonWithPermissions(
                      'Load',
                      () => setOpenListFilter(true),
                      SCREEN?.INSTRUMENT_EVENTS,
                      RULES.VIEW,
                      permissionData
                    )}
                    {renderMUIButtonWithPermissions(
                      'Clear',
                      clearFilter,
                      SCREEN?.INSTRUMENT_EVENTS,
                      RULES.VIEW,
                      permissionData
                    )}
                  </div>
                  <ModalAddFilter
                    title={'Save Filter'}
                    data={editingFilter}
                    show={isOpenAddFilter}
                    handleClose={() => setOpenAddFilter(false)}
                    handleSave={handleSaveFilter}
                    disabled={
                      !hasPermission(permissionData, SCREEN?.INSTRUMENT_SETTINGS, RULES.UPDATE) ||
                      editingFilter?.fromMasterData
                    }
                  />
                  <ModalListEventFilter
                    title={'List Filter'}
                    data={eventFilters ?? []}
                    show={isOpenListFilter}
                    handleClose={() => setOpenListFilter(false)}
                    allLocations={allLocations}
                    instrumentSettingsEventType={instrumentSettingsEventType}
                    instrumentSettingsEventStatus={instrumentSettingsEventStatus}
                    handleApplyFilter={handleApplyFilter}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="search">
                  <Form.Label>Search</Form.Label>
                  <Form.Control
                    value={searchValue ?? ''}
                    onChange={(event) => setSearchValue(event.target.value.trim())}
                    placeholder="All fields"
                  />
                </Form.Group>
              </Row>
            </div>
          </div>
          <div className="grid-view" style={{ marginTop: '0px' }}>
            <h2>Events</h2>
            <CustomTable
              numberOfRows={50}
              data={events
                .map((row) => {
                  const dataInstrument = instruments.find((item) => item.id === row.instrumentName);
                  const dataStatus = instrumentSettingsEventStatus.find(
                    (item) => item.id === row.status
                  );
                  const eventType = instrumentSettingsEventType.find(
                    (item) => item.id === row.eventType
                  );
                  const location = allLocations.find(
                    (item) => item.id === dataInstrument?.location
                  );
                  return {
                    ...row,
                    location: location?.name,
                    instrumentId: dataInstrument?.id,
                    instrumentName: dataInstrument?.name,
                    status: dataStatus?.name,
                    eventType: eventType?.name
                  };
                })
                .filter((row) => {
                  if (searchValue) {
                    return (
                      row.eventNumber?.toString().includes(searchValue) ||
                      row.location?.toLowerCase().includes(searchValue.toLowerCase()) ||
                      row.instrumentName?.toLowerCase().includes(searchValue.toLowerCase()) ||
                      row.status?.toLowerCase().includes(searchValue.toLowerCase()) ||
                      row.eventType?.toLowerCase().includes(searchValue.toLowerCase()) ||
                      moment(row.dateDue?.seconds * 1000)
                        .format('MM/DD/YYYY')
                        .toLowerCase()
                        .includes(searchValue.toLowerCase()) ||
                      personnel
                        .filter((person) => ([row.responsibleParty] || []).includes(person.id))
                        .some((p) => p.name?.toLowerCase().includes(searchValue.toLowerCase())) ||
                      vendors
                        .filter((vendor) => (row.vendors || []).includes(vendor.id))
                        .some((p) =>
                          p.companyName?.toLowerCase().includes(searchValue.toLowerCase())
                        )
                    );
                  } else {
                    return true;
                  }
                })
                .filter((row) => {
                  if (!instrumentFilters) {
                    return true;
                  }
                  return instrumentFilters.includes(row.instrumentId);
                })
                .filter((event) => {
                  if (!pastDueFiltered) {
                    return true;
                  }
                  return (
                    event.dateDue?.seconds * 1000 < now.startOf('day').valueOf() &&
                    event.status !== 'Complete' &&
                    event.status !== 'In Progress'
                  );
                })}
              header={listEventColumnsFn({ vendors, personnel })}
              sort={{ sorting: { sortModel: [{ field: 'eventNumber', sort: 'desc' }] } }}
              rowHeight="auto"
              sx={{
                '.MuiDataGrid-cell': {
                  minHeight: '52px !important'
                },
                '& .MuiDataGrid-iconButtonContainer[aria-label*="filter"]': {
                  display: 'none'
                }
              }}
            />
          </div>
        </>
      ) : (
        <> </>
      )}
    </>
  );
};

export default TabEvents;
