import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import './PersonnelSection.scss';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { uniq } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { useAuth } from '../../util/auth';
import {
  createPersonnel,
  createSetupGroup,
  deletePersonnel,
  deleteSetupGroup,
  updatePersonnel,
  updateSetupGroup,
  useGetDepartments,
  useGetInstruments,
  useGetCompanies,
  useGetLaboratories,
  useGetPersonnel,
  useGetSetupGroups,
  updatePermisison,
  useGetPermisisonData
} from '../../util/db';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import CustomTable from '../Table/CustomTable';
import {
  SCREEN_PERMISSION,
  ROLES,
  UPDATE_TYPE,
  RULES,
  SCREEN,
  DEFAULT_PERMISSION
} from '../../util/Constant';
import {
  isAdmin,
  renderButtonWithPermissions,
  renderAuthButtonWithPermission,
  checkDelete,
  checkUpdate,
  checkReadOnly
} from '../../util/util';
import PermissionConfig from './PermissionConfig';
import PermissionEdit from './PermissionEdit';
import Snackbar from '@mui/material/Snackbar';
import { getFunctions, httpsCallable } from 'firebase/functions';
import firebaseApp from '../../util/firebase';
import { uniqueArrayByKey } from '../../util/util';

const functions = getFunctions(firebaseApp);
const getPermission = httpsCallable(functions, 'getPermission');

function PersonnelSection({ variation = '' }) {
  const auth = useAuth();
  const [currentPersonnel, setCurrentPersonnel] = useState([]);
  const { data: personnel } = useGetPersonnel(variation);
  const { data: instruments } = useGetInstruments(variation);
  const [currentSetupGroups, setCurrentSetupGroups] = useState([]);
  const { data: setupGroups } = useGetSetupGroups(variation);
  const { data: companies } = useGetCompanies(variation);
  const [personnelToUpdate, setPersonnelToUpdate] = useState([]);
  const [setupGroupsToUpdate, setSetupGroupsToUpdate] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [currentLaboratories, setCurrentLaboratories] = useState([]);
  const { data: laboratories } = useGetLaboratories(variation);
  const [currentDepartments, setCurrentDepartments] = useState([]);
  const { data: departments } = useGetDepartments(variation);
  const [showModal, setShowModal] = useState(false);
  const [showSetupGroupModal, setShowSetupGroupModal] = useState(false);
  const [currentInstruments, setCurrentInstruments] = useState([]);
  const [newPersonnel, setNewPersonnel] = useState({});
  const [permission, setPermission] = useState('');
  const [currentModalSetupGroup, setCurrentModalSetupGroup] = useState({
    locations: [],
    name: '',
    instruments: [],
    personnel: []
  });
  const [permissionData, setPermissionData] = useState(null);
  const [permissionContributor, setPermissionContributor] = useState(null);
  const [permissionTable, setPermissionTable] = useState([]);
  const [currentModalAvailableUsers, setCurrentModalAvailableUsers] = useState([]);
  const [
    // eslint-disable-next-line no-unused-vars
    currentModalAvailableInstruments,
    setCurrentModalAvailableInstruments
  ] = useState([]);
  const [currentModalAvailableLocations, setCurrentModalAvailableLocations] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [allPersonels, setAllPersonels] = useState([]);
  const [modalType, setModalType] = useState('');
  const [actions, setActions] = useState(['none']);
  const [hasEdit, setHasEdit] = useState(false);
  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const [showPermissionEditModal, setShowPermissionEditModal] = useState(false);
  const [permissionEdit, setPermissionEdit] = useState({});
  const [updateContributor, setUpdateContributor] = useState(false);
  const [snack, setSnack] = useState(false);
  const [message, setMessage] = useState('');
  useEffect(() => {
    (async () => {
      const data = await useGetPermisisonData();
      const contributorPermission = data[ROLES.CONTRIBUTION];
      if (contributorPermission) {
        setPermissionContributor(contributorPermission);
      }
    })();
  }, []);

  useEffect(() => {
    if (permissionContributor) {
      const data = [];
      Object.keys(permissionContributor).forEach((key) => {
        if (key !== 'permissionType') {
          data.push({
            tableName: key,
            create: permissionContributor[key].create,
            delete: permissionContributor[key].delete,
            update: permissionContributor[key].update,
            view:
              permissionContributor[key].view === undefined ? true : permissionContributor[key].view
          });
        }
      });
      setPermissionTable(data);
    }
  }, [permissionContributor]);

  useEffect(() => {
    if (permissionData) {
      const value = [];
      if (checkDelete(permissionData, SCREEN?.GROUP)) {
        value.push('delete');
      }
      if (checkUpdate(permissionData, SCREEN?.GROUP)) {
        setHasEdit(checkUpdate(permissionData, SCREEN?.GROUP));
        value.push('edit');
      }
      setActions(value);
    }
  }, [permissionData]);

  const statuses = [
    {
      id: 'Active',
      value: 'Active',
      label: 'Active'
    },
    {
      id: 'Inactive',
      value: 'Inactive',
      label: 'Inactive'
    }
  ];

  const permissions = [
    {
      id: 'Administrator',
      value: 'Administrator',
      label: 'Administrator'
    },
    {
      id: 'Contributor',
      value: 'Contributor',
      label: 'Contributor'
    },
    {
      id: 'Read-only',
      value: 'Read-only',
      label: 'Read-only'
    }
  ];

  const allLocationItem = { id: 'All', name: 'All', value: 'All', label: 'All' };
  const allPersonelItem = { id: 'All', name: 'All' };

  useEffect(() => {
    if (Array.isArray(companies) && companies.length > 0) {
      const updatedLocationsPersonnel = [allLocationItem];
      companies.forEach((company) => {
        const currentLocation = `${company.region} - ${company.area} - ${company.laboratory}`;
        const locationToPush = {
          name: currentLocation,
          id: currentLocation,
          value: currentLocation,
          label: currentLocation
        };
        const locationExists = updatedLocationsPersonnel.find(
          (location) => location.name === currentLocation
        );
        if (!locationExists) {
          updatedLocationsPersonnel.push(locationToPush);
        }
      });
      setCurrentModalAvailableLocations(updatedLocationsPersonnel);
      setAllLocations(updatedLocationsPersonnel);
    }
  }, [companies]);

  const savePersonnel = () => {
    const currentPersonnelToUpdate = personnelToUpdate;
    currentPersonnelToUpdate.forEach((p) => {
      updatePersonnel(p.id, p, variation);
    });
    setPersonnelToUpdate([]);
  };

  const handleCreatePersonnel = () => {
    createPersonnel(newPersonnel, variation);
    setShowModal(false);
  };

  const saveSetupGroups = () => {
    const currentSetupGroupsToUpdate = setupGroupsToUpdate;
    currentSetupGroupsToUpdate.forEach((setupGroup) => {
      updateSetupGroup(setupGroup.id, setupGroup, variation);
    });
    setSetupGroupsToUpdate([]);
  };

  const saveSetupGroup = () => {
    const instrumentsToAdd = [];
    currentModalSetupGroup.instrumentsAssigned.forEach((instrument) => {
      instrumentsToAdd.push(instrument.id);
    });
    currentModalSetupGroup.instruments = instrumentsToAdd;
    if (modalType === 'Add') {
      createSetupGroup(currentModalSetupGroup, variation);
    } else {
      updateSetupGroup(currentModalSetupGroup.id, currentModalSetupGroup, variation);
    }
    setShowSetupGroupModal(false);
    setPersonnelToUpdate([]);
  };

  const saveUpdatePermission = async () => {
    setUpdateContributor(true);
    const saveData = { ...permissionContributor };
    delete saveData.permissionType;
    const result = await updatePermisison(ROLES.CONTRIBUTION, saveData);

    if (result && !result.success) {
      getPermission()
        .then(async () => {
          await updatePermisison(ROLES.CONTRIBUTION, saveData);
          setUpdateContributor(false);
        })
        .catch((err) => {
          setSnack(true);
          setMessage(err);
          setUpdateContributor(false);
        });
    } else {
      setUpdateContributor(false);
    }
  };

  const addTable = () => {
    setShowPermissionModal(true);
  };

  const resetDefault = () => {
    const defaultData = DEFAULT_PERMISSION[ROLES.CONTRIBUTION];
    setPermissionContributor(defaultData);
  };

  const handleNewPersonnelModalOpen = () => {
    setNewPersonnel({
      name: '',
      email: '',
      phoneNumber: '',
      department: '',
      abbreviation: '',
      location: '',
      permission: '',
      status: '',
      notes: ''
    });
    setShowModal(true);
  };

  useEffect(() => {
    if (Array.isArray(instruments) && instruments.length > 0) {
      setCurrentInstruments(instruments);
      setCurrentModalAvailableInstruments(instruments);
    }
  }, [instruments]);

  const handleUserAdd = (e) => {
    let userToChange = [];
    let updatedCurrentModalAvailableUsers = [];
    if (e.target.value === 'All') {
      userToChange = currentModalAvailableUsers.filter((currentUser) => currentUser.id !== 'All');
      setCurrentModalAvailableUsers([]);
    } else {
      userToChange = currentModalAvailableUsers.filter(
        (currentUser) => currentUser.id === e.target.value
      );
      updatedCurrentModalAvailableUsers = currentModalAvailableUsers.filter(
        (user) => user.id !== e.target.value
      );

      if (
        updatedCurrentModalAvailableUsers.length === 1 &&
        updatedCurrentModalAvailableUsers[0].name === 'All'
      ) {
        setCurrentModalAvailableUsers([]);
      } else {
        updatedCurrentModalAvailableUsers = uniq(updatedCurrentModalAvailableUsers);
        setCurrentModalAvailableUsers(updatedCurrentModalAvailableUsers);
      }
    }
    const updatedCurrentModalSetupGroup = currentModalSetupGroup;
    updatedCurrentModalSetupGroup.personnel = [
      ...userToChange,
      ...updatedCurrentModalSetupGroup.personnel
    ];
    updatedCurrentModalSetupGroup.personnel = uniq(updatedCurrentModalSetupGroup.personnel);
    setCurrentModalSetupGroup(updatedCurrentModalSetupGroup);
    e.target.value = '';
  };

  const handleLocationAdd = (e) => {
    let locationToChange = [];
    let updatedAvailableLocations = [];
    if (e.target.value === 'All') {
      locationToChange = currentModalAvailableLocations.filter(
        (location) => location.name !== 'All'
      );
      updatedAvailableLocations = currentModalAvailableLocations.filter(
        (location) => location.name !== 'All'
      );
      setCurrentModalAvailableLocations([]);
    } else {
      locationToChange = currentModalAvailableLocations.filter(
        (currentLocation) => currentLocation.name === e.target.value
      );
      updatedAvailableLocations = currentModalAvailableLocations.filter(
        (location) => location.name !== e.target.value
      );
      if (updatedAvailableLocations.length === 1 && updatedAvailableLocations[0].name === 'All') {
        setCurrentModalAvailableLocations([]);
      } else {
        setCurrentModalAvailableLocations(updatedAvailableLocations);
      }
    }
    const updatedCurrentModalSetupGroup = currentModalSetupGroup;
    updatedCurrentModalSetupGroup.locations = [
      ...updatedCurrentModalSetupGroup.locations,
      ...locationToChange
    ];
    updatedCurrentModalSetupGroup.locations = uniq(updatedCurrentModalSetupGroup.locations);
    const updatedCurrentModalAvailableInstruments = [...currentModalAvailableInstruments];
    const instrumentsToAdd = currentModalAvailableInstruments.filter(
      (instrument) => instrument.location === e.target.value
    );
    instrumentsToAdd.forEach((instrument) => {
      updatedCurrentModalSetupGroup.instrumentsAssigned = [
        ...updatedCurrentModalSetupGroup.instrumentsAssigned,
        instrument
      ];
      const indexToRemove = updatedCurrentModalAvailableInstruments.findIndex(
        (availableInstrument) => availableInstrument.location === instrument.location
      );
      updatedCurrentModalAvailableInstruments.splice(indexToRemove, 1);
    });
    setCurrentModalAvailableInstruments(updatedCurrentModalAvailableInstruments);
    setCurrentModalSetupGroup(updatedCurrentModalSetupGroup);
    e.target.value = '';
  };

  const handleNameChange = (e) => {
    setCurrentModalSetupGroup({
      ...currentModalSetupGroup,
      name: e.target.value
    });
  };

  const handleLocationRemove = (row) => {
    const locationToChange = currentModalSetupGroup.locations.find(
      (currentLocation) => currentLocation.name === row.name
    );
    const updatedSetupGroupLocations = currentModalSetupGroup.locations.filter(
      (location) => location.name !== row.name && location.name !== 'All'
    );
    const updatedSetupGroup = currentModalSetupGroup;
    updatedSetupGroup.locations = updatedSetupGroupLocations;
    updatedSetupGroup.locations = uniq(updatedSetupGroup.locations);
    let updatedAvailableLocations = [...currentModalAvailableLocations];
    updatedAvailableLocations.push(allLocationItem);
    updatedAvailableLocations.push(locationToChange);
    updatedAvailableLocations = uniqueArrayByKey(updatedAvailableLocations, 'name');
    setCurrentModalAvailableLocations(updatedAvailableLocations);
    const updatedSetupGroupInstrumentsAssigned = [...updatedSetupGroup.instrumentsAssigned];
    const updatedCurrentModalAvailableInstruments = [...currentModalAvailableInstruments];
    const instrumentsToRemove = updatedSetupGroupInstrumentsAssigned.filter(
      (instrument) => instrument.location === row.name
    );
    instrumentsToRemove.forEach((instrument) => {
      updatedCurrentModalAvailableInstruments.push(instrument);
      const indexToRemove = updatedSetupGroupInstrumentsAssigned.findIndex(
        (availableInstrument) => availableInstrument.location === instrument.location
      );
      updatedSetupGroupInstrumentsAssigned.splice(indexToRemove, 1);
    });
    updatedSetupGroup.instrumentsAssigned = updatedSetupGroupInstrumentsAssigned;
    setCurrentModalAvailableInstruments(updatedCurrentModalAvailableInstruments);
    setCurrentModalSetupGroup(updatedSetupGroup);
  };

  const handleUserRemove = (row) => {
    const userToChange = currentModalSetupGroup.personnel.find(
      (currentUser) => currentUser.id === row.id
    );
    const updatedSetupGroupPersonnel = currentModalSetupGroup.personnel.filter(
      (user) => user.id !== row.id && user.name !== 'All'
    );
    const updatedSetupGroup = currentModalSetupGroup;
    updatedSetupGroup.personnel = updatedSetupGroupPersonnel;
    updatedSetupGroup.personnel = uniq(updatedSetupGroup.personnel);
    setCurrentModalSetupGroup(updatedSetupGroup);
    let updatedAvailableUsers = [...currentModalAvailableUsers];
    updatedAvailableUsers.push(allPersonelItem);
    updatedAvailableUsers.push(userToChange);
    updatedAvailableUsers = uniqueArrayByKey(updatedAvailableUsers, 'name');
    setCurrentModalAvailableUsers(updatedAvailableUsers);
  };

  const handleInstrumentRemove = (row) => {
    setCurrentModalSetupGroup({
      ...currentModalSetupGroup,
      instrumentsAssigned: currentModalSetupGroup.instrumentsAssigned.filter(
        (instrument) => instrument.id !== row.id
      )
    });
  };

  const renderOptions = (dropdownOptions) => {
    const rows = [];
    dropdownOptions.forEach((option) => {
      rows.push(
        <option key={uuidv4()} value={option.value}>
          {option.label}
        </option>
      );
    });
    return rows;
  };

  const renderPersonnelOptions = (dropdownOptions) => {
    const rows = [];
    dropdownOptions.forEach((option) => {
      rows.push(
        <option key={uuidv4()} value={option.id}>
          {option.name}
        </option>
      );
    });
    return rows;
  };

  const renderLocationOptions = (dropdownOptions) => {
    const rows = [];
    dropdownOptions.forEach((option) => {
      rows.push(
        <option key={uuidv4()} value={option.name}>
          {option.name}
        </option>
      );
    });
    return rows;
  };

  useEffect(() => {
    if (Array.isArray(personnel) && personnel.length > 0) {
      const matchingUser = personnel.find((person) => person.email === auth.user.email);
      if (matchingUser) {
        setPermission(matchingUser.permission);
        setPermissionData(auth.permissionData[matchingUser.permission]);
      }
      setCurrentPersonnel(personnel);
      setCurrentModalAvailableUsers([allPersonelItem, ...personnel]);
      setAllPersonels([allPersonelItem, ...personnel]);
    }
  }, [personnel]);

  useEffect(() => {
    if (Array.isArray(setupGroups) && setupGroups.length > 0) {
      const mySetupGroups = [...setupGroups];
      for (const group of mySetupGroups) {
        const locationsToDisplay = [];
        group.locations.forEach((location) => {
          locationsToDisplay.push(location.name);
        });
        group.locationsDisplayed = locationsToDisplay.toString().replaceAll(',', ', ');
        group.personnelNumber = group.personnel.length;
        group.instrumentsNumber = group.instruments.length;
        group.instrumentsAssigned = [];
        if (Array.isArray(currentInstruments) && currentInstruments.length > 0) {
          group.instruments.forEach((instrumentId) => {
            const existingInstrument = currentInstruments.find(
              (instrument) => instrument.id === instrumentId
            );
            if (existingInstrument) {
              group.instrumentsAssigned.push(existingInstrument);
            }
          });
        }
      }
      setCurrentSetupGroups(setupGroups);
    }
  }, [setupGroups && currentInstruments && currentPersonnel, setupGroups]);

  useEffect(() => {
    if (Array.isArray(departments) && departments.length > 0) {
      const departmentsDropdown = departments.map((department) => {
        const departmentToReturn = {
          id: department.id,
          value: department.name,
          label: department.name
        };

        return departmentToReturn;
      });
      setCurrentDepartments(departmentsDropdown);
    }
  }, [departments]);

  useEffect(() => {
    if (Array.isArray(laboratories) && laboratories.length > 0) {
      const laboratoriesDropdown = laboratories.map((laboratory) => {
        const updatedLocation = `${laboratory.region} - ${laboratory.area} - ${laboratory.name}`;
        const laboratoryToReturn = {
          id: laboratory.id,
          value: updatedLocation,
          label: updatedLocation
        };

        return laboratoryToReturn;
      });
      setCurrentLaboratories(laboratoriesDropdown);
    }
  }, [laboratories]);

  const instrumentModalTableColumns = [
    {
      field: 'name',
      headerName: 'Instrument Name',
      flex: 1,
      editable: true
    },
    {
      field: 'serialNumber',
      headerName: 'Serial Number',
      flex: 1,
      editable: true
    },
    {
      field: 'manufacturer',
      headerName: 'Manufacturer',
      flex: 1,
      editable: true
    },
    {
      field: 'model',
      headerName: 'Model',
      flex: 1,
      editable: true
    }
  ];

  const personnelTableColumns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 3
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 4
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone Number',
      flex: 3
    },
    {
      field: 'abbreviation',
      headerName: 'Abbreviation',
      flex: 2
    },
    {
      field: 'location',
      headerName: 'Location',
      editable: hasEdit,
      type: 'singleSelect',
      flex: 4,
      valueOptions: allLocations
    },
    {
      field: 'department',
      headerName: 'Department',
      editable: hasEdit,
      type: 'singleSelect',
      valueOptions: currentDepartments,
      flex: 2
    },
    {
      field: 'permission',
      headerName: 'Permission',
      editable: hasEdit,
      type: 'singleSelect',
      valueOptions: permissions,
      flex: 2
    },
    {
      field: 'status',
      headerName: 'Status',
      editable: hasEdit,
      type: 'singleSelect',
      valueOptions: statuses,
      flex: 1
    },
    {
      field: 'notes',
      headerName: 'Notes',
      flex: 2
    }
  ];

  const locationColumns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      editable: true
    }
  ];

  const modalUserColumns = [
    {
      field: 'name',
      headerName: 'Name',
      editable: true,
      flex: 1
    },
    {
      field: 'email',
      headerName: 'Email',
      editable: true,
      flex: 1
    },
    {
      field: 'status',
      headerName: 'Status',
      editable: true,
      flex: 1
    }
  ];

  const addGroup = () => {
    setModalType('Add');
    const setupGroupToAdd = {
      locations: [],
      name: '',
      instrumentsAssigned: [],
      personnel: []
    };
    setCurrentModalSetupGroup(setupGroupToAdd);
    setCurrentModalAvailableLocations(structuredClone(allLocations));
    setCurrentModalAvailableInstruments(structuredClone(currentInstruments));
    setCurrentModalAvailableUsers(structuredClone([allPersonelItem, ...currentPersonnel]));
    setShowSetupGroupModal(true);
  };

  const handleNewPersonnelNameChange = (e) => {
    setNewPersonnel({
      ...newPersonnel,
      name: e.target.value
    });
  };

  const handleEmailChange = (e) => {
    setNewPersonnel({
      ...newPersonnel,
      email: e.target.value
    });
  };

  const handlePhoneNumberChange = (e) => {
    setNewPersonnel({
      ...newPersonnel,
      phoneNumber: e.target.value
    });
  };

  const handleAbbreviationChange = (e) => {
    setNewPersonnel({
      ...newPersonnel,
      abbreviation: e.target.value
    });
  };

  const handleDepartmentChange = (e) => {
    setNewPersonnel({
      ...newPersonnel,
      department: e.target.value
    });
  };

  const handleStatusChange = (e) => {
    setNewPersonnel({
      ...newPersonnel,
      status: e.target.value
    });
  };

  const handlePermissionChange = (e) => {
    setNewPersonnel({
      ...newPersonnel,
      permission: e.target.value
    });
  };

  const handleNotesChange = (e) => {
    setNewPersonnel({
      ...newPersonnel,
      notes: e.target.value
    });
  };

  const handleLocationChange = (e) => {
    setNewPersonnel({
      ...newPersonnel,
      location: e.target.value
    });
  };

  const mapAssignments = (setupGroupToEdit) => {
    const updatedAvailableLocations = [];
    const currentAssignedLocations = setupGroupToEdit.locations;
    allLocations.forEach((location) => {
      const alreadyAssignedLocation = currentAssignedLocations.find(
        (assignedLocation) => assignedLocation.name === location.name
      );
      if (!alreadyAssignedLocation) {
        updatedAvailableLocations.push(location);
      }
    });
    const updatedAvailableUsers = [];
    const currentAssignedUsers = setupGroupToEdit.personnel;
    allPersonels.forEach((user) => {
      const alreadyAssignedUser = currentAssignedUsers.find(
        (assignedUser) => assignedUser.id === user.id
      );
      if (!alreadyAssignedUser) {
        updatedAvailableUsers.push(user);
      }
    });
    const updatedAvailableInstruments = [];
    const currentAssignedInstruments = setupGroupToEdit.instrumentsAssigned;
    currentModalAvailableInstruments.forEach((instrument) => {
      const alreadyAssignedInstrument = currentAssignedInstruments.find(
        (assignedInstrument) => assignedInstrument.id === instrument.id
      );
      if (!alreadyAssignedInstrument) {
        updatedAvailableInstruments.push(instrument);
      }
    });
    if (updatedAvailableUsers.length === 1 && updatedAvailableUsers[0].name === 'All') {
      setCurrentModalAvailableUsers([]);
    } else {
      setCurrentModalAvailableUsers(updatedAvailableUsers);
    }
    if (updatedAvailableLocations.length === 1 && updatedAvailableLocations[0].name === 'All') {
      setCurrentModalAvailableLocations([]);
    } else {
      setCurrentModalAvailableLocations(updatedAvailableLocations);
    }
    setCurrentModalAvailableInstruments(updatedAvailableInstruments);
    setCurrentModalSetupGroup(setupGroupToEdit);
  };

  const editGroup = (row) => {
    setModalType('Edit');
    const setupGroupToEdit = {
      id: row.id,
      name: row.name,
      instruments: row.instruments,
      instrumentsAssigned: row.instrumentsAssigned,
      locations: row.locations,
      personnel: row.personnel
    };
    mapAssignments(setupGroupToEdit);
    setShowSetupGroupModal(true);
  };

  const editPermission = (row) => {
    setPermissionEdit({
      name: row.tableName,
      table: {
        create: row.create,
        delete: row.delete,
        update: row.update,
        view: row.view
      }
    });
    setShowPermissionEditModal(true);
  };

  const options = {
    // pageStartIndex: 0,
    sizePerPage: 5,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true
  };

  const handleResults = (results) => {
    results.forEach((p, index) => {
      if (index > 0 && p[0] !== '') {
        const personnelToAdd = {
          name: p[0],
          email: p[1],
          phoneNumber: p[2],
          abbreviation: p[3],
          location: p[4],
          department: p[5],
          permission: p[6],
          status: p[7],
          notes: p[8]
        };
        createPersonnel(personnelToAdd);
      }
    });
  };

  const mapToObjectArray = (string) => {
    const arrayToReturn = string.split(',');
    const objectArrayToReturn = [];
    arrayToReturn.forEach((stringValue) => {
      objectArrayToReturn.push({ name: stringValue });
    });
    return objectArrayToReturn;
  };

  const mapToStringArray = (string) => {
    const arrayToReturn = string.split(',');
    return arrayToReturn;
  };

  const handleResultsSetupGroup = (results) => {
    results.forEach((setupGroup, index) => {
      if (index > 0 && setupGroup[0] !== '') {
        const setupGroupToAdd = {
          name: setupGroup[0],
          locations: mapToObjectArray(setupGroup[1]),
          personnel: mapToStringArray(setupGroup[2]),
          instruments: mapToStringArray(setupGroup[3])
        };
        createSetupGroup(setupGroupToAdd);
      }
    });
  };

  const groupColumns = [
    {
      field: 'name',
      headerName: 'Group',
      flex: 1
    },
    {
      field: 'locationsDisplayed',
      headerName: 'Locations',
      flex: 3
    },
    {
      field: 'personnelNumber',
      headerName: 'Personnel Assigned',
      flex: 2
    },
    {
      field: 'instrumentsNumber',
      headerName: 'Instruments',
      flex: 2
    },
    {
      field: 'edit',
      headerName: '',
      flex: 0.5,
      renderCell: ({ row }) => {
        if (!checkReadOnly(permissionData, SCREEN?.GROUP)) {
          return (
            <FontAwesomeIcon
              className="icon-button"
              onClick={() => {
                editGroup(row);
              }}
              size="sm"
              icon="edit"
            />
          );
        }
        return <></>;
      }
    }
  ];

  const permissionColumns = [
    {
      field: 'tableName',
      headerName: 'Permission Screen',
      valueGetter: ({ row }) => {
        const table = SCREEN_PERMISSION.find((item) => item.value === row.tableName);
        if (table) {
          return table.label;
        }
        return row.tableName;
      },
      flex: 2
    },
    {
      field: 'view',
      headerName: 'View',
      renderCell: ({ row }) => {
        const value = row.view === undefined ? true : row.view;
        if (value) {
          return 'Yes';
        }
        return 'No';
      },
      flex: 1
    },
    {
      field: 'create',
      headerName: 'Create',
      renderCell: ({ row }) => {
        const value = row.create || false;
        if (value) {
          return 'Yes';
        }
        return 'No';
      },
      flex: 1
    },
    {
      field: 'delete',
      headerName: 'Delete',
      renderCell: ({ row }) => {
        const value = row.delete || false;
        if (value) {
          return 'Yes';
        }
        return 'No';
      },
      flex: 1
    },
    {
      field: 'update',
      headerName: 'Update',
      renderCell: ({ row }) => {
        const value = row.update.type;
        switch (value) {
          case UPDATE_TYPE.CUSTOM:
            const fields = row.update.fieldAllow || 'Empty';
            return `Custom, Allow field update: [ ${fields} ]`;
          case UPDATE_TYPE.NONE:
            return 'None';
          case UPDATE_TYPE.ALL:
            return 'All';
        }
      },
      type: 'singleSelect',
      flex: 2
    },
    {
      field: 'edit',
      headerName: '',
      flex: 0.4,
      renderCell: ({ row }) => {
        if (permission === 'Administrator') {
          return (
            <FontAwesomeIcon
              className="icon-button"
              onClick={() => {
                editPermission(row);
              }}
              size="sm"
              icon="edit"
            />
          );
        }
        return <></>;
      }
    }
  ];

  return (
    <div>
      <div className="header">
        <h2>Personnel</h2>
      </div>
      <div className="underline-header">
        <hr />
      </div>
      <div className="personnel-information-container">
        <h3>Personnel Information</h3>
        <CustomTable
          data={currentPersonnel}
          header={personnelTableColumns}
          numberOfRows={10}
          action={isAdmin(permissionData) ? actions : ['none']}
          deleteRowData={(data) => {
            deletePersonnel(data.id, variation);
          }}
          updateRowData={(data) => {
            setPersonnelToUpdate([...personnelToUpdate, data]);
          }}
        />
      </div>
      <div className="data-button-container">
        <div className="left-buttons">
          <div>
            {/* <PrimaryButton
              clicked={() => handleNewPersonnelModalOpen()}
              text="Add Personnel"
            ></PrimaryButton> */}
            {renderButtonWithPermissions(
              'Add Personnel',
              () => handleNewPersonnelModalOpen(),
              SCREEN?.PERSONNEL,
              RULES.CREATE,
              permissionData
            )}
          </div>
          {renderAuthButtonWithPermission(
            handleResults,
            auth,
            currentPersonnel,
            permissionData,
            SCREEN?.PERSONNEL,
            RULES.CREATE
          )}
        </div>
        <div>
          {/* <PrimaryButton
            clicked={savePersonnel}
            text="Save data"
          ></PrimaryButton> */}
          {renderButtonWithPermissions(
            'Save data',
            () => savePersonnel(),
            SCREEN?.PERSONNEL,
            RULES.SAVE,
            permissionData
          )}
        </div>
      </div>
      <Modal size="xl" show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Personnel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="manage-data-modal">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Name</Form.Label>
              <Form.Control value={newPersonnel.name} onChange={handleNewPersonnelNameChange} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email</Form.Label>
              <Form.Control value={newPersonnel.email} onChange={handleEmailChange} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control value={newPersonnel.phoneNumber} onChange={handlePhoneNumberChange} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Abbreviation</Form.Label>
              <Form.Control value={newPersonnel.abbreviation} onChange={handleAbbreviationChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Location</Form.Label>
              <Form.Select value={newPersonnel.location} onChange={handleLocationChange}>
                <option value="">Assign a location</option>
                {renderLocationOptions(allLocations)}
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Label>Department</Form.Label>
              <Form.Select value={newPersonnel.department} onChange={handleDepartmentChange}>
                <option value="">Select department</option>
                {renderOptions(currentDepartments)}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Permission</Form.Label>
              <Form.Select onChange={handlePermissionChange}>
                <option value="">Select permission</option>
                <option value="Administrator">Administrator</option>
                <option value="Contributor">Contributor</option>
                <option value="Read-only">Read-only</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Status</Form.Label>
              <Form.Select onChange={handleStatusChange}>
                <option value="">Select status</option>
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Notes</Form.Label>
              <Form.Control value={newPersonnel.notes} onChange={handleNotesChange} />
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <PrimaryButton text="Cancel" clicked={() => setShowModal(false)} />
          <PrimaryButton text="Save" clicked={() => handleCreatePersonnel()} />
        </Modal.Footer>
      </Modal>
      <div className="setup-group">
        <h3>Groups</h3>
        <div className="setup-group-container">
          <div>
            <CustomTable
              data={currentSetupGroups}
              header={groupColumns}
              numberOfRows={10}
              action={actions.includes('delete') ? ['delete'] : ['none']}
              deleteRowData={(data) => {
                deleteSetupGroup(data.id, variation);
              }}
            />
          </div>
        </div>
        <div className="data-button-container">
          <div className="left-buttons">
            <div>
              {/* <PrimaryButton
                clicked={() => addGroup()}
                text="Add Group"
              ></PrimaryButton> */}
              {renderButtonWithPermissions(
                'Add Group',
                () => addGroup(),
                SCREEN?.GROUP,
                RULES.CREATE,
                permissionData
              )}
            </div>
            {renderAuthButtonWithPermission(
              handleResultsSetupGroup,
              auth,
              currentPersonnel,
              permissionData,
              SCREEN?.GROUP,
              RULES.CREATE
            )}
          </div>
          <div>
            {/* <PrimaryButton
              clicked={saveSetupGroups}
              text="Save data"
            ></PrimaryButton> */}
            {renderButtonWithPermissions(
              'Save data',
              () => saveSetupGroups(),
              SCREEN?.GROUP,
              RULES.SAVE,
              permissionData
            )}
          </div>
        </div>
        <Modal size="xl" show={showSetupGroupModal} onHide={() => setShowSetupGroupModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{modalType} Group</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="manage-data-modal">
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Group Name</Form.Label>
                <Form.Control onChange={handleNameChange} value={currentModalSetupGroup.name} />
              </Form.Group>
              <div className="setup-groups-header">
                <h3>Locations</h3>
              </div>
              <div>
                <div>Locations Assigned</div>
              </div>
              <div>
                <CustomTable
                  data={currentModalSetupGroup.locations}
                  header={locationColumns}
                  numberOfRows={20}
                  action={[...actions, 'edit']}
                  deleteRowData={(data) => {
                    handleLocationRemove(data);
                  }}
                  setData={(data) => {
                    setCurrentModalSetupGroup({ ...currentModalSetupGroup, locations: data });
                  }}
                />
              </div>
              <Form.Group>
                <Form.Label>Available Locations</Form.Label>
                <Form.Select onChange={handleLocationAdd} aria-label="Default select example">
                  <option value="">Assign a location</option>
                  {renderLocationOptions(currentModalAvailableLocations)}
                </Form.Select>
              </Form.Group>
              <div className="setup-groups-header">
                <h3>Users</h3>
              </div>
              <div>
                <div>Users Assigned</div>
              </div>
              <div>
                <CustomTable
                  data={currentModalSetupGroup.personnel}
                  header={modalUserColumns}
                  numberOfRows={20}
                  action={[...actions, 'edit']}
                  deleteRowData={(data) => {
                    handleUserRemove(data);
                  }}
                  setData={(data) => {
                    setCurrentModalSetupGroup({ ...currentModalSetupGroup, personnel: data });
                  }}
                />
              </div>
              <Form.Group>
                <Form.Label>Available Users</Form.Label>
                <Form.Select onChange={handleUserAdd} aria-label="Default select example">
                  <option value="">Assign a user</option>
                  {renderPersonnelOptions(currentModalAvailableUsers)}
                </Form.Select>
              </Form.Group>
              <div className="setup-groups-header">
                <h3>Instruments</h3>
              </div>
              <div>
                <div>Instruments Assigned</div>
              </div>
              <div>
                <CustomTable
                  data={currentModalSetupGroup.instrumentsAssigned}
                  header={instrumentModalTableColumns}
                  numberOfRows={5}
                  action={['edit', 'delete']}
                  deleteRowData={(data) => {
                    handleInstrumentRemove(data);
                  }}
                  setData={(data) => {
                    setCurrentModalSetupGroup({
                      ...currentModalSetupGroup,
                      instrumentsAssigned: data
                    });
                  }}
                />
              </div>
              {/* <Form.Group>
                <Form.Label>Available Instruments</Form.Label>
                <Form.Select onChange={handleInstrumentAdd} aria-label="Default select example">
                  <option>Assign an instrument</option>
                  {renderInstrumentOptions(currentModalAvailableInstruments)}
                </Form.Select>
              </Form.Group> */}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <PrimaryButton text="Cancel" clicked={() => setShowSetupGroupModal(false)} />
            <PrimaryButton text="Save" clicked={saveSetupGroup} />
          </Modal.Footer>
        </Modal>
      </div>

      {permission && permission === ROLES.ADMIN && (
        <div className="setup-group">
          <h3>Contribution Permission</h3>
          <div className="setup-group-container">
            <div>
              <CustomTable
                data={permissionTable}
                header={permissionColumns}
                deleteRowData={(data) => {
                  const updateList = { ...permissionContributor };
                  delete updateList[data.tableName];
                  setPermissionContributor(updateList);
                }}
                numberOfRows={10}
                action={actions.includes('delete') ? ['delete'] : ['none']}
                sort={{ sorting: { sortModel: [{ field: 'tableName', sort: 'asc' }] } }}
              />
            </div>
          </div>
          <div className="data-button-container">
            <div className="left-buttons">
              <div>
                {renderButtonWithPermissions(
                  'Add Permission',
                  () => addTable(),
                  SCREEN?.CONTRIBUTOR,
                  RULES.CREATE,
                  permissionData
                )}
              </div>
              <div>
                {renderButtonWithPermissions(
                  'Reset To Default',
                  () => resetDefault(),
                  SCREEN?.CONTRIBUTOR,
                  RULES.CREATE,
                  permissionData
                )}
              </div>
            </div>
            <div>
              {renderButtonWithPermissions(
                'Save data',
                () => saveUpdatePermission(),
                SCREEN?.CONTRIBUTOR,
                RULES.SAVE,
                permissionData,
                true,
                updateContributor
              )}
            </div>
          </div>
          <PermissionConfig
            showPermissionModal={showPermissionModal}
            setShowPermissionModal={setShowPermissionModal}
            permissionData={permissionContributor}
            setPermissionData={setPermissionContributor}
          />
          <PermissionEdit
            showPermissionEditModal={showPermissionEditModal}
            setShowPermissionEditModal={setShowPermissionEditModal}
            permissionEdit={permissionEdit}
            permissionData={permissionContributor}
            setPermissionData={setPermissionContributor}
          />
        </div>
      )}
      <Snackbar
        open={snack}
        autoHideDuration={2000}
        message={message}
        onClose={() => {
          setSnack(false);
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
    </div>
  );
}

export default PersonnelSection;
