import React, { useState } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import { updateChartSigDigits } from '../../util/db';
import {
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControl,
  InputLabel,
  FormHelperText,
  CircularProgress
} from '@mui/material';

const UpdateChartSigDigitsModal = ({ currentLocations, isShowModal, setShowModal }) => {
  const keyOptions = currentLocations.map((location) => location.name);
  const sigDigitOptions = [2, 3, 4, 5, 6, 7];
  const [formData, setFormData] = useState({
    keyToUpdate: [],
    chartSigDigits: 4,
    year: ''
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const validateForm = () => {
    let newErrors = {};
    let isValid = true;

    if (formData.keyToUpdate.length === 0) {
      newErrors.keyToUpdate = 'At least one Update Key is required.';
      isValid = false;
    }

    if (!sigDigitOptions.includes(Number(formData.chartSigDigits))) {
      newErrors.chartSigDigits = 'Please select a valid ChartSigDigits value.';
      isValid = false;
    }

    if (formData.year !== '' && (isNaN(formData.year) || formData.year < 1900)) {
      newErrors.year = 'Year must be a number greater than or equal to 1900';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleKeyToUpdateChange = (event) => {
    const selectedOptions = event.target.value;
    if (selectedOptions.includes('Select All')) {
      // Toggle "Select All" state
      setFormData({
        ...formData,
        keyToUpdate: formData.keyToUpdate.length === keyOptions.length ? [] : keyOptions
      });
    } else {
      setFormData({ ...formData, keyToUpdate: selectedOptions });
    }
  };

  const handleUpdate = async () => {
    if (!validateForm()) {
      return;
    }

    const formattedYear = formData.year ? parseInt(formData.year, 10) : null;

    try {
      setLoading(true); // Set loading to true when update starts
      await updateChartSigDigits({
        keyToUpdate: formData.keyToUpdate,
        chartSigDigits: Number(formData.chartSigDigits),
        year: formattedYear
      });

      alert('Update successful!');
      setFormData({ keyToUpdate: [], chartSigDigits: 4, year: '' });
      setErrors({});
      setShowModal(false);
    } catch (error) {
      console.error('Update failed:', error);
      alert('An error occurred. Please check the console.');
    } finally {
      setLoading(false); // Set loading to false when the update is done
    }
  };

  return (
    <>
      <Modal show={isShowModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Update Chart Sig Digits</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="formKeyToUpdate">
            <Form.Label>Update Keys</Form.Label>
            <FormControl error={!!errors.keyToUpdate} fullWidth>
              <InputLabel>Update Keys</InputLabel>
              <Select
                multiple
                value={formData.keyToUpdate}
                onChange={handleKeyToUpdateChange}
                renderValue={(selected) => selected.join(', ')}
              >
                <MenuItem value="Select All">
                  <Checkbox checked={formData.keyToUpdate.length === keyOptions.length} />
                  <ListItemText primary="Select All" />
                </MenuItem>
                {keyOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    <Checkbox checked={formData.keyToUpdate.includes(option)} />
                    <ListItemText primary={option} />
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors.keyToUpdate}</FormHelperText>
            </FormControl>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formChartSigDigits">
            <Form.Label>Chart axis significant digits</Form.Label>
            <Form.Control
              as="select"
              value={formData.chartSigDigits}
              onChange={(event) =>
                setFormData({ ...formData, chartSigDigits: Number(event.target.value) })
              }
              isInvalid={!!errors.chartSigDigits}
            >
              {sigDigitOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">{errors.chartSigDigits}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formYear">
            <Form.Label>Year</Form.Label>
            <Form.Control
              type="number"
              name="year"
              placeholder="Enter year (optional)"
              value={formData.year}
              onChange={handleInputChange}
              min="1900"
              isInvalid={!!errors.year}
            />
            <Form.Control.Feedback type="invalid">{errors.year}</Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleUpdate}
            disabled={loading} // Disable the button when loading
          >
            {loading ? <CircularProgress size={24} /> : 'Update'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UpdateChartSigDigitsModal;
