import React from 'react';
import { Button } from 'react-bootstrap';
import CustomTable from '../Table/CustomTable';

const PrintOverviewSection = ({
  currentControlStrategyConfigurations,
  chartStage,
  currentParameter,
  unit,
  summaryStatisticsColumns,
  summaryStatisticsPreview,
  pageSizeDefault,
  summaryStatisticsPreviewTwo,
  renderEditTable,
  renderPrintOverviewCharts,
  handleBackToOverview
}) => {
  return (
    <>
      <div>
        <div>
          <div>
            <div>
              <div>
                <div className="header">
                  <h2>Overview</h2>
                </div>
                <div className="underline-header">
                  <hr />
                </div>
                <div className="chart-status-section">
                  {currentControlStrategyConfigurations.controlStatus === 'No' ? (
                    <>
                      <div>
                        <h3>Chart Stage: Active</h3>
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        <h3>Chart Stage: {chartStage}</h3>
                      </div>
                    </>
                  )}
                </div>
                <div className="overview-chart-header">
                  <h4>
                    Summary Statistics: {currentParameter}, {unit}
                  </h4>
                </div>
                <div className="statistics-table">
                  <div>
                    <CustomTable
                      header={summaryStatisticsColumns}
                      data={summaryStatisticsPreview}
                      numberOfRows={pageSizeDefault}
                      hideFooter={true}
                    />
                  </div>
                  <div>
                    <CustomTable
                      header={summaryStatisticsColumns}
                      data={summaryStatisticsPreviewTwo}
                      numberOfRows={pageSizeDefault}
                      hideFooter={true}
                    />
                  </div>
                </div>
                <div className="overview-chart-header">
                  <h4>Results</h4>
                </div>
                <div>{renderEditTable()}</div>
              </div>
            </div>
            <div />
          </div>
        </div>
        {renderPrintOverviewCharts()}
      </div>
      <div className="print-button-container">
        <Button onClick={handleBackToOverview}>Back</Button>
      </div>
    </>
  );
};

export default PrintOverviewSection;
