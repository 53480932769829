import { formatInTimeZone } from 'date-fns-tz';
import { getSigDigitsUtil } from '../../util/util';

export const sortNumberComparator = (v1, v2) => {
  if (Number(v1) === 0 || Number.isNaN(Number(v1))) return 1;
  if (Number(v2) === 0 || Number.isNaN(Number(v2))) return -1;
  return Number(v1) - Number(v2);
};

export const formatNumberDisplay = (param) => {
  if (Number(param.value) === 0 || Number.isNaN(Number(param.value))) return '';
  return param.value;
};

export const getSPCAggregatedData = (currentDateResults) => {
  const numbers = currentDateResults.map(Number);
  const dateMin = Math.min(...numbers);
  const dateMax = Math.max(...numbers);
  const dateRange = dateMax - dateMin;
  const dateAverage = numbers.reduce((a, b) => a + b, 0) / numbers.length;
  return { dateRange, dateAverage };
};

export const getChartLines = (sortedAscLength) => {
  return [
    {
      yAxis: 'resultOmits',
      name: 'Data',
      color: '#32CD32',
      ewma: true,
      runRules: true,
      controlLimits: true,
      toleranceLimits: true
    },
    {
      yAxis: 'mean',
      name: 'Average',
      reference: sortedAscLength > 20,
      label: ``,
      color: '#FFBF00',
      ewma: true,
      runRules: true,
      controlLimits: true,
      toleranceLimits: true
    },
    {
      yAxis: 'sd3n',
      name: '+3 Std',
      label: '',
      color: '#FBCEB1',
      reference: true,
      ewma: true,
      runRules: true,
      controlLimits: false,
      toleranceLimits: false
    },
    {
      yAxis: 'sd3p',
      name: '-3 Std',
      label: '',
      color: '#FBCEB1',
      reference: true,
      ewma: true,
      runRules: true,
      controlLimits: false,
      toleranceLimits: false
    },
    {
      yAxis: 'userDefinedLTL',
      name: 'User Defined LTL',
      label: ``,
      color: 'red',
      reference: true,
      ewma: false,
      runRules: false,
      controlLimits: false,
      toleranceLimits: true
    },
    {
      yAxis: 'userDefinedUTL',
      name: 'User Defined UTL',
      label: ``,
      color: 'red',
      reference: true,
      ewma: false,
      runRules: false,
      controlLimits: false,
      toleranceLimits: true
    },
    {
      yAxis: 'userDefinedLCL',
      name: 'User Defined LCL',
      label: ``,
      color: 'red',
      reference: true,
      ewma: false,
      runRules: false,
      controlLimits: true,
      toleranceLimits: false
    },
    {
      yAxis: 'userDefinedUCL',
      name: 'User Defined UCL',
      label: ``,
      color: 'red',
      reference: true,
      ewma: false,
      runRules: false,
      controlLimits: true,
      toleranceLimits: false
    }
  ];
};

export const separateFullKey = (fullKey) => {
  return {
    location: fullKey.split('|')[0] || '',
    type: fullKey.split('|')[1] || '',
    product: fullKey.split('|')[2] || '',
    method: fullKey.split('|')[3] || '',
    parameter: fullKey.split('|')[4] || ''
  };
};

export const extractFullKeys = (data) => {
  const fullKeys = [];

  data.forEach((indexItem) => {
    indexItem.type.forEach((typeItem) => {
      typeItem.product.forEach((productItem) => {
        productItem.method.forEach((methodItem) => {
          methodItem.parameter.forEach((paramItem) => {
            const fullKey = `${indexItem.name}|${typeItem.name}|${productItem.name}|${methodItem.name}|${paramItem.name}`;
            fullKeys.push(fullKey);
          });
        });
      });
    });
  });

  return fullKeys;
};

export const convertToDailyData = (chartData, tz = 'UTC', controlStrategyConfigurations = {}) => {
  const timeZone = tz || 'UTC';
  let lastDate = null;
  let currentDateResults = [];
  const dateValues = {};
  const nSamplesPerDay = Number(controlStrategyConfigurations?.nSamplesPerDay || 3);

  const renderSigDigits = (stringValue) => {
    return getSigDigitsUtil(stringValue, controlStrategyConfigurations.chartSigDigits);
  };

  if (nSamplesPerDay === 1) {
    return chartData.map((r) => {
      return {
        ...r,
        dateAverage: renderSigDigits(r.resultOmits),
        dateRange: 0
      };
    });
  }

  for (let i = 0; i < chartData.length; i += 1) {
    const doc = chartData[i];
    const priorDoc = i > 0 ? chartData[i - 1] : null;
    // we aggregate by date
    const test = doc.dateTime ? formatInTimeZone(doc.dateTime, timeZone, 'yyyy-MM-dd') : doc.date;
    if (lastDate !== test) {
      // new date, so add to aggGrid if not first
      if (priorDoc) {
        dateValues[lastDate] = { ...priorDoc, ...getSPCAggregatedData(currentDateResults) };
      }
      // and clear:
      currentDateResults = [];
    }
    // add to results:
    currentDateResults.push(doc.result);
    lastDate = test;
  }
  // add to results:
  if (lastDate) {
    dateValues[lastDate] = {
      ...chartData[chartData.length - 1],
      ...getSPCAggregatedData(currentDateResults)
    };
  }

  // write to dailyData
  const dates = Object.keys(dateValues).sort();
  const dailyData = [];
  for (const date of dates) {
    dailyData.push({
      date,
      ...dateValues[date]
    });

    // and write data points back to individual dates
    for (let i = 0; i < chartData.length; i += 1) {
      const cd = chartData[i];
      if (
        date ===
        (cd.dateTime ? formatInTimeZone(new Date(cd.dateTime), timeZone, 'yyyy-MM-dd') : cd.date)
      ) {
        cd.dateAverage = renderSigDigits(dateValues[date].dateAverage);
        cd.dateRange = renderSigDigits(dateValues[date].dateRange);
      }
    }
  }
  return dailyData;
};
