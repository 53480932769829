import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import CustomTable from '../Table/CustomTable';
import { isAdmin } from '../../util/util';
import { SCREEN, RULES } from '../../util/Constant';

const OverviewSection = ({
  localSearchFinished,
  renderResultsSection,
  renderEditTable,
  isEverFlags,
  permissionData,
  renderButton,
  handleResetEverFlags,
  loadingResetEverFlags,
  exportToCSV,
  showEditModal,
  setShowEditModal,
  renderModalEditTable,
  featureFlags,
  saveEditModal,
  hasActiveEdits,
  renderOverviewCharts,
  activeTab,
  chartDataComplete,
  handlePrintClick
}) => {
  return (
    <>
      <div className="chart-section-overall-container">
        <div className="results-chart-container">
          <div>
            <div>
              {localSearchFinished && (
                <div>
                  {renderResultsSection}
                  <div>
                    {renderEditTable()}
                    <div className="edit-button">
                      {isEverFlags &&
                        isAdmin(permissionData) &&
                        renderButton(
                          'Reset EverFlags',
                          handleResetEverFlags,
                          SCREEN?.DATA,
                          RULES.SAVE,
                          true,
                          loadingResetEverFlags
                        )}
                      {renderButton('Export to CSV', () => exportToCSV(), SCREEN?.DATA, RULES.SAVE)}
                      {renderButton(
                        'Edit Data',
                        () => setShowEditModal(true),
                        SCREEN?.DATA,
                        RULES.SAVE
                      )}
                    </div>
                  </div>
                </div>
              )}
              {!localSearchFinished && (
                <div className="please-select-container">
                  Please select a location, method, sample name, instrument, and parameter
                </div>
              )}
            </div>
            <div />
            <div className="modal-90w">
              <Modal
                dialogClassName="modal-90w"
                show={showEditModal}
                onHide={() => setShowEditModal(false)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Edit Table</Modal.Title>
                </Modal.Header>
                <Modal.Body>{renderModalEditTable(featureFlags)}</Modal.Body>
                <Modal.Footer>
                  <PrimaryButton
                    text="Save"
                    clicked={() => saveEditModal()}
                    disabled={hasActiveEdits}
                  />
                  <PrimaryButton text="Cancel" clicked={() => setShowEditModal(false)} />
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </div>
        {renderOverviewCharts(activeTab, chartDataComplete)}
      </div>
      {localSearchFinished ? (
        <div className="print-button-container">
          <Button onClick={handlePrintClick}>Print</Button>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default OverviewSection;
