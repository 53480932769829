import { getApp, getApps, initializeApp } from 'firebase/app';

import { getConfiguration } from '../util/config';

export function getAppConfig() {
  let app;
  if (getApps().length === 0) {
    const appConfig = getConfiguration(window.location.hostname);
    app = initializeApp(appConfig);
  } else {
    // Use existing app if already initialized
    app = getApp();
  }
  return app;
}

export function getCurrentFeatureFlags() {
  const currentProjectId = getAppConfig().options.projectId;
  let featureFlags = [];
  switch (currentProjectId) {
    case 'fuelsqcv2-arteco':
      featureFlags = ['nonUs', 'tz=Europe/Brussels'];
      break;
    case 'fuelsqcv2-bapco':
      featureFlags = ['tz=Asia/Bahrain'];
      break;
    case 'fuelsqcv2-bluetide':
      featureFlags = ['tz=America/Chicago'];
      break;
    case 'fuelsqcv2-buc':
      featureFlags = ['tz=America/New_York'];
      break;
    case 'fuelsqcv2-colonial':
      featureFlags = ['tz=America/New_York'];
      break;
    case 'fuelsqcv2-ctl':
      featureFlags = ['tz=America/New_York'];
      break;
    case 'fuelsqcv2-cvr':
      featureFlags = ['tz=America/Chicago'];
      break;
    case 'fuelsqcv2-dev':
      featureFlags = ['tz=America/Chicago'];
      break;
    case 'fuelsqcv2-eurofins':
      featureFlags = ['tz=America/Chicago'];
      break;
    case 'fuelsqcv2-ggpetro':
      featureFlags = ['tz=America/Denver'];
      break;
    case 'fuelsqcv2-hep':
      featureFlags = ['tz=America/Denver'];
      break;
    case 'fuelsqcv2-hunt':
      featureFlags = [
        'lcl-data-entry-columns',
        'newest-default-sort',
        'config-download-button',
        'tz=America/Chicago'
      ];
      break;
    case 'fuelsqcv2-ies':
      featureFlags = ['tz=Pacific/Honolulu'];
      break;
    case 'fuelsqcv2-lupton':
      featureFlags = ['tz=America/Denver'];
      break;
    case 'fuelsqcv2-mmp':
      featureFlags = ['tz=America/Chicago'];
      break;
    case 'fuelsqcv2-propet':
      featureFlags = ['tz=America/Phoenix'];
      break;
    case 'fuelsqcv2-ser':
      featureFlags = ['tz=America/Chicago'];
      break;
    case 'fuelsqcv2-spl':
      featureFlags = ['tz=America/Chicago'];
      break;

    /* // for demoing non-US features in demo:
    case 'fuelsqcv2-demo':
      featureFlags = ['nonUs'];
      break;
    */
    default:
      featureFlags = ['tz=America/Chicago'];
      break;
  }
  return featureFlags;
}

export const currentLogo = getAppConfig().options.logo
  ? getAppConfig().options.logo
  : '/ASTM-Insight-SQC.png';

export const currentLogoAncillary = getAppConfig().options.logoAncillary
  ? getAppConfig().options.logoAncillary
  : '/SRP-Insight-SQC.png';

const firebaseApp = getAppConfig();

export default firebaseApp;
