import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { TextField, ButtonGroup } from '@mui/material';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import CustomTable from '../Table/CustomTable';
import { checkReadOnly, renderButtonWithPermissions } from '../../util/util';
import { SCREEN, RULES } from '../../util/Constant';
import UpdateChartSigDigitsModal from '../Modal/UpdateChartSigDigitsModal';

const ConfigurationSection = ({
  permissionData,
  radioArchiveValue,
  configurationData,
  configurationTableColumns,
  currentControlStrategyConfigurations,
  parameterSecondaryData,
  parameterSecondaryDataColumns,
  controlStrategyData,
  controlStrategyConfigurationColumns,
  bottomTPIData,
  bottomTPIColumns,
  methodSpecificData,
  methodSpecificColumns,
  methodSecondaryData,
  methodSecondaryDataColumns,
  tenNinetyStartDate,
  tenNinetyEndDate,
  handleTenNinetyDateChange,
  currentSampleSchedules,
  sampleScheduleColumnsNew,
  handleAddSchedule,
  showScheduleModal,
  setShowScheduleModal,
  scheduleModalType,
  currentModalSchedule,
  handleDescriptionChange,
  handleFrequencyChange,
  handleTimeChange,
  renderTimesOptions,
  handleStartDateChange,
  handleTimezoneChange,
  renderTimezoneOptions,
  handleSaveSchedule,
  assignedSamplesData,
  assignedSamplesTableColumns,
  archiveData,
  archiveColumns,
  runUnArchiveFunctions,
  runDataFunction,
  setChartLoading,
  checkedCombinedInitialAssessment,
  controlStatusData,
  controlStatusColumns,
  isDeleteAllData,
  setIsDeleteAllData,
  openCofirmDeleteAllData,
  setOpenCofirmDeleteAllData,
  confirmationInput,
  handleConfirmationInputChange,
  error,
  isDeleteDisabled,
  chartLoading,
  handleConfirmDelete,
  config,
  showUpdateChartSigDigitsModal,
  setShowUpdateChartSigDigitsModal,
  currentLocations,
  saveConfigurations,
  renderButton,
  updateStateConfigurations
}) => {
  const readOnly = checkReadOnly(permissionData, SCREEN?.CONFIG) || radioArchiveValue === 'Archive';

  const limitToleranceData = [
    {
      id: 'Lower-Tolerance-Limit',
      variable: 'Lower Tolerance Limit',
      approach: currentControlStrategyConfigurations.lowerToleranceLimitApproach,
      value: currentControlStrategyConfigurations.lowerToleranceLimit
    },
    {
      id: 'Upper-Tolerance-Limit',
      variable: 'Upper Tolerance Limit',
      approach: currentControlStrategyConfigurations.upperToleranceLimitApproach,
      value: currentControlStrategyConfigurations.upperToleranceLimit
    }
  ];

  const limitToleranceDataColumns = (editable = true) => [
    {
      field: 'variable',
      headerName: 'Variable',
      flex: 1,
      editable: false,
      sortable: false,
      headerStyle: () => {
        return { width: '250px' };
      }
    },
    {
      field: 'approach',
      headerName: 'Approach',
      flex: 1,
      editable: editable,
      sortable: false,
      type: 'singleSelect',
      valueOptions: (params) => {
        const utlDropdown = [
          {
            value: 'Use Upper Tolerance Limit (1090)',
            label: 'Use Upper Tolerance Limit (1090)'
          },
          { value: 'Default', label: 'Default' }
        ];
        const ltlDropdown = [
          {
            value: 'Use Lower Tolerance Limit (1090)',
            label: 'Use Lower Tolerance Limit (1090)'
          },
          { value: 'Default', label: 'Default' }
        ];
        switch (params.row.variable) {
          case 'Lower Tolerance Limit':
            return ltlDropdown;
          case 'Upper Tolerance Limit':
            return utlDropdown;
          default:
            return [];
        }
      }
    },
    {
      field: 'value',
      headerName: 'Value',
      flex: 1,
      editable: editable,
      sortable: false
    }
  ];

  const limitControlData = [
    {
      id: 'Lower-Control-Limit',
      variable: 'Lower Control Limit',
      approach: currentControlStrategyConfigurations.lowerControlLimitApproach,
      value: currentControlStrategyConfigurations.lowerControlLimit
    },
    {
      id: 'Upper-Control-Limit',
      variable: 'Upper Control Limit',
      approach: currentControlStrategyConfigurations.upperControlLimitApproach,
      value: currentControlStrategyConfigurations.upperControlLimit
    }
  ];

  const limitControlDataColumns = (editable = true) => [
    {
      field: 'variable',
      headerName: 'Variable',
      flex: 1,
      editable: false,
      sortable: false,
      headerStyle: () => {
        return { width: '250px' };
      }
    },
    {
      field: 'approach',
      headerName: 'Approach',
      flex: 1,
      editable: editable,
      sortable: false,
      type: 'singleSelect',
      valueOptions: (params) => {
        const uclDropdown = [
          { value: 'Default', label: 'Default' },
          {
            value: 'Use Repeatability-based limit',
            label: 'Use Repeatability-based limit'
          },
          {
            value: 'Custom',
            label: 'Custom'
          }
        ];
        const lclDropdown = uclDropdown;
        switch (params.row.variable) {
          case 'Lower Control Limit':
            return lclDropdown;
          case 'Upper Control Limit':
            return uclDropdown;
          default:
            return [];
        }
      }
    },
    {
      field: 'value',
      headerName: 'Value',
      flex: 1,
      editable: editable,
      sortable: false
    }
  ];

  return (
    <div>
      <div className="configuration-table-container">
        <div className="left-configuration-tables">
          <div>
            <h3>Control Strategy configurations</h3>
          </div>
          <div>
            <CustomTable
              data={configurationData}
              header={configurationTableColumns(!readOnly)}
              hideFooter={true}
              action={readOnly ? ['none'] : ['edit']}
              actionPosition={'latest'}
              updateRowData={(data) => {
                updateStateConfigurations(data, data.value);
              }}
            />
          </div>
          <div>
            <CustomTable
              data={limitToleranceData}
              hideFooter={true}
              action={readOnly ? ['none'] : ['edit']}
              header={limitToleranceDataColumns(!readOnly)}
              actionPosition={'latest'}
              updateRowData={(data) => {
                updateStateConfigurations(data, data.approach);
              }}
            />
          </div>
          <div>
            <CustomTable
              data={limitControlData}
              hideFooter={true}
              action={readOnly ? ['none'] : ['edit']}
              header={limitControlDataColumns(!readOnly)}
              actionPosition={'latest'}
              updateRowData={(data) => {
                updateStateConfigurations(data, data.approach);
              }}
            />
          </div>
          <div>
            <CustomTable
              data={parameterSecondaryData}
              header={parameterSecondaryDataColumns(!readOnly)}
              hideFooter={true}
              action={readOnly ? ['none'] : ['edit']}
              numberOfRows={10}
              actionPosition={'latest'}
              updateRowData={(data) => {
                updateStateConfigurations(data, data.value);
              }}
            />
          </div>
          <div>
            <CustomTable
              data={controlStrategyData}
              header={controlStrategyConfigurationColumns(!readOnly)}
              hideFooter={true}
              action={readOnly ? ['none'] : ['edit']}
              numberOfRows={20}
              actionPosition={'latest'}
              updateRowData={(data) => {
                updateStateConfigurations(data, data.value);
              }}
            />
          </div>
          <div>
            <CustomTable
              data={bottomTPIData}
              header={bottomTPIColumns(!readOnly)}
              hideFooter={true}
              action={readOnly ? ['none'] : ['edit']}
              numberOfRows={20}
              actionPosition={'latest'}
              updateRowData={(data) => {
                updateStateConfigurations(data, data.value);
              }}
            />
          </div>
        </div>
        <div className="right-configuration-tables">
          <div>
            <h3>Chart Group configurations</h3>
          </div>
          <div>
            <CustomTable
              data={methodSpecificData}
              header={methodSpecificColumns(!readOnly)}
              hideFooter={true}
              action={readOnly ? ['none'] : ['edit']}
              numberOfRows={20}
              actionPosition={'latest'}
              updateRowData={(data) => {
                updateStateConfigurations(data, data.value);
              }}
            />
          </div>
          <div>
            <CustomTable
              data={methodSecondaryData}
              header={methodSecondaryDataColumns(!readOnly)}
              hideHeader={true}
              hideFooter={true}
              action={readOnly ? ['none'] : ['edit']}
              numberOfRows={20}
              actionPosition={'latest'}
              updateRowData={(data) => {
                updateStateConfigurations(data, data.value);
              }}
            />
          </div>
          <div style={{ marginTop: '-16px', marginBottom: '16px' }}>
            <div className="ten-ninety-date-container">
              <input readOnly value="1090 Start Date" className="control-limit-entry" />
              <Form.Control
                disabled={readOnly}
                style={{ borderRadius: '0px' }}
                value={tenNinetyStartDate}
                onChange={(e) => handleTenNinetyDateChange('start', e.target.value)}
                type="date"
              />
              <input readOnly value="1090 End Date" className="control-limit-entry" />
              <Form.Control
                disabled={readOnly}
                style={{ borderRadius: '0px' }}
                value={tenNinetyEndDate}
                onChange={(e) => handleTenNinetyDateChange('end', e.target.value)}
                type="date"
              />
            </div>
          </div>
          <div>
            <h3 className="control-strategy">Sample Schedule</h3>
          </div>
          <div>
            <CustomTable
              data={currentSampleSchedules}
              header={sampleScheduleColumnsNew(readOnly)}
              hideFooter={true}
              numberOfRows={20}
            />
          </div>
          {!readOnly ? (
            <div className="add-schedule-container">
              <Button onClick={handleAddSchedule}>Add Schedule</Button>
            </div>
          ) : (
            <></>
          )}

          <div className="modal-90w">
            <Modal
              dialogClassName="modal-90w"
              show={showScheduleModal}
              onHide={() => setShowScheduleModal(false)}
            >
              <Modal.Header closeButton>
                <Modal.Title>{scheduleModalType} Schedule</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="manage-data-modal">
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      onChange={handleDescriptionChange}
                      value={currentModalSchedule.description}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Frequency</Form.Label>
                    <Form.Select
                      onChange={handleFrequencyChange}
                      value={currentModalSchedule.frequency}
                    >
                      <option value="None">None</option>
                      <option value="Daily">Daily</option>
                      <option value="Weekly">Weekly</option>
                      <option value="Monthly">Monthly</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Time</Form.Label>
                    <Form.Select onChange={handleTimeChange} value={currentModalSchedule.time}>
                      {renderTimesOptions()}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Starting Date</Form.Label>
                    <DatePicker
                      selected={currentModalSchedule.startingDate}
                      onChange={(date) => handleStartDateChange(date)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Timezone</Form.Label>
                    <Form.Select
                      onChange={handleTimezoneChange}
                      value={currentModalSchedule.timezone}
                    >
                      <option value={moment.tz.guess()}>{moment.tz.guess()}</option>
                      {renderTimezoneOptions()}
                    </Form.Select>
                  </Form.Group>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={() => setShowScheduleModal(false)}>Cancel</Button>
                <Button onClick={handleSaveSchedule}>Save</Button>
              </Modal.Footer>
            </Modal>
          </div>
          <div>
            <h3 className="control-strategy">Assigned Samples</h3>
          </div>
          <div>
            <CustomTable
              data={assignedSamplesData}
              header={assignedSamplesTableColumns(!readOnly)}
              hideFooter={true}
              action={readOnly ? ['none'] : ['edit']}
              numberOfRows={20}
              actionPosition={'latest'}
              updateRowData={(data) => {
                updateStateConfigurations(data, data.sample);
              }}
            />
          </div>
          <div>
            <h3 className="control-strategy">Archive</h3>
          </div>
          <div>
            <CustomTable
              data={archiveData}
              header={archiveColumns(!readOnly)}
              hideFooter={true}
              hideHeader={true}
              action={readOnly ? ['none'] : ['edit']}
              numberOfRows={20}
              actionPosition={'latest'}
              updateRowData={(data) => {
                updateStateConfigurations(data, data.value);
              }}
            />
            {currentControlStrategyConfigurations?.archive === 'Yes' && (
              <div className="restore-archive-container">
                {renderButtonWithPermissions(
                  'Restore Archive',
                  async () => {
                    setChartLoading(true);

                    let tmp = currentControlStrategyConfigurations.fullKey.split('|');
                    const request = {
                      location: tmp[0],
                      method: tmp[1],
                      sampleName: tmp[2],
                      instrument: tmp[3],
                      parameter: tmp[4]
                    };
                    const response = await runUnArchiveFunctions(request);
                    const { data } = response || {};
                    if (data.success && response.data.fullKey) {
                      let key = response.data.fullKey.split('|');
                      const runDataObject = {
                        location: key[0],
                        method: key[1],
                        instrument: key[2],
                        sampleName: key[3],
                        parameter: key[4]
                      };
                      await runDataFunction(runDataObject);
                    }
                    setChartLoading(false);
                    window.location.reload();
                  },
                  SCREEN?.CONFIG,
                  RULES.SAVE,
                  permissionData,
                  true
                )}
              </div>
            )}
          </div>
          <div>
            <h3 className="control-strategy">Combined Initial Assessment</h3>
          </div>
          <div>
            <CustomTable
              data={[
                {
                  id: 'combinedInitialAssessment',
                  variable: 'Combined Initial Assessment',
                  value: checkedCombinedInitialAssessment ? 'Yes' : 'No'
                }
              ]}
              header={controlStatusColumns(!readOnly)}
              hideFooter={true}
              hideHeader={true}
              action={readOnly ? ['none'] : ['edit']}
              numberOfRows={20}
              actionPosition={'latest'}
              updateRowData={(data) => {
                updateStateConfigurations(data, data.value);
              }}
            />
          </div>
          <div>
            <h3 className="control-strategy">Control Status</h3>
          </div>
          <div>
            <CustomTable
              data={controlStatusData}
              header={controlStatusColumns(!readOnly)}
              hideFooter={true}
              hideHeader={true}
              action={readOnly ? ['none'] : ['edit']}
              numberOfRows={20}
              actionPosition={'latest'}
              updateRowData={(data) => {
                updateStateConfigurations(data, data.value);
              }}
            />
          </div>
          {!readOnly && (
            <div className="delete-all-container">
              <h3 className="control-strategy">Delete All Data</h3>
              {!isDeleteAllData && (
                <div className="add-schedule-container">
                  <Button onClick={() => setIsDeleteAllData(true)}>Delete All Data</Button>
                </div>
              )}
              {isDeleteAllData && (
                <div className="add-schedule-container">
                  <Button onClick={() => setIsDeleteAllData(false)}>Close Delete All Data</Button>
                </div>
              )}
            </div>
          )}

          {isDeleteAllData && (
            <div>
              <CustomTable
                data={[
                  {
                    id: 'delete',
                    variable: 'Delete All Data',
                    value: openCofirmDeleteAllData ? 'Yes' : 'No'
                  }
                ]}
                header={controlStatusColumns(!readOnly)}
                hideFooter={true}
                hideHeader={true}
                action={readOnly ? ['none'] : ['edit']}
                numberOfRows={20}
                actionPosition={'latest'}
                updateRowData={(data) => {
                  if (data.value === 'Yes') {
                    setOpenCofirmDeleteAllData(true);
                  } else {
                    setOpenCofirmDeleteAllData(false);
                  }
                  return;
                }}
              />
            </div>
          )}

          {openCofirmDeleteAllData && isDeleteAllData && (
            <>
              <p style={{ color: 'red' }}>
                Type <strong>'delete'</strong> below to confirm the deletion of all data.
              </p>
              <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                <TextField
                  fullWidth
                  placeholder="Type 'delete' to confirm"
                  value={confirmationInput}
                  onChange={handleConfirmationInputChange}
                  error={!!error}
                  helperText={error}
                  size="small"
                />
                <Button onClick={handleConfirmDelete} disabled={isDeleteDisabled || chartLoading}>
                  Confirm
                </Button>
              </div>
              <br />
            </>
          )}
          {!readOnly && config.useUpdateChartSigDigits && (
            <div className="control-strategy-container">
              <h3 className="control-strategy">Update Chart Axis Significant Digits</h3>
              <div className="add-schedule-container">
                <Button variant="primary" onClick={() => setShowUpdateChartSigDigitsModal(true)}>
                  Open Update
                </Button>
              </div>
            </div>
          )}
          <UpdateChartSigDigitsModal
            currentLocations={currentLocations}
            isShowModal={showUpdateChartSigDigitsModal}
            setShowModal={setShowUpdateChartSigDigitsModal}
          />
        </div>
      </div>
      <div className="button-container">
        {renderButton('Save', () => saveConfigurations(), SCREEN?.CONFIG, RULES.SAVE)}
      </div>
    </div>
  );
};

export default ConfigurationSection;
