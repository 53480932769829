import React from 'react';
import Meta from '../components/Meta';
import { requireAuth } from '../util/auth';
import SPCDashboardSection from '../components/SPCDashboardSection/SPCDashboardSection';

function SPCDashboardPage() {
  return (
    <>
      <Meta title="Dashboard" />
      <SPCDashboardSection
        bg="white"
        textColor="dark"
        bgImage=""
        bgImageOpacity={1}
        title="Dashboard"
        subtitle=""
      />
    </>
  );
}

export default requireAuth(SPCDashboardPage);
