import React, { useContext, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import qs from 'qs';
import { formatInTimeZone } from 'date-fns-tz';
import { uniq } from 'lodash';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import createTrend from 'trendline';
import moment from 'moment-timezone';
import Tooltip from '@mui/material/Tooltip';
import { getConfiguration } from '../util/config';

import {
  defaultColumns,
  defaultColumnsExtend,
  defaultColumnsUsExtend,
  oocFlagColumns,
  wFlagColumns
} from '../components/Columns';
import './chart.scss';
import { getCurrentFeatureFlags } from '../util/firebase';
import { requireAuth, useAuth } from '../util/auth';
import {
  arrayAverage,
  calculateHistogram,
  randomDarkColor,
  firebaseClean,
  getSigDigitsUtil,
  getTimeZone,
  generateControlStrategyData
} from '../util/util';
import {
  useEntitiesByFullKey,
  useEntitiesByShortKey,
  useFindAssessmentsByShortKey,
  useGetMethods,
  useGetPersonnel,
  useGetResults,
  useGetSampleSchedules,
  useGetSummaries,
  useGetOrderOfParameters,
  useGetCompanyType,
  getDefaultControlStrategyConfiguration,
  useFindControlStrategyConfigurationByFullKey
} from '../util/db';
import Section from '../components/Section';
import SQCLineChart from '../components/SQCLineChart';
import SQCMovingRangeChart from '../components/SQCMovingRangeChart';
import SQCScatterPlot from '../components/SQCScatterPlot';
import SQCHistogram from '../components/SQCHistogram';
import { useLocation, useRouter } from '../util/router';
import ChartContext from '../components/DataEntrySection/DataEntryProvider';
import PrintOverviewDataTable from '../components/Table/PrintOverviewDataTable';

import 'react-datepicker/dist/react-datepicker.css';
import CustomTable from '../components/Table/CustomTable';
import { randomId } from '@mui/x-data-grid-generator';
import AppContext from '../components/AppContext';
import { archiveRegex } from '../util/Constant';
import Loading from '../components/Common/Loading';

const pageSizeDefault = 10;

const radioDataType = {
  ACTIVE: 'Active',
  ARCHIVE: 'Archive'
};

const defaultChartGroupConfigurations = {
  assignedSamples: ['None', 'None', 'None'],
  autoCreateSampleRecheck: 'No',
  displayMethodVersion: 'No',
  fullKey: '',
  initialAssessment: '20',
  ongoingAssessment: '20',
  ongoingAssessmentDays: '0',
  sampleType: 'SQC',
  shortKey: '',
  type: 'Chart Group',
  ewmaLambda: '0.4',
  tAlpha: '0.05',
  fAlpha: '0.05'
};

function SQCPrintPage() {
  const auth = useAuth();
  const router = useRouter();
  const location = useLocation();
  const [currentShortKey, setCurrentShortKey] = useState(null);
  const { currentFullKey, setCurrentFullKey } = useContext(AppContext);
  const { data: items } = useEntitiesByFullKey(currentFullKey);
  const { data: shortKeyItems } = useEntitiesByShortKey(currentShortKey);
  let { data: assessments } = useFindAssessmentsByShortKey(currentShortKey);
  const { data: controlStrategyConfigurations } =
    useFindControlStrategyConfigurationByFullKey(currentFullKey);
  const [resultsDataTableData, setResultsDataTableData] = useState([]);
  const { data: summaries } = useGetSummaries();
  const { data: orderOfParameters } = useGetOrderOfParameters();
  const [currentResultsData, setCurrentResultsData] = useState([]);
  let { data: results } = useGetResults();
  const { data: personnel } = useGetPersonnel();
  const [currentSummariesShortKey, setCurrentSummariesShortKey] = useState([]);
  const [currentSummary, setCurrentSummary] = useState(null);
  const [currentSetupDataOne, setCurrentSetupDataOne] = useState(0);
  const [currentSetupDataZero, setCurrentSetupDataZero] = useState(0);
  const [currentPersonnel, setCurrentPersonnel] = useState([]);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [currentMethod, setCurrentMethod] = useState(null);
  const [currentSampleName, setCurrentSampleName] = useState(null);
  const [currentInstrument, setCurrentInstrument] = useState(null);
  const [currentParameter, setCurrentParameter] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [chartHitCount, setChartHitCount] = useState(0);
  const [chartHits, setChartHits] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [chartScalars, setChartScalars] = useState({});
  const [currentParameters, setCurrentParameters] = useState([]);
  const [filteredChartHits, setFilteredChartHits] = useState(false);
  const [filteredShortKeyData, setFilteredShortKeyData] = useState([]);
  const [timezones] = useState(moment.tz.names());
  const [currentNValue, setCurrentNValue] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [chartAssessed, setChartAssessed] = useState(false);
  const [currentAssessments, setCurrentAssessments] = useState([]);
  const [chartStage, setChartStage] = useState('');
  const [chartSigDigits, setChartSigDigits] = useState(3);
  const [chartButtonText, setChartButtonText] = useState('');
  const { data: methods } = useGetMethods();

  const [summaryStatisticsAssessmentData, setSummaryStatisticsAssessmentData] = useState([]);
  const { data: sampleSchedules } = useGetSampleSchedules();
  const { data: companyTypes } = useGetCompanyType();
  const [currentChartGroupConfigurations, setCurrentChartGroupConfigurations] = useState(
    defaultChartGroupConfigurations
  );
  const [unit, setUnit] = useState('');
  const [permissionData, setPermissionData] = useState({});

  const [chartLoading, setChartLoading] = useState(false);
  const [radioArchiveValue, setRadioArchiveValue] = useState('Active');
  const defaultCombinedParameterName = 'Combined Z-score';
  const defaultControlStrategyConfigurations = getDefaultControlStrategyConfiguration();

  useEffect(() => {
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (queryParams.location) {
      setCurrentLocation(queryParams.location);
    }
    if (queryParams.method) {
      setCurrentMethod(queryParams.method);
    }
    if (queryParams.sampleName) {
      setCurrentSampleName(queryParams.sampleName);
    }
    if (queryParams.instrument) {
      setCurrentInstrument(queryParams.instrument);
    }
    if (queryParams.parameter) {
      setCurrentParameter(queryParams.parameter);
    }
  }, [location.search]);

  const [currentControlStrategyConfigurations, setCurrentControlStrategyConfigurations] = useState(
    defaultControlStrategyConfigurations
  );
  const [featureFlags] = useState(getCurrentFeatureFlags);
  const [timeFormat, setTimeFormat] = useState('h:mm A');
  const [dateTimeFormat, setDateTimeFormat] = useState('MM/DD/YYYY hh:mm A');
  const [tz] = useState(getTimeZone(featureFlags));

  const [controlStrategyData, setControlStrategyData] = useState(
    generateControlStrategyData(currentControlStrategyConfigurations)
  );

  useEffect(() => {
    const config = getConfiguration(window.location.hostname);
    if (isNonUs || config.timeIn24HourFormat) {
      setDateTimeFormat('MM/DD/YYYY HH:mm');
      setTimeFormat('HH:mm');
    } else {
      setDateTimeFormat('MM/DD/YYYY hh:mm A');
      setTimeFormat('h:mm A');
    }
  }, [companyTypes]);

  const defaultChartLines = [
    {
      yAxis: 'resultOmits',
      name: 'Data',
      color: '#32CD32',
      ewma: true,
      runRules: true,
      controlLimits: true,
      toleranceLimits: true
    },
    {
      yAxis: 'ewmaOmits',
      name: 'Trend',
      color: '#B0E0E6',
      ewma: true,
      runRules: false,
      controlLimits: false,
      toleranceLimits: false
    },
    {
      yAxis: 'mean',
      name: 'Average',
      reference: chartData.length > 20,
      label: ``,
      color: '#FFBF00',
      ewma: true,
      runRules: true,
      controlLimits: true,
      toleranceLimits: true
    },
    {
      yAxis: 'sd15p',
      name: 'EWMA UCL',
      label: ``,
      color: '#9932CC',
      reference: true,
      ewma: true,
      runRules: false,
      controlLimits: false,
      toleranceLimits: false
    },
    {
      yAxis: 'sd15n',
      name: 'EWMA LCL',
      label: ``,
      color: '#9932CC',
      reference: true,
      ewma: true,
      runRules: false,
      controlLimits: false,
      toleranceLimits: false
    },
    {
      yAxis: 'sd3n',
      name: 'LCL',
      label: ``,
      color: '#FBCEB1',
      reference: true,
      ewma: true,
      runRules: true,
      controlLimits: false,
      toleranceLimits: false
    },
    {
      yAxis: 'sd3p',
      name: 'UCL',
      label: ``,
      color: '#FBCEB1',
      reference: true,
      ewma: true,
      runRules: true,
      controlLimits: false,
      toleranceLimits: false
    },
    {
      yAxis: 'userDefinedLTL',
      name: 'User Defined LTL',
      label: ``,
      color: 'red',
      reference: true,
      ewma: false,
      runRules: false,
      controlLimits: false,
      toleranceLimits: true
    },
    {
      yAxis: 'userDefinedUTL',
      name: 'User Defined UTL',
      label: ``,
      color: 'red',
      reference: true,
      ewma: false,
      runRules: false,
      controlLimits: false,
      toleranceLimits: true
    },
    {
      yAxis: 'userDefinedLCL',
      name: 'User Defined LCL',
      label: ``,
      color: 'red',
      reference: true,
      ewma: false,
      runRules: false,
      controlLimits: true,
      toleranceLimits: false
    },
    {
      yAxis: 'userDefinedUCL',
      name: 'User Defined UCL',
      label: ``,
      color: 'red',
      reference: true,
      ewma: false,
      runRules: false,
      controlLimits: true,
      toleranceLimits: false
    }
  ];

  const defaultChartLinesNonUs = [
    {
      yAxis: 'resultOmits',
      name: 'Data',
      color: '#32CD32',
      ewma: true,
      runRules: true,
      controlLimits: true,
      toleranceLimits: true
    },
    {
      yAxis: 'ewmaOmits',
      name: 'Trend',
      color: '#B0E0E6',
      ewma: true,
      runRules: false,
      controlLimits: false,
      toleranceLimits: false
    },
    {
      yAxis: 'mean',
      name: 'Average',
      reference: chartData.length > 20,
      label: '',
      color: '#FFBF00',
      ewma: true,
      runRules: true,
      controlLimits: true,
      toleranceLimits: true
    },
    {
      yAxis: 'sd15p',
      name: 'EWMA UCL',
      label: '',
      color: '#9932CC',
      reference: true,
      ewma: true,
      runRules: true,
      controlLimits: true,
      toleranceLimits: true
    },
    {
      yAxis: 'sd15n',
      name: 'EWMA LCL',
      label: '',
      color: '#9932CC',
      reference: true,
      ewma: true,
      runRules: true,
      controlLimits: true,
      toleranceLimits: true
    },
    {
      yAxis: 'sd3n',
      name: 'LCL',
      label: '',
      color: '#FBCEB1',
      reference: true,
      ewma: true,
      runRules: true,
      controlLimits: false,
      toleranceLimits: false
    },
    {
      yAxis: 'sd3p',
      name: 'UCL',
      label: '',
      color: '#FBCEB1',
      reference: true,
      ewma: true,
      runRules: true,
      controlLimits: false,
      toleranceLimits: false
    },
    {
      yAxis: 'userDefinedLTL',
      name: 'User Defined LTL',
      label: ``,
      color: 'red',
      reference: true,
      ewma: false,
      runRules: false,
      controlLimits: false,
      toleranceLimits: true
    },
    {
      yAxis: 'userDefinedUTL',
      name: 'User Defined UTL',
      label: ``,
      color: 'red',
      reference: true,
      ewma: false,
      runRules: false,
      controlLimits: false,
      toleranceLimits: true
    },
    {
      yAxis: 'userDefinedLCL',
      name: 'User Defined LCL',
      label: ``,
      color: 'red',
      reference: true,
      ewma: false,
      runRules: false,
      controlLimits: true,
      toleranceLimits: false
    },
    {
      yAxis: 'userDefinedUCL',
      name: 'User Defined UCL',
      label: ``,
      color: 'red',
      reference: true,
      ewma: false,
      runRules: false,
      controlLimits: true,
      toleranceLimits: false
    }
  ];
  const isNonUs = companyTypes && companyTypes[0] && companyTypes[0]['nonUs'];
  const isEverFlags = companyTypes && companyTypes[0] && companyTypes[0]['everFlags'];
  const [chartLines, setChartLines] = useState(
    isNonUs ? defaultChartLinesNonUs : defaultChartLines
  );

  const renderSigDigits = (stringValue) => {
    return getSigDigitsUtil(stringValue, chartSigDigits);
  };

  const aggregateFlags = (summaryIn) => {
    const summary = summaryIn;
    summary.flags = '';
    summary.chartStatus = 'In control';
    if (summary.ooc_or && summary.warnings_or) {
      if (summary.ooc_or.length > 0 && summary.warnings_or.length === 0) {
        summary.flags = `OOC: ${summary.ooc_or}`;
        summary.chartStatus = 'OOC';
      } else if (summary.warnings_or.length > 0 && summary.ooc_or.length === 0) {
        summary.flags = `Warning: ${summary.warnings_or}`;
        summary.chartStatus = 'Warning';
      } else if (summary.warnings_or.length > 0 && summary.ooc_or.length > 0) {
        summary.flags = `OOC: ${summary.ooc_or}, Warning: ${summary.warnings_or}`;
        summary.chartStatus = 'OOC';
      }
    }
    return summary;
  };

  useEffect(() => {
    if (Array.isArray(controlStrategyConfigurations) && controlStrategyConfigurations.length > 0) {
      const updatedCurrentControlStrategyConfigurations = controlStrategyConfigurations.sort(
        (a, b) => b.archive?.localeCompare(a.archive)
      )[0];
      setCurrentControlStrategyConfigurations(updatedCurrentControlStrategyConfigurations);
      setControlStrategyData(
        generateControlStrategyData(updatedCurrentControlStrategyConfigurations)
      );
      setChartSigDigits(updatedCurrentControlStrategyConfigurations.chartSigDigits);
    } else if (
      Array.isArray(controlStrategyConfigurations) &&
      controlStrategyConfigurations.length === 0 &&
      !archiveRegex.test(currentFullKey)
    ) {
      defaultControlStrategyConfigurations.fullKey = currentFullKey;
      createConfiguration(defaultControlStrategyConfigurations);
    }
  }, [controlStrategyConfigurations]);

  useEffect(() => {
    if (Array.isArray(summaries) && summaries.length > 0) {
      summaries.forEach((summaryIn) => {
        const summary = summaryIn;
        summary.recent_sample = parseFloat(summary.recent_sample).toFixed(5);
        summary.tpi_or = parseFloat(summary.tpi_or).toFixed(5);
        summary.sp_or = parseFloat(summary.sp_or).toFixed(5);
        summary.avg_or = parseFloat(summary.avg_or).toFixed(5);
        summary.sd_rms_or = parseFloat(summary.sd_rms_or).toFixed(5);
        summary.sd_mr_or = parseFloat(summary.sd_mr_or).toFixed(5);
        summary.repeat_or = parseFloat(summary.repeat_or).toFixed(5);
        summary.repro_or = parseFloat(summary.repro_or).toFixed(5);
        summary.pr_or = parseFloat(summary.pr_or).toFixed(5);
        summary.ad_mr_or = parseFloat(summary.ad_mr_or).toFixed(5);
        aggregateFlags(summary);
        const fullKey = summary.id;
        summary.location = fullKey.substr(0, fullKey.indexOf('|'));
        const secondStr = fullKey.substr(fullKey.indexOf('|') + 1, fullKey.length - 1);
        summary.method = secondStr.substr(0, secondStr.indexOf('|'));
        const thirdStr = secondStr.substr(secondStr.indexOf('|') + 1, secondStr.length - 1);
        summary.sampleName = thirdStr.substr(0, thirdStr.indexOf('|'));
        const fourthStr = thirdStr.substr(thirdStr.indexOf('|') + 1, thirdStr.length - 1);
        summary.instrument = fourthStr.substr(0, fourthStr.indexOf('|'));
        const fifthStr = fourthStr.substr(fourthStr.indexOf('|') + 1, fourthStr.length - 1);
        summary.parameter = fifthStr.substr(0, fifthStr.length);
      });
    }
  }, [summaries]);

  const mapControlStrategyToChartLines = (controlStrategy) => {
    const chartLinesToReturn = isNonUs ? defaultChartLinesNonUs : defaultChartLines;
    let updatedChartLines = [];
    switch (controlStrategy) {
      case 'EWMA':
        updatedChartLines = chartLinesToReturn.filter((line) => line.ewma);
        break;
      case 'Run rules':
        updatedChartLines = chartLinesToReturn.filter((line) => line.runRules);
        break;
      case 'Control limits':
        updatedChartLines = chartLinesToReturn.filter((line) => line.controlLimits);
        break;
      case 'Tolerance limits':
        updatedChartLines = chartLinesToReturn.filter((line) => line.toleranceLimits);
        break;
      default:
        break;
    }
    setChartLines(updatedChartLines);
  };

  useEffect(() => {
    mapControlStrategyToChartLines(currentControlStrategyConfigurations.controlStrategy);
  }, [currentControlStrategyConfigurations.controlStrategy, companyTypes]);

  const buildSummaryStatisticsDataSet = () => {
    const updatedSummaryStatisticsAssessmentData = [];
    // let columns = renderAssessmentTableHeader(resultsDataTableData);
    const average = { statistic: 'Average' };
    const sdRms = { statistic: 'Standard Deviation, RMS' };
    const sdMr = { statistic: 'Standard Deviation, MR' };
    const sampleSize = { statistic: 'Sample Size (n)' };
    const outliers = { statistic: 'Number of outliers' };
    const runsOmitted = { statistic: 'Runs Omitted' };
    const repeatability = { statistic: 'Repeatability (r)' };
    const reproducibility = { statistic: 'Reproducibility (R)' };
    const sitePrecision = { statistic: 'Site Precision' };
    const precisionRatio = { statistic: 'Precision Ratio' };
    const tpi = { statistic: 'TPI' };
    const tpiAssessment = { statistic: 'TPI Assessment' };
    const andersonDarling = { statistic: 'Anderson Darling' };
    const normality = { statistic: 'Normality Assessment' };
    const fTestVarianceRatio = { statistic: 'F-test variance ratio' };
    const fStatistic = { statistic: 'F statistic' };
    const fEval = { statistic: 'F test evaluation' };
    currentSummariesShortKey.forEach((summary) => {
      average[summary.parameter] = renderSigDigits(summary.avg_sd);
      sdRms[summary.parameter] = renderSigDigits(summary.sd_rms_sd);
      sdMr[summary.parameter] = renderSigDigits(summary.sd_mr_sd);
      sampleSize[summary.parameter] = renderSigDigits(summary.n_sd);
      outliers[summary.parameter] = renderSigDigits(summary.outliers_gesd);
      runsOmitted[summary.parameter] = renderSigDigits(summary.omits_sd);
      repeatability[summary.parameter] = renderSigDigits(summary.repeat_sd);
      reproducibility[summary.parameter] = renderSigDigits(summary.repro_sd);
      sitePrecision[summary.parameter] = renderSigDigits(summary.sp_sd);
      precisionRatio[summary.parameter] = renderSigDigits(summary.pr_sd);
      tpi[summary.parameter] = renderSigDigits(summary.tpi_sd);
      tpiAssessment[summary.parameter] = summary.tpi_eval_sd;
      andersonDarling[summary.parameter] = renderSigDigits(summary.ad_mr_sd);
      normality[summary.parameter] = summary.ad_eval_sd;
      fTestVarianceRatio[summary.parameter] = renderSigDigits(summary.f_vr_ud);
      fStatistic[summary.parameter] = renderSigDigits(summary.f_statistic_ud);
      fEval[summary.parameter] = summary.f_eval_ud;
    });
    updatedSummaryStatisticsAssessmentData.push(average);
    updatedSummaryStatisticsAssessmentData.push(sdRms);
    updatedSummaryStatisticsAssessmentData.push(sdMr);
    updatedSummaryStatisticsAssessmentData.push(sampleSize);
    updatedSummaryStatisticsAssessmentData.push(outliers);
    updatedSummaryStatisticsAssessmentData.push(runsOmitted);
    updatedSummaryStatisticsAssessmentData.push(repeatability);
    updatedSummaryStatisticsAssessmentData.push(reproducibility);
    updatedSummaryStatisticsAssessmentData.push(sitePrecision);
    updatedSummaryStatisticsAssessmentData.push(precisionRatio);
    updatedSummaryStatisticsAssessmentData.push(tpi);
    updatedSummaryStatisticsAssessmentData.push(tpiAssessment);
    updatedSummaryStatisticsAssessmentData.push(andersonDarling);
    updatedSummaryStatisticsAssessmentData.push(normality);
    updatedSummaryStatisticsAssessmentData.push(fTestVarianceRatio);
    updatedSummaryStatisticsAssessmentData.push(fStatistic);
    updatedSummaryStatisticsAssessmentData.push(fEval);
    setSummaryStatisticsAssessmentData(updatedSummaryStatisticsAssessmentData);
  };

  useEffect(() => {
    if (currentFullKey && Array.isArray(summaries)) {
      const currentSummaryObject = summaries.find(
        (summaryObject) => summaryObject.id === firebaseClean(currentFullKey)
      );
      setCurrentSummary(currentSummaryObject);
      const currentShortKeyClean = firebaseClean(currentShortKey);
      // query summaries to find summary with ID that matches fullKey
      const summariesShortKey = summaries.filter((summaryObject) =>
        summaryObject.id.includes(currentShortKeyClean)
      );
      setCurrentSummariesShortKey(summariesShortKey);
      if (currentSummariesShortKey.length > 0) {
        buildSummaryStatisticsDataSet();
      }
    }
  }, [currentFullKey, assessments, summaries, items, chartSigDigits]);

  useEffect(() => {
    const updatedChartData = [...chartData];
    updatedChartData.forEach((dataPointIn) => {
      const dataPoint = dataPointIn;
      dataPoint.userDefinedUTL = currentControlStrategyConfigurations.upperToleranceLimit;
      dataPoint.userDefinedLTL = currentControlStrategyConfigurations.lowerToleranceLimit;
      dataPoint.userDefinedUCL = currentControlStrategyConfigurations.upperControlLimit;
      dataPoint.userDefinedLCL = currentControlStrategyConfigurations.lowerControlLimit;
    });
    setChartData(updatedChartData);
  }, [
    currentControlStrategyConfigurations.lowerToleranceLimit,
    currentControlStrategyConfigurations.upperToleranceLimit,
    currentControlStrategyConfigurations.lowerControlLimit,
    currentControlStrategyConfigurations.upperControlLimit
  ]);

  useEffect(() => {
    setCurrentShortKey(
      [currentLocation, currentMethod, currentSampleName, currentInstrument].join('|')
    );
    setCurrentFullKey(
      [currentLocation, currentMethod, currentSampleName, currentInstrument, currentParameter].join(
        '|'
      )
    );
    if (currentParameter !== null) {
      if (Array.isArray(currentParameters)) {
        const currentDate = moment()
          .tz(tz || 'UTC')
          .format('YYYY/MM/DD');
        const currentTime = moment()
          .tz(tz || 'UTC')
          .format(timeFormat);
        const emailBeforeTheAt = String(auth?.user?.email).split('@')[0];
        const currentDateTime = moment(Date.now())
          .tz(tz || 'UTC')
          .format(dateTimeFormat);
        const matchingUser = currentPersonnel.find(
          (person) => person.email.toLowerCase() === String(auth?.user?.email).toLowerCase()
        );
        let rowIndex = 0;
        const updatedResultsDataTableData = [];
        let setupData = '0';
        if (currentNValue < 20 && currentNValue > 0) {
          setupData = '1';
        }
        const formatSample = `SQC${currentDate.replace(/\//g, '')}`;

        const paramsOrdered =
          orderOfParameters && orderOfParameters[0] && orderOfParameters[0][currentShortKey]
            ? orderOfParameters[0][currentShortKey]
            : {};
        // eslint-disable-next-line no-restricted-syntax
        for (const parameter of currentParameters) {
          const data = {
            key: rowIndex,
            date: currentDate,
            time: currentTime,
            dateTime: currentDateTime,
            isEditedDatetime: false,
            parameter,
            personnel: matchingUser ? matchingUser.name : emailBeforeTheAt,
            sampleId: formatSample,
            isEditedSampleId: false,
            setupData,
            notes: '',
            result: '',
            order: paramsOrdered[parameter] || 0
          };
          if (
            parameter !== defaultCombinedParameterName &&
            ((!archiveRegex.test(parameter) && radioArchiveValue === radioDataType.ACTIVE) ||
              (archiveRegex.test(parameter) && radioArchiveValue === radioDataType.ARCHIVE))
          ) {
            updatedResultsDataTableData.push(data);
            rowIndex += 1;
          }
        }

        setResultsDataTableData(updatedResultsDataTableData.sort((a, b) => a.order - b.order));
      }
    }
  }, [currentLocation, currentMethod, currentSampleName, currentInstrument, currentParameter]);

  useEffect(() => {
    if (currentParameter && Array.isArray(methods) && methods.length > 0) {
      setUnit('');
      methods.forEach((item) => {
        if (item.parameter === currentParameter && item.method === currentMethod) {
          setUnit(item.units);
        }
      });
    }
  }, [currentParameter, methods]);

  useEffect(() => {
    buildSummaryStatisticsDataSet();
  }, [chartSigDigits]);

  ``;

  function generateNValue(fromItems) {
    const myCurrentParameters = uniq(fromItems.map((i) => i.parameter)).sort();
    const newParametersArray = myCurrentParameters.map((param) => {
      let currentSetupDataCount = 0;
      fromItems.filter((i) => {
        if (i.parameter === param && String(i.setupData) === '1') {
          currentSetupDataCount += 1;
        }
        return true;
      });
      return currentSetupDataCount;
    });

    setCurrentNValue(newParametersArray[0]);
  }

  useEffect(() => {
    if (Array.isArray(shortKeyItems) && shortKeyItems.length > 0) {
      if (isNonUs) {
        const sortedParameters = artecoCustomParameterSort(
          uniq(
            shortKeyItems
              .filter((item) => item.parameter !== defaultCombinedParameterName)
              .map((i) => i.parameter)
          ).sort()
        );
        setCurrentParameters(sortedParameters);
      } else {
        setCurrentParameters(
          uniq(
            shortKeyItems
              .filter((item) => item.parameter !== defaultCombinedParameterName)
              .map((i) => i.parameter)
          ).sort()
        );
      }
      generateNValue(shortKeyItems);
      const timeZone = tz || 'UTC';
      // check for ooc and add _r to sample ID
      setFilteredShortKeyData(
        shortKeyItems
          .map((cd, index) => {
            const wFlags = wFlagColumns.filter((f) => !!cd[f]).map((f) => cd[f]);
            const oocFlags = oocFlagColumns.filter((f) => !!cd[f]).map((f) => cd[f]);
            const row = {
              index,
              flags: `${wFlags.length > 0 ? `Warnings: ${wFlags.join(', ')}` : ''}${
                wFlags.length > 0 && oocFlags.length > 0 ? '; ' : ''
              }${oocFlags.length > 0 ? `OOC: ${oocFlags.join(', ')}` : ''}`
            };

            // eslint-disable-next-line no-restricted-syntax
            for (const elt of Object.keys(cd)) {
              if (['date'].includes(elt)) {
                row[elt] = cd[elt];
              } else if (['objectID'].includes(elt)) {
                row[elt] = cd[elt];
              } else if (
                String(cd[elt]).match(/[A-Z]/i) ||
                ['instrument', 'parameter', 'location', 'sampleName', 'method'].includes(elt)
              ) {
                row[elt] = cd[elt];
              } else if (!Number.isNaN(parseFloat(cd[elt]))) {
                row[elt] = Number(cd[elt]); // 3 decimal places max
              } else {
                row[elt] = cd[elt];
              }
            }
            if (row.dateTime) {
              const theDate = new Date(row.dateTime);
              const config = getConfiguration(window.location.hostname);
              row.date = formatInTimeZone(theDate, timeZone, 'M/d/yyyy');
              row.time = formatInTimeZone(
                theDate,
                timeZone,
                config.timeIn24HourFormat ? 'HH:mm' : 'h:mm aaa'
              );
            }
            return row;
          })
          .sort((a, b) => new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime())
      );
    }
  }, [shortKeyItems, companyTypes]);

  useEffect(() => {
    if (Array.isArray(items) && items.length > 0) {
      setChartHits(items);
      setChartHitCount(items?.length || 0);
    } else {
      setChartHits([]);
    }
  }, [items]);

  useEffect(() => {
    if (!currentParameter) {
      setChartHits([]);
      setChartData([]);
    }
  }, [currentParameter]);

  useEffect(() => {
    if (Array.isArray(assessments)) {
      if (radioArchiveValue === 'Active') {
        assessments = assessments.filter((assessment) => !Object.hasOwn(assessment, 'archive'));
      } else if (radioArchiveValue === 'Archive') {
        assessments = assessments.filter(
          (assessment) => Object.hasOwn(assessment, 'archive') && assessment.archive === true
        );
      }
      for (const assessment of assessments) {
        if (assessment.createdAt) {
          assessment.created = assessment.createdAt.toDate().toDateString();
        }
      }

      // check existing assessments in DB
      // if zero exist, check configuration (initial assessment n value) and then compare current n value to see if initial assessment due
      if (assessments.length === 0) {
        // check configuration value in DB for n value of when initial assessment needs to be completed.
        const initialAssessmentConfigValue = parseInt(
          currentChartGroupConfigurations.initialAssessment,
          10
        );
        if (currentSetupDataOne >= initialAssessmentConfigValue) {
          // initial assessment due
          setChartStage('Needs Initial Assessment');
          setChartButtonText('Perform Initial Assessment');
        } else {
          // chart stage in development
          setChartStage('In Development');
        }
      } else if (assessments.length > 0) {
        // check for initial assessment with outcome of pass
        const periodicAssessmentConfigValue = parseInt(
          currentChartGroupConfigurations.ongoingAssessment,
          10
        );
        const initialAssessmentOutcomePass = assessments.find(
          (assessment) => assessment.type === 'Initial' && assessment.outcome === 'Pass'
        );
        if (initialAssessmentOutcomePass) {
          if (currentSetupDataZero >= periodicAssessmentConfigValue) {
            // first periodic assessment due
            setChartStage('Needs Periodic Assessment');
            setChartButtonText('Perform Periodic Assessment');
            const periodicAssessments = assessments.filter(
              (assessment) =>
                assessment.type === 'Periodic' && assessment.fullKey === currentFullKey
            );
            if (periodicAssessments.length > 0) {
              periodicAssessments.sort((x, y) => x.createdAt - y.createdAt);
              const mostRecentPeriodicAssessment =
                periodicAssessments[periodicAssessments.length - 1];
              if (mostRecentPeriodicAssessment.outcome === 'Pass') {
                if (
                  currentSetupDataOne + currentSetupDataZero - mostRecentPeriodicAssessment.n <
                  periodicAssessmentConfigValue
                ) {
                  // most recent periodic assessment complete and passed, but not ready for next periodic assessment yet;
                  setChartStage('Active');
                  setChartButtonText('View Completed Assessment');
                } else if (
                  currentSetupDataOne + currentSetupDataZero - mostRecentPeriodicAssessment.n >=
                  periodicAssessmentConfigValue
                ) {
                  // periodic assessment due
                  setChartStage('Needs Periodic Assessment');
                  setChartButtonText('Perform Periodic Assessment');
                }
              } else {
                // periodic assessment stil due - no pass on most recent periodic assessment outcome
                setChartStage('Needs Periodic Assessment');
                setChartButtonText('Perform Periodic Assessment');
              }
            } else {
              // periodic assessment due
              setChartStage('Needs Periodic Assessment');
              setChartButtonText('Perform Periodic Assessment');
            }
          } else {
            // initial assessment complete, but not ready for periodic assessment yet;
            setChartStage('Active');
            setChartButtonText('View Completed Assessment');
          }
        } else {
          // initial assessment still due - no pass on initial assessment outcome
          setChartStage('Needs Initial Assessment');
          setChartButtonText('Perform Initial Assessment');
        }
      }
      assessments.sort((x, y) => x.createdAt - y.createdAt);
      setCurrentAssessments(assessments);
      if (assessments.length > 0) {
        setChartAssessed(true);
      } else {
        setChartAssessed(false);
      }
      if (Array.isArray(currentResultsData) && currentResultsData.length > 0) {
        const updatedCurrentResultsData = currentResultsData;
        for (const result of updatedCurrentResultsData) {
          result.setupData = '0';
        }
        setCurrentResultsData(updatedCurrentResultsData);
      }
    }
  }, [assessments, currentChartGroupConfigurations, currentSetupDataOne, currentSetupDataZero]);

  const renderAssessmentTableHeader = (tableData) => {
    const columnHeaders = [
      {
        field: 'statistic',
        headerName: 'Statistic',
        flex: 1,
        sortable: false
      }
    ];
    tableData.forEach((result) => {
      const fieldName =
        radioArchiveValue === radioDataType.ARCHIVE
          ? result.parameter.split('_')[0]
          : result.parameter;
      const columnToPush = {
        field: firebaseClean(fieldName),
        headerName: result.parameter,
        flex: 1,
        sortable: false
      };
      columnHeaders.push(columnToPush);
    });
    return columnHeaders;
  };

  const summaryStatisticsColumns = [
    {
      field: 'statistic',
      headerName: 'Statistic',
      flex: 2,
      sortable: false
    },
    {
      field: 'value',
      headerName: currentParameter || '',
      flex: 1,
      sortable: false
    }
  ];

  const summaryStatisticsPreview = [
    {
      id: randomId(),
      statistic: 'Average',
      value: currentSummary ? renderSigDigits(currentSummary.avg_or) : ''
    },
    {
      id: randomId(),
      statistic: 'Standard Deviation, RMS',
      value: currentSummary ? renderSigDigits(currentSummary.sd_rms_or) : ''
    },
    {
      id: randomId(),
      statistic: 'Standard Deviation, MR',
      value: currentSummary ? renderSigDigits(currentSummary.sd_mr_or) : ''
    },
    {
      id: randomId(),
      statistic: 'Repeatability (r)',
      value: currentSummary ? renderSigDigits(currentSummary.repeat_or) : ''
    },
    {
      id: randomId(),
      statistic: 'Upper Control Limit',
      value: currentSummary ? renderSigDigits(currentSummary.ucl_sd) : ''
    },
    { id: randomId(), statistic: 'n', value: currentSummary ? Number(currentSummary.n_or) : '' }
  ];

  const summaryStatisticsPreviewTwo = [
    {
      id: randomId(),
      statistic: 'Reproducibility (R)',
      value: currentSummary ? renderSigDigits(currentSummary.repro_or) : ''
    },
    {
      id: randomId(),
      statistic: 'Precision Ratio',
      value: currentSummary ? renderSigDigits(currentSummary.pr_or) : ''
    },
    {
      id: randomId(),
      statistic: 'TPI',
      value: currentSummary ? renderSigDigits(currentSummary.tpi_or) : ''
    },
    {
      id: randomId(),
      statistic: 'Anderson Darling',
      value: currentSummary ? renderSigDigits(currentSummary.ad_mr_or) : ''
    },
    {
      id: randomId(),
      statistic: 'Lower Control Limit',
      value: currentSummary ? renderSigDigits(currentSummary.lcl_sd) : ''
    },
    {
      id: randomId(),
      statistic: 'Site Precision',
      value: currentSummary ? renderSigDigits(currentSummary.sd_rms_or * 2.77) : ''
    }
  ];

  const renderPrintOverviewCharts = () => {
    return (
      <div>
        {Array.isArray(chartData) && chartData.length && (
          <div>
            <div className="overview-chart-header">
              <h4>Charts</h4>
            </div>
            <div className="chart-row-container">
              <SQCLineChart
                xAxis="updatedIndex"
                syncId="chained"
                yMin={chartScalars.cYMin}
                yMax={chartScalars.cYMax}
                data={chartData}
                lines={chartLines}
              />
              <SQCMovingRangeChart
                xAxis="updatedIndex"
                key={uuidv4()}
                data={chartData}
                syncId="chained2"
                yMin={0}
                yMax={chartScalars.mrYMax}
                lines={[
                  {
                    yAxis: 'mrOmits',
                    name: 'Data',
                    color: '#32CD32'
                  },
                  {
                    yAxis: 'mean',
                    overrideValue: chartScalars.mrMean,
                    name: 'Average',
                    reference: true,
                    label: ``,
                    color: '#FFBF00'
                  },
                  {
                    yAxis: 'sd3p',
                    overrideValue: chartScalars.mrUCL,
                    label: ``,
                    color: '#FBCEB1',
                    reference: true
                  }
                ]}
              />
            </div>
            <Row>
              <Col>
                <>
                  {chartScalars.histogramData && <SQCHistogram data={chartScalars.histogramData} />}
                  {!chartScalars.histogramData && <div>No Histogram</div>}
                </>
              </Col>
              <Col>
                <SQCScatterPlot
                  xAxis="zScoreOmits"
                  key={uuidv4()}
                  data={chartData}
                  syncId="chained2"
                  xMin={chartScalars.qqXMin}
                  xMax={chartScalars.qqXMax}
                  yMin={chartScalars.qqYMin}
                  yMax={chartScalars.qqYMax}
                  collections={[
                    {
                      name: 'Lab Results',
                      color: '#32CD32',
                      data: chartData.map((cd) => {
                        return {
                          x: cd.zScoreOmits,
                          y: cd.resultOmits
                        };
                      })
                    },
                    {
                      name: 'Trend Line',
                      color: '#FFBF00',
                      data: chartScalars.trendData,
                      line: true
                    }
                  ]}
                />
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  };

  const renderEditTable = () => {
    let columns;
    if (isNonUs) {
      columns = [...defaultColumns(dateTimeFormat, tz), ...defaultColumnsUsExtend];
    } else {
      columns = [...defaultColumns(dateTimeFormat, tz), ...defaultColumnsExtend];
    }

    if (isEverFlags) {
      columns.splice(5, 0, {
        field: 'everFlags',
        headerName: 'EverFlags',
        minWidth: 250,
        editable: false,
        renderCell: (params) => {
          const { value } = params;
          if (value && value.length) {
            return (
              <Tooltip
                title={
                  <>
                    {value.map((v) => (
                      <p style={{ margin: 0 }}>{`V${v.version}: ${v.flags.replace(
                        'W:',
                        'Warnings:'
                      )}`}</p>
                    ))}
                  </>
                }
              >
                <span>
                  V{value[value.length - 1].version}:{' '}
                  {value[value.length - 1].flags.replace('W:', 'Warnings:')}
                </span>
              </Tooltip>
            );
          }
          return <></>;
        }
      });
    }
    if (!columns || columns.length === 0 || !filteredChartHits || filteredChartHits.length === 0) {
      return <></>;
    }
    return <PrintOverviewDataTable columns={columns} data={filteredChartHits} tz={tz} />;
  };

  function renderBottomSection(section) {
    return (
      <>
        <div>
          <div>
            <div>
              <div>
                <div>
                  <div className="header">
                    <h2>Overview</h2>
                  </div>
                  <div className="underline-header">
                    <hr />
                  </div>
                  <div className="chart-status-section">
                    {currentControlStrategyConfigurations.controlStatus === 'No' ? (
                      <>
                        <div>
                          <h3>Chart Stage: Active</h3>
                        </div>
                      </>
                    ) : (
                      <>
                        <div>
                          <h3>Chart Stage: {chartStage}</h3>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="overview-chart-header">
                    <h4>
                      Summary Statistics: {currentParameter}, {unit}
                    </h4>
                  </div>
                  <div className="statistics-table">
                    <div>
                      <CustomTable
                        header={summaryStatisticsColumns}
                        data={summaryStatisticsPreview}
                        numberOfRows={pageSizeDefault}
                        hideFooter={true}
                      />
                    </div>
                    <div>
                      <CustomTable
                        header={summaryStatisticsColumns}
                        data={summaryStatisticsPreviewTwo}
                        numberOfRows={pageSizeDefault}
                        hideFooter={true}
                      />
                    </div>
                  </div>
                  <div className="overview-chart-header">
                    <h4>Results</h4>
                  </div>
                  <div>{renderEditTable()}</div>
                </div>
              </div>
              <div />
            </div>
          </div>
          {renderPrintOverviewCharts()}
        </div>
      </>
    );
  }

  useEffect(() => {
    if (Array.isArray(chartHits)) {
      for (const chart of chartHits) {
        if (!chart.notes) {
          chart.notes = '';
        }
      }
      setFilteredChartHits(
        chartHits
          .map((cd, index) => {
            const wFlags = wFlagColumns.filter((f) => !!cd[f]).map((f) => cd[f]);
            const oocFlags = oocFlagColumns.filter((f) => !!cd[f]).map((f) => cd[f]);
            const row = {
              index,
              flags: `${wFlags.length > 0 ? `Warnings: ${wFlags.join(', ')}` : ''}${
                wFlags.length > 0 && oocFlags.length > 0 ? '; ' : ''
              }${oocFlags.length > 0 ? `OOC: ${oocFlags.join(', ')}` : ''}`
            };

            for (const elt of Object.keys(cd)) {
              if (['date'].includes(elt)) {
                row[elt] = cd[elt];
              } else if (['objectID'].includes(elt)) {
                row[elt] = cd[elt];
              } else if (
                String(cd[elt]).match(/[A-Z]/i) ||
                [
                  'instrument',
                  'parameter',
                  'location',
                  'sampleName',
                  'method',
                  'sampleId'
                ].includes(elt)
              ) {
                row[elt] = cd[elt];
              } else if (!Number.isNaN(parseFloat(cd[elt]))) {
                row[elt] = Number(cd[elt]); // 3 decimal places max
              } else if (elt !== 'flags') {
                row[elt] = cd[elt];
              }
            }
            if (row.dateTime) {
              const timeZone = tz || 'UTC';
              const theDate = new Date(row.dateTime);
              row.date = formatInTimeZone(theDate, timeZone, 'M/d/yyyy');
              row.time = formatInTimeZone(theDate, timeZone, 'h:mm aaa');
            }
            if (row.result) {
              row.result = renderSigDigits(row.result);
            }
            if (row.resultOmits) {
              row.resultOmits = renderSigDigits(row.resultOmits);
            }
            return row;
          })
          .sort((a, b) => new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime())
      );
    }
  }, [chartHits, currentChartGroupConfigurations, currentControlStrategyConfigurations]);

  useEffect(() => {
    if (
      currentLocation &&
      currentMethod &&
      currentSampleName &&
      currentInstrument &&
      currentParameter &&
      Array.isArray(filteredChartHits)
    ) {
      const completeChartData = filteredChartHits.some((chartHit) =>
        Object.hasOwn(chartHit, 'resultOmits')
      );
      if (completeChartData) {
        const newChartData = filteredChartHits.filter(
          (cd) => !!cd.resultOmits || parseInt(cd.resultOmits, 10) === 0
        );

        // for y range on top 2 charts
        const rs = newChartData.map((d) => d.resultOmits);
        const rMin = Math.min(...rs);
        const rMax = Math.max(...rs);
        const topYMin =
          rMin < newChartData[newChartData.length - 1]?.sd3n
            ? rMin
            : newChartData[newChartData.length - 1]?.sd3n;
        const topYMax =
          rMax > newChartData[newChartData.length - 1]?.sd3p
            ? rMax
            : newChartData[newChartData.length - 1]?.sd3p;
        const topYInterval = topYMax - topYMin;

        // for mr chart
        const mrMean = arrayAverage(newChartData.map((d) => d.mrOmits));
        // const mrMean = Number(currentSummary.mravg_sd);

        // cover for case zScoreOmits/resultOmits is a number but store as string
        const dateTrend = newChartData.map((item) => {
          return { zScoreOmits: Number(item.zScoreOmits), resultOmits: Number(item.resultOmits) };
        });
        const trend = createTrend(dateTrend, 'zScoreOmits', 'resultOmits');
        const zscores = newChartData.map((d) => d.zScoreOmits);
        const newResults = newChartData.map((d) => d.resultOmits);
        const mrs = newChartData
          .filter((d) => !Number.isNaN(parseFloat(d.mrOmits)))
          .map((d) => parseFloat(d.mrOmits));
        const mrMaxVal = Math.max(...mrs);
        const mrUCL = mrMean * 3.27;
        const mrMax = mrUCL > mrMaxVal ? mrUCL : mrMaxVal;
        const xMin = Math.min(...zscores);
        const xMax = Math.max(...zscores);
        const yMin = Math.min(...newResults);
        const yMax = Math.max(...newResults);

        setChartScalars({
          mrMean,
          mrUCL,
          mrYMax: mrMax * 1.05,
          trendData: [
            { y: trend.calcY(Math.min(xMin)), x: xMin },
            { y: trend.calcY(xMax), x: xMax }
          ],
          qqXMin: xMin,
          qqXMax: xMax,
          qqYMin: yMin,
          qqYMax: yMax,
          cYMin: topYMin - 0.05 * topYInterval,
          cYMax: topYMax + 0.05 * topYInterval,
          histogramData: calculateHistogram(newChartData, yMin, yMax)
        });
        for (const data of newChartData) {
          data.formattedDate = new Date(data.date);
          if (!data.notes) {
            data.notes = '';
          }
        }
        const sortedAsc = newChartData.sort(
          (objA, objB) => Date.parse(objA.date) - Date.parse(objB.date)
        );
        let personnelNames = [];
        sortedAsc.forEach((resultIn, index) => {
          const result = resultIn;
          result.updatedIndex = index;
          result.userDefinedUTL = currentControlStrategyConfigurations.upperToleranceLimit;
          result.userDefinedLTL = currentControlStrategyConfigurations.lowerToleranceLimit;
          result.userDefinedUCL = currentControlStrategyConfigurations.upperControlLimit;
          result.userDefinedLCL = currentControlStrategyConfigurations.lowerControlLimit;
          personnelNames.push(result.personnel);
        });
        personnelNames = uniq(personnelNames);
        const analystsDots = [];
        const updatedPersonnelNames = [];
        personnelNames.forEach((name) => {
          const randomColor = randomDarkColor();
          const objectToAdd = {
            analyst: name,
            color: randomColor
          };
          analystsDots.push(objectToAdd);
          const objectToReturn = {
            name,
            checked: true,
            color: randomColor
          };
          updatedPersonnelNames.push(objectToReturn);
        });
        const setupDataOne = [...sortedAsc].filter((dataPoint) => dataPoint.setupData === 1);
        setCurrentSetupDataOne(setupDataOne.length);
        const setupDataZero = [...sortedAsc].filter((dataPoint) => dataPoint.setupData === 0);
        setCurrentSetupDataZero(setupDataZero.length);
        setChartData(sortedAsc);
      }
    }
  }, [currentLocation, currentMethod, currentSampleName, currentInstrument, filteredChartHits]);

  return (
    <>
      <ChartContext.Provider
        value={{
          currentChartGroupConfigurations,
          currentControlStrategyConfigurations,
          filteredShortKeyData,
          filteredChartHits,
          currentLocation,
          currentMethod,
          currentSampleName,
          currentInstrument,
          currentParameter,
          currentShortKey,
          currentFullKey,
          currentSummary,
          resultsDataTableData,
          chartData,
          permissionData,
          personnel,
          currentPersonnel,
          summaries
        }}
      >
        <Row className="mb-3 global-dropdowns">
          <Form.Group as={Col} controlId="location">
            <Form.Label>Location</Form.Label>
            <Form.Control type="text" placeholder={currentLocation} readOnly />
          </Form.Group>
          <Form.Group as={Col} controlId="method">
            <Form.Label>Method</Form.Label>
            <Form.Control type="text" placeholder={currentMethod} readOnly />
          </Form.Group>
          <Form.Group as={Col} controlId="sampleName">
            <Form.Label>Sample Name</Form.Label>
            <Form.Control type="text" placeholder={currentSampleName} readOnly />
          </Form.Group>
          <Form.Group as={Col} controlId="instrument">
            <Form.Label>Instrument</Form.Label>
            <Form.Control type="text" placeholder={currentInstrument} readOnly />
          </Form.Group>
          <Form.Group as={Col} controlId="parameter">
            <Form.Label>Parameter</Form.Label>
            <Form.Control type="text" placeholder={currentParameter} readOnly />
          </Form.Group>
        </Row>
        <Section bg="white" textColor="dark" size="lg" bgImage="" bgImageOpacity={1}>
          <div className="chart-section-container">
            <Row>{renderBottomSection()}</Row>
          </div>
        </Section>
        {chartLoading && <Loading />}
      </ChartContext.Provider>
    </>
  );
}

export default SQCPrintPage;
