import { Button } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import CustomTable from '../../Table/CustomTable';
import { listVendorColumns, VendorStatusOptions } from './Columns';
import {
  setInstrumentVendors,
  useGetPersonnel,
  useInstrumentSettings,
  useInstrumentVendors
} from '../../../util/db';
import ModalFormVendor from '../Modal/ModalFormVendor';
import { alphaNumericSorterArrayString, renderMUIButtonWithPermissions } from '../../../util/util';
import { SETTING_TYPE } from './DefineCategories';
import { useAuth } from '../../../util/auth';
import { hasPermission } from '../utils';
import { RULES, SCREEN } from '../../../util/Constant';

const VendorList = () => {
  const auth = useAuth();
  const [filterModel, setFilterModel] = useState({});
  const { data: vendors = [] } = useInstrumentVendors(filterModel);
  const { data: instrumentSettingsVendorType = [] } = useInstrumentSettings(
    SETTING_TYPE.VENDOR_TYPE
  );
  const { data: personnel = [] } = useGetPersonnel();
  const [isOpen, setOpen] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [searchValue, setSearchValue] = useState();
  const [permissionData, setPermissionData] = useState(null);

  useEffect(() => {
    if (Array.isArray(personnel) && personnel.length > 0) {
      const matchingUser = personnel.find((person) => person.email === auth.user.email);
      if (matchingUser) {
        if (auth.permissionData) setPermissionData(auth.permissionData[matchingUser.permission]);
      }
    }
  }, [personnel]);

  useEffect(() => {
    if (companies.length == 0) {
      setCompanies(
        Array.from(new Set(vendors.map((item) => item.companyName))).sort(
          alphaNumericSorterArrayString
        )
      );
    }
  }, [vendors]);

  const handleChange = (field, event) => {
    if (event.target.value) {
      setFilterModel({
        ...filterModel,
        [field]: event.target.value
      });
    } else {
      setFilterModel({
        ...filterModel,
        [field]: ''
      });
    }
  };

  const handleSave = async (data) => {
    await setInstrumentVendors(data);
    setOpen(false);
  };

  return (
    <>
      {hasPermission(permissionData, SCREEN?.INSTRUMENT_SETTINGS, RULES.VIEW) ? (
        <>
          <div className="filter">
            <div>
              <Row>
                <Form.Group as={Col} controlId="company">
                  <Form.Label>Company</Form.Label>
                  <Form.Select onChange={(event) => handleChange('companyName', event)}>
                    <option value="">All companies</option>
                    {companies.map((item) => (
                      <option key={item}>{item}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col} controlId="status">
                  <Form.Label>Status</Form.Label>
                  <Form.Select onChange={(event) => handleChange('status', event)}>
                    <option value="">All status</option>
                    {VendorStatusOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col} controlId="search">
                  <Form.Label>Search</Form.Label>
                  <Form.Control
                    placeholder="All fields"
                    onChange={(event) => setSearchValue(event.target.value)}
                  />
                </Form.Group>
              </Row>
            </div>
          </div>
          <div className="grid-view">
            <div className="inner-actions">
              <h2>Vendors</h2>
              {renderMUIButtonWithPermissions(
                'Add new',
                () => setOpen(true),
                SCREEN?.INSTRUMENT_SETTINGS,
                RULES.CREATE,
                permissionData
              )}
              <ModalFormVendor
                show={isOpen}
                handleClose={() => setOpen(false)}
                handleSave={handleSave}
                instrumentSettingsVendorType={instrumentSettingsVendorType}
              />
            </div>
            <CustomTable
              numberOfRows={20}
              data={vendors.filter((row) => {
                if (searchValue) {
                  return (
                    row.vendorId.includes(searchValue.toLowerCase()) ||
                    row.companyName.toLowerCase().includes(searchValue.toLowerCase()) ||
                    row.department.toLowerCase().includes(searchValue.toLowerCase())
                  );
                } else {
                  return true;
                }
              })}
              header={listVendorColumns}
              sort={{ sorting: { sortModel: [{ field: 'vendorId', sort: 'asc' }] } }}
              sx={{
                '& .MuiDataGrid-iconButtonContainer[aria-label*="filter"]': {
                  display: 'none'
                }
              }}
            />
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default VendorList;
