import React from 'react';
import ReactDOM from 'react-dom/client';
import { PostHogProvider } from 'posthog-js/react';
import App from './pages/_app';
import * as serviceWorker from './serviceWorker';
import { getPosthogConfig, isAnalyticsEnabled } from './config/analytics';
import packageJson from '../package.json';

// Print the current version to the console
console.log('Current version:', packageJson.version);

const root = ReactDOM.createRoot(document.getElementById('root'));

// Only wrap with PostHogProvider if analytics is properly configured
const app = isAnalyticsEnabled() ? (
  <PostHogProvider {...getPosthogConfig()}>
    <App />
  </PostHogProvider>
) : (
  <App />
);

root.render(app);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
