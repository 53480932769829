import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import React from 'react';

function PrimaryButton({ buttonStyle, text, clicked, disabled }) {
  return (
    <Button className={buttonStyle} onClick={clicked} disabled={disabled}>
      {text}
    </Button>
  );
}

PrimaryButton.propTypes = {
  buttonStyle: PropTypes.string,
  text: PropTypes.string,
  clicked: PropTypes.func,
  disabled: PropTypes.bool
};

PrimaryButton.defaultProps = {
  buttonStyle: 'btn btn-primary',
  text: 'Submit',
  clicked: () => {},
  disabled: false
};

export default PrimaryButton;
