import React, { useMemo, useState, useEffect } from 'react';
import { Table, Form, Button, Spinner } from 'react-bootstrap';
import {
  useEntitiesByLocationMethodAndSampleName,
  useFindControlStrategyConfigurationByFullKey,
  getDefaultControlStrategyConfiguration,
  updateConfiguration
} from '../util/db';
import { serverTimestamp } from 'firebase/firestore';

const InstrumentLimits = ({ location, method, sampleName, parameter, summaries }) => {
  const { data } = useEntitiesByLocationMethodAndSampleName(location, method, sampleName);
  // State to track selection for pooling for each instrument
  const [poolingSelections, setPoolingSelections] = useState({});
  const [assignForPooling, setAssignForPooling] = useState({});
  const [config, setConfig] = useState({});
  const [currentInstrument, setCurrentInstrument] = useState('');
  const [isAssigning, setIsAssigning] = useState(false);

  // Get the fullKey for the current instrument
  const fullKey = currentInstrument
    ? [location, method, sampleName, currentInstrument, parameter].join('|')
    : '';

  // Use the hook at the top level
  const { data: controlStrategyConfiguration } =
    useFindControlStrategyConfigurationByFullKey(fullKey);

  // Get list of instruments from data
  const instruments = useMemo(() => {
    if (!data || data.length === 0) return [];
    return [...new Set(data.map((item) => item.instrument).filter(Boolean))];
  }, [data]);

  // Initialize pooling selections when data changes
  useEffect(() => {
    if (data && data.length > 0) {
      const initialSelections = {};
      const initialAssignments = {};

      data.forEach((item) => {
        if (item.instrument) {
          initialSelections[item.instrument] = 'Y';
          initialAssignments[item.instrument] = 'Y';
        }
      });

      setPoolingSelections(initialSelections);
      setAssignForPooling(initialAssignments);

      // Set the first instrument to load its configuration
      if (instruments.length > 0) {
        setCurrentInstrument(instruments[0]);
      }
    }
  }, [data, instruments]);

  // Handle the control strategy configuration loading
  useEffect(() => {
    // When we get a configuration for the current instrument
    if (
      Array.isArray(controlStrategyConfiguration) &&
      controlStrategyConfiguration.length > 0 &&
      currentInstrument
    ) {
      // Update the config state
      setConfig((prev) => ({
        ...prev,
        [currentInstrument]: controlStrategyConfiguration[0]
      }));

      // Move to the next instrument if there is one
      const currentIndex = instruments.indexOf(currentInstrument);
      if (currentIndex < instruments.length - 1) {
        setCurrentInstrument(instruments[currentIndex + 1]);
      }
    }
  }, [controlStrategyConfiguration, currentInstrument, instruments]);

  // Handle selection for pooling change
  const handleSelectionChange = (instrument, value) => {
    setPoolingSelections((prev) => ({
      ...prev,
      [instrument]: value
    }));
  };

  // Handle assign for pooling change
  const handleAssignmentChange = (instrument, value) => {
    setAssignForPooling((prev) => ({
      ...prev,
      [instrument]: value
    }));
  };

  // Function to calculate pooled values
  const calculatePooledValues = (stats, selectedOnly) => {
    if (!stats || stats.length === 0) return null;

    // Filter by selection if needed
    const filteredStats = selectedOnly
      ? stats.filter((stat) => poolingSelections[stat.instrument] === 'Y')
      : stats;

    if (filteredStats.length === 0) return null;

    // Calculate total N
    const totalN = filteredStats.reduce((sum, stat) => sum + stat.n, 0);
    if (totalN === 0) return null;

    // Calculate weighted mean
    const weightedMean =
      filteredStats.reduce((sum, stat) => {
        return sum + parseFloat(stat.rawMean) * stat.n;
      }, 0) / totalN;

    // Calculate weighted variance
    let numerator = 0;
    let denominator = 0;
    filteredStats.forEach((stat) => {
      const weight = stat.n > 1 ? stat.n - 1 : 0;
      numerator += parseFloat(stat.rawVariance) * weight;
      denominator += weight;
    });

    const weightedVariance = denominator > 0 ? numerator / denominator : 0;

    // Calculate weighted UCL and LCL
    const weightedUCL = weightedMean + 3 * Math.sqrt(weightedVariance);
    const weightedLCL = weightedMean - 3 * Math.sqrt(weightedVariance);

    return {
      n: totalN,
      mean: weightedMean.toFixed(4),
      variance: weightedVariance.toFixed(4),
      stdDev: Math.sqrt(weightedVariance).toFixed(4),
      ucl: weightedUCL.toFixed(4),
      lcl: weightedLCL.toFixed(4)
    };
  };

  // Calculate stats for each instrument
  const { instrumentStats, pooledValuesAll, pooledValuesSelected } = useMemo(() => {
    if (!data || !method || !sampleName)
      return { instrumentStats: [], pooledValuesAll: null, pooledValuesSelected: null };

    // Group data by instrument
    const instrumentMap = {};

    // Filter data that has a result and respect the current pooling selections
    const filteredData = data.filter((item) => {
      return item.resultOmits !== undefined;
    });

    // Group filtered data by instrument
    filteredData.forEach((item) => {
      const instrumentKey = item.instrument;
      if (!instrumentMap[instrumentKey]) {
        instrumentMap[instrumentKey] = [];
      }
      instrumentMap[instrumentKey].push(Number(item.resultOmits));
    });

    // First, create base instrument stats without Z-scores
    const baseInstrumentStats = Object.keys(instrumentMap).map((instrumentKey) => {
      const values = instrumentMap[instrumentKey];

      // Calculate mean
      const mean = values.reduce((sum, val) => sum + val, 0) / values.length;

      // Calculate variance
      const variance =
        values.length <= 1
          ? 0
          : values.reduce((sum, val) => sum + Math.pow(val - mean, 2), 0) / (values.length - 1);

      // Calculate standard deviation
      const stdDev = Math.sqrt(variance);

      const instrumentFullKey = [location, method, sampleName, instrumentKey, parameter].join('|');
      const summary = summaries.find((s) => s.id === instrumentFullKey);

      return {
        n: values.length,
        instrument: instrumentKey,
        mean: mean.toFixed(4),
        stdDev: stdDev.toFixed(4),
        variance: variance.toFixed(4),
        ucl: summary?.ucl_sd || 'N/A',
        lcl: summary?.lcl_sd || 'N/A',
        selectionForPooling: poolingSelections[instrumentKey] || 'Y',
        assignForPooling: assignForPooling[instrumentKey] || 'Y',
        rawMean: mean,
        rawVariance: variance,
        rawStdDev: stdDev
      };
    });

    // Calculate Pooled Values (all) using base stats
    const pooledValuesAll = calculatePooledValues(baseInstrumentStats, false);

    // Calculate Pooled Values (selected) using base stats
    const pooledValuesSelected = calculatePooledValues(baseInstrumentStats, true);

    // Now calculate Z-scores using the pooled values (selected) as reference
    const instrumentStats = baseInstrumentStats.map((stat) => {
      // Z-score calculations relative to pooled values (selected)
      let zScoreMean = 0;
      let zScoreVariance = 0;

      if (
        pooledValuesSelected &&
        pooledValuesSelected.stdDev !== 0 &&
        pooledValuesSelected.variance !== 0
      ) {
        // Z-score for mean: (instrument mean - pooled mean) / pooled stdDev
        zScoreMean =
          (stat.rawMean - parseFloat(pooledValuesSelected.mean)) /
          parseFloat(pooledValuesSelected.stdDev);

        // Z-score for variance: (instrument variance - pooled variance) / pooled variance
        // This is a simplified approach - there are more complex ways to calculate this
        zScoreVariance =
          (stat.rawVariance - parseFloat(pooledValuesSelected.variance)) /
          parseFloat(pooledValuesSelected.variance);
      }

      return {
        ...stat,
        zScoreMean: zScoreMean.toFixed(4),
        zScoreVariance: zScoreVariance.toFixed(4)
      };
    });

    return { instrumentStats, pooledValuesAll, pooledValuesSelected };
  }, [
    data,
    method,
    sampleName,
    poolingSelections,
    assignForPooling,
    config,
    summaries,
    location,
    parameter
  ]);

  return (
    <div>
      <div className="instrument-limits-table-container">
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Instrument</th>
              <th>N</th>
              <th>Mean</th>
              <th>Standard Deviation</th>
              <th>RSD</th>
              <th>Variance</th>
              <th>UCL</th>
              <th>LCL</th>
              <th>Z-score Mean</th>
              <th>Z-score Variance</th>
              <th>Selection for Pooling</th>
              <th>Assign for Pooling</th>
            </tr>
          </thead>
          <tbody>
            {instrumentStats && instrumentStats.length > 0 ? (
              <>
                {instrumentStats
                  .sort((a, b) =>
                    a.instrument.localeCompare(b.instrument, undefined, {
                      numeric: true
                    })
                  )
                  .map((stat, index) => (
                    <tr key={`instrument-${index}`}>
                      <td>{stat.instrument}</td>
                      <td>{stat.n}</td>
                      <td>{stat.mean}</td>
                      <td>{stat.stdDev}</td>
                      <td>
                        {stat.rawMean > 0
                          ? ((stat.rawStdDev / stat.rawMean) * 100).toFixed(2)
                          : 'N/A'}
                      </td>
                      <td>{stat.variance}</td>
                      <td>{stat.ucl}</td>
                      <td>{stat.lcl}</td>
                      <td>{stat.zScoreMean}</td>
                      <td>{stat.zScoreVariance}</td>
                      <td>
                        <Form.Select
                          value={poolingSelections[stat.instrument] || 'Y'}
                          onChange={(e) => handleSelectionChange(stat.instrument, e.target.value)}
                          aria-label="Selection for Pooling"
                        >
                          <option value="Y">Y</option>
                          <option value="N">N</option>
                        </Form.Select>
                      </td>
                      <td>
                        <Form.Select
                          value={assignForPooling[stat.instrument] || 'Y'}
                          onChange={(e) => handleAssignmentChange(stat.instrument, e.target.value)}
                          aria-label="Assign for Pooling"
                        >
                          <option value="Y">Y</option>
                          <option value="N">N</option>
                        </Form.Select>
                      </td>
                    </tr>
                  ))}

                {/* Visual separator */}
                <tr className="pooled-values-separator">
                  <td colSpan="12" style={{ padding: '0.5rem', backgroundColor: '#f0f0f0' }}></td>
                </tr>

                {/* Pooled Values (all) row */}
                {pooledValuesAll && (
                  <tr className="pooled-values-all">
                    <td>
                      <strong>Pooled Values (all)</strong>
                    </td>
                    <td>{pooledValuesAll.n}</td>
                    <td>{pooledValuesAll.mean}</td>
                    <td>{pooledValuesAll.stdDev}</td>
                    <td>
                      {parseFloat(pooledValuesAll.mean) > 0
                        ? (
                            (parseFloat(pooledValuesAll.stdDev) /
                              parseFloat(pooledValuesAll.mean)) *
                            100
                          ).toFixed(2)
                        : 'N/A'}
                    </td>
                    <td>{pooledValuesAll.variance}</td>
                    <td>{pooledValuesAll.ucl}</td>
                    <td>{pooledValuesAll.lcl}</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                )}

                {/* Pooled Values (selected) row */}
                {pooledValuesSelected && (
                  <tr className="pooled-values-selected">
                    <td>
                      <strong>Pooled Values (selected)</strong>
                    </td>
                    <td>{pooledValuesSelected.n}</td>
                    <td>{pooledValuesSelected.mean}</td>
                    <td>{pooledValuesSelected.stdDev}</td>
                    <td>
                      {parseFloat(pooledValuesSelected.mean) > 0
                        ? (
                            (parseFloat(pooledValuesSelected.stdDev) /
                              parseFloat(pooledValuesSelected.mean)) *
                            100
                          ).toFixed(2)
                        : 'N/A'}
                    </td>
                    <td>{pooledValuesSelected.variance}</td>
                    <td>{pooledValuesSelected.ucl}</td>
                    <td>{pooledValuesSelected.lcl}</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                )}
              </>
            ) : (
              <tr>
                <td colSpan="12" className="text-center">
                  No instrument data available for the selected method and sample name
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      {pooledValuesSelected && (
        <div className="d-flex justify-content-end mt-3">
          <Button
            variant="primary"
            disabled={isAssigning}
            onClick={() => {
              setIsAssigning(true);

              // Get all instruments that are marked 'Y' for assignForPooling
              const instrumentsToAssign = instrumentStats
                .filter((stat) => assignForPooling[stat.instrument] === 'Y')
                .map((stat) => stat.instrument);

              // Track promises to know when all operations are complete
              const updatePromises = [];

              // For each instrument that is selected for assignment
              instrumentsToAssign.forEach((instrument) => {
                // Create fullKey for the instrument
                const instrumentFullKey = [
                  location,
                  method,
                  sampleName,
                  instrument,
                  parameter
                ].join('|');

                // Get existing configuration ID for this instrument if it exists
                const configId = config[instrument] ? config[instrument].id : null;

                // Prepare configuration data
                let configData;

                // If there's no existing config, add default values
                if (!configId) {
                  configData = {
                    ...getDefaultControlStrategyConfiguration(),
                    recType: 'configurations',
                    fullKey: instrumentFullKey,
                    upperControlLimit: pooledValuesSelected.ucl,
                    lowerControlLimit: pooledValuesSelected.lcl,
                    controlStrategy: 'Control limits',
                    updatedAt: serverTimestamp()
                  };
                } else {
                  configData = {
                    fullKey: instrumentFullKey,
                    upperControlLimit: pooledValuesSelected.ucl,
                    lowerControlLimit: pooledValuesSelected.lcl,
                    controlStrategy: 'Control limits',
                    updatedAt: serverTimestamp()
                  };
                }

                // Update the configuration using the function from db.js and track the promise
                const updatePromise = updateConfiguration(configId, configData)
                  .then(() => {
                    console.log(
                      `Updated configuration for instrument: ${instrument} (${configId}): ${JSON.stringify(
                        configData,
                        null,
                        2
                      )}`
                    );
                  })
                  .catch((error) => {
                    console.error(
                      `Error updating configuration for instrument: ${instrument}`,
                      error
                    );
                  });

                updatePromises.push(updatePromise);
              });

              // When all updates are complete, set isAssigning to false
              Promise.all(updatePromises)
                .then(() => {
                  setIsAssigning(false);
                })
                .catch(() => {
                  setIsAssigning(false);
                });
            }}
          >
            {isAssigning ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-2"
                />
                <span>Assigning...</span>
              </>
            ) : (
              'Assign Selected Limits'
            )}
          </Button>
        </div>
      )}
    </div>
  );
};

export default InstrumentLimits;
