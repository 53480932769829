import React, { useState } from 'react';
import { Modal, Form, Col, Row, Card } from 'react-bootstrap';
import { Button as MuiButton } from '@mui/material';

const ModalGenerateReportFilter = ({ show, handleClose, handleGenerate }) => {
  const [filterModel, setFilterModel] = useState({
    startDate: '',
    endDate: ''
  });

  const handleChange = (field, event) => {
    setFilterModel({
      ...filterModel,
      [field]: event.target.value
    });
  };

  const handleSubmit = () => {
    handleGenerate(filterModel);
    handleClose();
    setFilterModel({
      startDate: '',
      endDate: ''
    });
  };

  const handleModalClose = () => {
    handleClose();
    setFilterModel({
      startDate: '',
      endDate: ''
    });
  };

  return (
    <Modal show={show} onHide={handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Generate Report</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Card>
            <Card.Header as="h6">Event due date</Card.Header>
            <Card.Body>
              <Row>
                <Form.Group as={Col} controlId="startDate">
                  <Form.Label>From</Form.Label>
                  <Form.Control
                    type="date"
                    value={filterModel.startDate}
                    onChange={(event) => handleChange('startDate', event)}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="endDate">
                  <Form.Label>To</Form.Label>
                  <Form.Control
                    type="date"
                    value={filterModel.endDate}
                    onChange={(event) => handleChange('endDate', event)}
                  />
                </Form.Group>
              </Row>
            </Card.Body>
          </Card>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <MuiButton
          variant="text"
          type="reset"
          onClick={handleModalClose}
          style={{ marginRight: '10px' }}
        >
          Close
        </MuiButton>
        <MuiButton variant="contained" onClick={handleSubmit}>
          Generate Report
        </MuiButton>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalGenerateReportFilter;
